* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#nav nav {
  height: 50px;
  width: 100%;
  margin: auto;
  align-items: center;
  margin-bottom: 25px;
}

:root {

  --orange: rgb(245, 101, 29)
}

/*  MAin Container */

.main-container {
  display: flex;
}

/* Sidebar Start Here */

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.sidebar {
  background: white;
  color: black;
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  top: 0;
  left: 0;
}

.sidebar .top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.sidebar .top_section .logo {

  line-height: 0;
  border-radius: 5px;
}

.sidebar .top_section .bars {
  width: 30px;
  font-size: 18px;
}

#search {
  border-radius: 5px;
}

.hide {
  display: none;
}

.upshowing .input-group input {
  padding: 15px 40px;
}

.upshowing .input-group #srh-icon {
  position: absolute;
  left: 12px;
  top: 18px;
  z-index: 20;

}

.upshowing .main-section-search .input-group #srh-icon {
  position: absolute;
  left: 12px;
  top: 18px;
  z-index: 20;
}

#search {
  padding-left: 35px;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: rgb(0, 7, 61);
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.routes .link {
  display: flex;
  color: black;
  gap: 10px;
  padding: 5px 10px;
  text-decoration: none;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.routes .link:hover {
  border-right: 4px solid whitesmoke;
  background: rgb(45, 51, 89);
  color: #f1f1f1;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.routes .active {
  border-right: 4px solid var(--orange);
  color: white;
  background: rgb(45, 51, 89);
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: black;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

/* ::-webkit-scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/*  Centerbar starts here  */

/* topbar  */
.topbar {
  width: 100%;
  position: sticky;
  top: 0;
  background: var(--orange);
  line-height: 50px;
  z-index: 99;
  color: white;
}

.topbar .H1 {
  font-size: 25px;
  font-weight: 900;
  display: contents;
}

.centerbar {
  width: 100%;
}


.emp-centerbar {
  width: 100%;
  height: 100vh;
  overflow-y: scroll ;
}



.card-height {
  min-height: 120px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  filter: brightness(90%);
  border-radius: 10px;
  transition-duration: 4ms all ease-in-out;
  transform: all ease-in-out;
}

.card-height:hover {
  transform: scale(1.02);
}

.card-height span {
  background-color: var(--c);
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  height: 30px;
  width: 30px;
  padding: 4px;
}

.card-height h6 {
  font-size: 14px;
  width: auto;
  padding: 10px 5px;

}

/* settings */
.profile .lable {
  padding: 5px 10px;
  background-color: var(--orange);
  color: whitesmoke;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.profile .lable .image-upload {
  font-size: 25px;
  font-weight: bold;
}

.profile .img-priview img {
  width: 200px;
  height: 150px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.siteconfig-form img {
  width: 180px;
  height: 130px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.siteconfig-form h6 {
  font-size: 22px;
  margin-top: 10px;
  font-weight: 500;
}

/* /----------step-wizard------------/  */

.d-flex {
  display: flex;
}

.wizard {

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 18px;
  border-radius: 10px;
  margin-top: 15px;
}


.wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
}

.wizard>div.wizard-inner {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}

.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 67%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 30px;
  z-index: 1;
}

.wizard .nav-tabs>li.active>a,
.wizard .nav-tabs>li.active>a:hover,
.wizard .nav-tabs>li.active>a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

span.round-tab {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 16px;
  color: #052693;
  font-weight: 500;
  border: 1px solid #ddd;
}

span.round-tab i {
  color: #555555;
}

.wizard li.active span.round-tab {
  background: #052693;
  color: #fff;
  border-color: #052693;
}

.wizard li.active span.round-tab i {
  color: #5bc0de;
}

.wizard .nav-tabs>li.active>a i {
  color: #052693;
}

.wizard .nav-tabs>li {
  width: 32%;
}

.wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: #052693;
  transition: 0.1s ease-in-out;
}



.wizard .nav-tabs>li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}

.wizard .nav-tabs>li a i {
  position: absolute;
  top: -15px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.wizard .nav-tabs>li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 20px;
}


.wizard h3 {
  margin-top: 0;
}

.prev-step,
.next-step {
  font-size: 13px;
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  margin: 30px 10px;
}

.next-step {
  background-color: #052693;
}

.skip-btn {
  margin-right: 2px;
  background-color: whitesmoke;
}

.skip-btn:hover {
  color: white;
  background-color: #052693;
}

.step-head {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}

.term-check {
  font-size: 14px;
  font-weight: 400;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 38px;
  padding: .375rem .75rem;
  line-height: 2;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0;
}


.tablemain {
  overflow-x: scroll;

}

.error-msg {
  margin: 21px 0 0 0;
  background-color: #2D3359;
  color: #fff;
  padding: 8px 20px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  /* border-radius: 8px 65px; */
  border-radius: 15px;
  max-width: 56%;
}


@media (max-width: 767px) {
  .sign-content h3 {
    font-size: 40px;
  }

  .wizard .nav-tabs>li a i {
    display: none;
  }

  .signup-logo-header .navbar-toggle {
    margin: 0;
    margin-top: 8px;
  }

  .signup-logo-header .logo_area {
    margin-top: 0;
  }

  .signup-logo-header .header-flex {
    display: block;
  }
}

/* /----------end-step-wizard------------/  */

.profile-header .profile-image {
  max-width: 280px;
  height: 250px;
}

.profile-header .profile-image img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.form_img img {
  max-width: 120px;
}

.hback {
  background-color: #2d3359;
  color: #fff;
  font-weight: 600;
  font-size: 23px;
  padding: 10px;
  border-radius: 6px;
}

.borderbt {
  padding: 15px 0;
  border-bottom: 1px solid gray;

}

.nav-pills .nav-link.active {
  background-color: #2d3359;
}

.nav_of_stu a.navkinks {
  font-size: 15px;
}

.nav_of_stu {
  background-color: #2d335938;
  border-radius: 6px;

}

.nav_of_stu .tab-content {
  padding-top: 0;
  margin-top: 0 !important;
}

/* company dashsboard testimonial image css  */
.content-related .testiimage {
  width: 100px;
  max-width: 100%;
}

.company_form div {
  margin-top: 10px;
}

.no-data-message {
  text-align: center;
  font-size: 18px;
  color: #999;
  padding: 20px;
}

.status_dropdown .form-select {
  font-size: 16px;
  padding: 2px;
}

/* company dashboard  view job section css  start*/
.comany-dashboard .company-view-details .job-view {
  font-size: 20px;
  font-weight: 500;
  padding: 10px 35px;
  text-decoration-line: underline;
  text-underline-offset: 5px;
  text-decoration-style: double;
  text-decoration-color: #ea8d2d;
}

.comany-dashboard .company-view-details .comapny-details {
  width: 100%;
  height: auto;
  line-height: 40px;
}

.comany-dashboard .company-view-details .comapny-details strong {
  font-size: 15px;
}

.comany-dashboard .company-view-details .comapny-details span {
  margin: 0 5px;

  & p {
    margin: 0;
    padding: 0;
  }
}

/* company dashboard  view job section css  end*/



/* Agent Dashboard Css start */
.profile_of_student .agent-dashboard-profile .agent-profile-pic {
  width: 100%;
  height: auto;
}

.profile_of_student .agent-dashboard-profile .agent-profile-pic img {
  width: 150px;
  height: 120px;
  margin: 10px 0px;
  border-radius: 10px;
}

/*agent profile update form css  start */

.agent-profile-update .agent-form-heading {
  text-decoration: underline;
  text-decoration-color: darkorange;
  text-decoration-style: double;
  text-underline-offset: 8px;
  font-size: 19px;
}

.agent-profile-update label {
  margin-top: 20px;

}

/* agent profile update form css end */
/* Agent Dashboard Css end */

/* Employee dashboard css start */

.employee-sidebar {
  background-color: #f0ddc4;
  width: 25%;
  /* margin: 5px 15px; */
  height: 100vh;
}

.employee-sidebar .employee-profile {
  padding: 2px 0;
}
.employee-sidebar .employee-profile .profile_name_emp{
  color: darkcyan;
  animation: both;
}
.employee-sidebar .user-details-tab .emp-main-route .emp-route:hover {
  color: #052693;
  transition: all 0.5 ease-in;
}

.employee-sidebar .user-details-tab .emp-main-route .emp-route.active {
  color: #052693;
  font-weight: 700;
}

.employee-sidebar .employee-profile a {
  margin: 1rem 0.5rem;
}

.employee-profile .user-employee-profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;

  &:hover {
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: transparent !important;
  }
}

.employee-profile .user-employee-profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:hover {
    animation: changebgcolor 3s infinite linear;
  }
}

.user-information {
  width: 100%;
}

.user-information .user-profile {
  width: 120px;
  height: 120px;
  padding: 2px
}

.user-information .user-profile img {
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;
  cursor: pointer;
  padding: 3px;
}

.user-information .user-profile:hover {
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: transparent !important;
}

.user-information .user-profile img:hover {
  animation: changebgcolor 3s infinite linear;

}

@keyframes changebgcolor {
  0% {
    background-color: #fae94e;
    transform: scale(0.95);
    transition: all 0.2s linear;
  }

  50% {
    background-color: #f5eb65;
    transform: scale(1);
    transition: all 0.5s linear;
  }

  75% {
    background-color: #e7dc42;
    transform: scale(1);
    transition: all 0.8s linear;
  }

  100% {
    background-color: #e9ff6a;
    transform: scale(0.95);
    transition: all 0.10s linear;
  }

}

.user-information .user-profile-data h3 {
  font-size: 24px;
  font-weight: 600;
  color: #6d6a68;
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: #b3988b;
}

.user-information .user-profile-data p {
  font-size: 20px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 45px;

}

.user-information .user-profile-data p:nth-child(2) :first-child:nth-child(1) {
  color: #fed049;
  font-size: 23px;
}

.user-information .user-profile-data p:nth-child(3) :first-child:nth-child(1) {
  color: #2196f3;
  font-size: 23px;
}

.user-information .user-profile-data p:nth-child(4) :first-child:nth-child(1) {
  color: #ff6469;
  font-size: 23px;
}

.user-information .user-profile-data p span {
  /* color: #fff; */
  font-size: 15px;
}

.user-data-with-card .info-card {
  padding: 5px;
  margin: 12px 0px;
  background-color: #62636646;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;


}

.user-data-with-card .info-card h5 {
  margin-top: 10px;
}

.user-data-with-card .info-card p {
  font-size: 23px;
  font-weight: 700;
  color: #F5651D;
  padding: 0;
  margin: 0;
  height: auto;
}

/* Employee dashboard css end */

/* // responsive ewmployee dashboard  start // */

@media (max-width:767px) {
  .employee-sidebar .employee-profile a {
    margin: 0.5rem !important;
  }

  .emp-centerbar .profile_of_student .breadcrumb {
    justify-content: start !important;
    padding: 0.6rem !important;
    margin: 0;
  }

  .emp-centerbar #nav nav {
    margin-bottom: 0px;
  }

  .emp-centerbar .page-wrapper {
    margin-top: 35px;
  }

  .emp-centerbar .page-wrapper .profile_of_student .nav_of_stu .nav-item .nav-link {
    font-size: 0.9rem;
    padding: 0.4rem;

  }

  .emp-centerbar .upshowing {
    padding: 0px 10px;
  }

  .emp-centerbar .page-wrapper .profile_of_student .update_btn {
    margin: 20px 0px;
  }

  .emp-centerbar .container {
    margin: 0;
  }

  .tab-content .tab-pane .hback {
    font-size: 1rem
  }

}

@media (max-width:676px) {
  .employee-sidebar {
    width: 20%;
  }

  .employee-sidebar .profile-details {
    display: none;
  }

  .employee-sidebar .user-details-tab .emp-main-route .emp-route .emp-details-tabs span:nth-child(2) {
    display: none;
  }

  .employee-sidebar .user-details-tab .employee-last-details button span:nth-child(2) {
    display: none;
  }

  .employee-profile .user-employee-profile {
    width: 55px;
    height: 55px;
  }
}


@media (max-width:367px) {
  .employee-sidebar {
    width: 20%;
  }

  .employee-sidebar .user-details-tab {
    margin-left: 0.5rem !important;
  }

}

/* // responsive ewmployee dashboard  end // */