@charset "UTF-8";
/* Author: thewebmax team
=========================
This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.
This files [Table of contents] are outlined below
---------------------------------------------------
** - ========================
	 GENERAL 
	 ========================
** - Title separators
** - Title separators
** - Center title
** - Counter
** - Client carousel 1
** - Client carousel 2
** - Footer News Letter
** - Footer dark version css
** - Footer Light version css
** - Footer Newsletter
** - Recent Post Entry
** - Widget Services
** - scroll top btn css
** - Pricing Table
** - inner page banner
** - Breadcrumb
** - Contact Form
** - Pagination Style
** - Blog Side Bar
** - Side bar tags
** - Comment list section
** - comment form
** - Comment area
** - Single Blog Post
** - Single Post Tags
** - Single Post Tags
** - Posts Author
** - Single Post Navigation
** - Loading
** - Loading Animation Start
** - Description list
** - Description list
** - Section Overlay
** - Services
** - Search
** - Google map
** - Gallery
** - Video
** - Slide Top Animation
** - Zoom in Zoom Out Animation
** - Up Down Animation
** - Working Process
** - process steps
** - how it work
** - testimonial style 1
** - testimonial style 1
** - Blog post 1
** - Blog post 2
** - BLog Post List Style
** - Job post section
** - Employer List
** - Employer grid
** - Explore New Life
** - Explore New Life
** - Job categories
** - Search Bar
** - Search Bar Section home 2
** - trusted Logo
** - tabs
** - tabs
** - Side bar Advertisement
** - Side Bar Elements
** - Checked Input
** - Error 404
** - Candidate List
** - Candidate grid
** - Candidates detail
** - Employer detail
** - Work Experience
2** - Social Share
** - Employer detail
** - Home Page 2 Banner Section Css Start 
** - Home Page 1 Banner Section Css Start 
** - Side Map
** - Side Map2
** - Sidebar Info
** - Sidebar Info
** - Sidebar  Info 2
** - Sidebar-contact
** - Faq
** - Accordion button
** - Sign Up popup
** - Dashboard Header
** - My Account css
** - Dashboard message Dropdown
** - Dashboard Notification Dropdown 
** - Dashboard Message
** - Dashboard Notification
** - Admin Nav 
** - Form Elements 
** - Dashboard Card
** - Upload Video Button
** - dashboard List
** - dashboard message
** - Edit Profile
** - Dashboard Widget
** - notification list
** - Message list
** - Msg style 2
** - msg user list side button
** - Chart
** - Dashboard candidates style
** - Data Table Candidets 
** - default table
** - Data Table Candidets 
** - Data Table
** - Model Popup 

---------------------------------------------------
*/
/* root-color================== */

:root {
  --theme-color: #017d03;
  --btn-color: #f69619;
  --bg-color: #fffaf4;
  --body-color: #54595f;
  --category-color: #f69619;
  --smoke-color2: #f5f7eb;
  --black-color: #000000;
  --black-color2: #080e1c;
  --gray-color: #bdbdbd;
  --white-color: #ffffff;
  --light-color: #bdbdbd;
  --yellow-color: #ffb539;
  --success-color: #28a745;
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");

/*===GENERAL===*/

* {
  scroll-behavior: smooth;
}

.goog-te-gadget-simple {
  background-color: whitesmoke;
  border-left: 1px solid #D5D5D5;
  border-top: 1px solid #9B9B9B;
  border-bottom: 1px solid #E8E8E8;
  border-right: 1px solid #D5D5D5;
  font-size: 10pt;
  display: flex !important;
  padding-top: 1px;
  color: #000;
  padding: 2px;
  align-items: center !important;
  cursor: pointer;
}

.goog-te-gadget {
  display: inline-block !important;
  text-align: center;
}

.google_translate {
  display: grid;
  width: 100%;
  background-color: #dcdcdcaf;
}

/* Custom style for the translate dropdown */
#google_translate_element {
  display: inline-block;
}

#google_translate_element img {
  display: none;
}

/* Hide the language text and separator */
.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span:first-child,
.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span[style*="border-left"] {
  padding: 0;
  margin: 0;
}

/* Adjust the size and appearance of the dropdown icon */
.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span[aria-hidden="true"] {
  font-size: 16px;
  color: #007bff;
  vertical-align: middle;
}

/* Hide the Google Translate icon */
.goog-te-gadget-icon {
  display: block !important;
}

/* Style the container holding the Google Translate button */
.google_translate {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Custom styles for the buttons */
.google_translate button {
  background-color: #007bff;
  color: gray;
  border: none;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
}


.site-text-primary {
  color: #1967d2;
}

.site-text-secondry {
  color: #171d28;
}

.site-text-black {
  color: #000;
}

.site-text-gray {
  color: #f9fcff;
}

.site-text-white {
  color: #fff;
}

.site-text-orange {
  color: #fd7e14;
}

.site-bg-primary {
  background-color: #1967d2;
}

.site-bg-black {
  background-color: #000;
}

.site-bg-dark {
  background-color: #17171d;
}

.site-bg-gray {
  background-color: #f9fcff;
}

.site-bg-light {
  background-color: #f9f9f9;
}

.site-bg-white {
  background-color: #fff;
}

.site-bg-light-purple {
  background-color: #f0f6fe;
}

body {
  color: #3c3c3c;
  font-family: "Rubik", sans-serif;
  line-height: 1.7;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  font-size: 15px;
}

a {
  color: #17171d;
  outline: 0px none;
  text-decoration: none;
}

a:hover,
a:focus {
  outline: 0px none;
  text-decoration: none;
}

a:active,
a:hover,
a:focus {
  color: inherit;
}

.overflow-hide {
  overflow: hidden;
}

img {
  border-style: none;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

#hero {
  /* background: url("../src/assets/images/hero-bg.jpg") center center;
  background-attachment: fixed;
  background-size: cover; */
  /* top: 75px; */
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.527);
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

#hero .main-section-video {
  width: 100%;
  height: 100%;
  position: relative;
  /* z-index: 1;  */
}

.home-register {
  position: fixed;
  width: auto;
  height: 40px;
  top: 50%;
  right: 5px;
  z-index: 9;

}

#hero .main-section-video video {
  width: 100%;
  height: auto;
}

#hero .main-s {
  z-index: 4;
  position: absolute;
  left: 40px;
  bottom: 30%;
}

#hero h1 {
  padding-top: 30px;
  font-size: 55px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

#hero p {
  color: #eee;
  font-size: 18px;
}

#hero .get-started-icon {
  font-size: 24px;
  background: #ff5821;
  padding: 14px;
  color: #fff;
  border-radius: 50px;
  position: relative;
  z-index: 5;
  box-shadow: 10px 2px 15px rgba(0, 0, 0, 0.1);
}

#hero .btn-get-started {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  transition: 0.5s;
  margin-left: -10px;
  padding: 8px 26px 8px 26px;
  color: #fff;
  background: #ff5821;
  border-radius: 0 50px 50px 0;
  position: relative;
  z-index: 4;
}

#hero .btn-get-started:hover {
  background: #ff5821bb;
}

@media (max-width: 768px) {}

/* tesimonial css */
.testimonials .testimonial-wrap {
  padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 15px;
  min-height: 200px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.175);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item span {
  width: 95px;
  height: 100px;
  border-radius: 20px;
  position: absolute;
  top: 25px;
  left: -58px;
  animation: mymove 3.4s infinite;
}

@keyframes mymove {
  0% {
    top: 10px;
  }

  50% {
    top: 30px;
  }

  100% {
    top: 10px;
  }
}

.testimonials .testimonial-item .testimonial-img {
  position: relative;
  width: 80px;
  border-radius: 10px;
  height: 90px !important;
  border: 6px solid #fff;
  /* border: 2px solid black; */
  position: absolute;
  padding: 2px 0;
  position: absolute;
  top: 25px;
  left: -42px;
  animation: mymove 3.5s infinite;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #e1f0fa;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #3498db;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3498db;
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }

  .testimonials .testimonial-item span {
    background-color: rgba(94, 91, 91, 0.411);
    /* position: static; */
    left: 18px;
    opacity: 0.8;
  }
}

/*Float clearfix*/
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.shadow-bx {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.wt-icon-box-wraper:after {
  content: "";
  display: table;
  clear: both;
}

ol.commentlist li:after {
  content: "";
  display: table;
  clear: both;
}

section,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary {
  display: block;
}

ul {
  padding: 0px;
}

::selection {
  background: #484848;
  color: #fff;
}

/*===Heading & Title====*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
  line-height: 1.2;
  color: #17171d;
}

h1 {
  font-size: 60px;
  font-weight: 700;
}

h2 {
  font-size: 46px;
  font-weight: 500;
}

h3 {
  font-size: 22px;
  font-weight: 500;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

h5 {
  font-size: 16px;
  font-weight: 500;
}

h6 {
  font-size: 14px;
  font-weight: 400;
}

/*---Text align--- */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/*===Listing Style===*/
dl,
ul,
ol {
  list-style-position: outside;
  padding: 0px;
}

ul,
ol {
  margin-bottom: 24px;
}

ul li {
  padding: 0px;
}

ol li {
  padding: 0px;
}

.list-simple li {
  margin-bottom: 10px;
}

.list-simple li ul {
  margin-left: 15px;
  margin-top: 10px;
}

.list-simple.list-unstyled li ul {
  margin-left: 30px;
  margin-top: 10px;
}

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
  margin: 0px 0px 20px 0px;
  padding: 0px;
  list-style: none;
  font-weight: 600;
}

.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-check-circle li,
.list-chevron-circle li,
.list-arrow-circle li,
.list-times-circle li {
  padding: 5px 5px 5px 20px;
  position: relative;
}

.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-check-circle li:before,
.list-chevron-circle li:before,
.list-arrow-circle li:before,
.list-times-circle li:before {
  font-family: "FontAwesome";
  position: absolute;
  left: 0px;
  top: 3px;
  display: block;
  font-size: 15px;
  color: #000;
}

.list-circle li:before {
  top: 6px;
  font-size: 10px;
  content: "\f111";
}

.list-angle-right li:before {
  content: "\f105";
}

.list-arrow li:before {
  content: "\f0da";
}

.list-check li:before {
  content: "\f00c";
}

.list-checked li:before {
  content: "\f046";
}

.list-check-circle li:before {
  content: "\f058";
}

.list-chevron-circle li:before {
  content: "\f138";
}

.list-arrow-circle li:before {
  content: "\f0a9";
}

.list-times-circle li:before {
  content: "\f057";
}

/*---Padding (around)---*/
.p-a0 {
  padding: 0px;
}

.p-a5 {
  padding: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-a40 {
  padding: 40px;
}

.p-a50 {
  padding: 50px;
}

.p-a60 {
  padding: 60px;
}

.p-a70 {
  padding: 70px;
}

.p-a80 {
  padding: 80px;
}

.p-a90 {
  padding: 90px;
}

.p-a100 {
  padding: 100px;
}

.p-a110 {
  padding: 110px;
}

.p-a120 {
  padding: 120px;
}

.p-a130 {
  padding: 130px;
}

.p-a140 {
  padding: 140px;
}

.p-a150 {
  padding: 150px;
}

/*---Padding (top)---*/
.p-t0 {
  padding-top: 0px;
}

.p-t5 {
  padding-top: 5px;
}

.p-t10 {
  padding-top: 10px;
}

.p-t15 {
  padding-top: 15px;
}

.p-t20 {
  padding-top: 20px;
}

.p-t30 {
  padding-top: 30px;
}

.p-t40 {
  padding-top: 40px;
}

.p-t50 {
  padding-top: 50px;
}

.p-t60 {
  padding-top: 60px;
}

.p-t70 {
  padding-top: 70px;
}

.p-t80 {
  padding-top: 80px;
}

.p-t90 {
  padding-top: 90px;
}

.p-t100 {
  padding-top: 100px;
}

.p-t110 {
  padding-top: 110px;
}

.p-t120 {
  padding-top: 20px;
}

.p-t130 {
  padding-top: 130px;
}

.p-t140 {
  padding-top: 140px;
}

.p-t150 {
  padding-top: 150px;
}

/*---Padding (bottom)---*/
.p-b0 {
  padding-bottom: 0px;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-b110 {
  padding-bottom: 110px;
}

.p-b120 {
  padding-bottom: 120px;
}

.p-b130 {
  padding-bottom: 130px;
}

.p-b140 {
  padding-bottom: 140px;
}

.p-b150 {
  padding-bottom: 150px;
}

/*---Padding (left)---*/
.p-l0 {
  padding-left: 0px;
}

.p-l5 {
  padding-left: 5px;
}

.p-l10 {
  padding-left: 10px;
}

.p-l15 {
  padding-left: 15px;
}

.p-l20 {
  padding-left: 20px;
}

.p-l30 {
  padding-left: 30px;
}

.p-l40 {
  padding-left: 40px;
}

.p-l50 {
  padding-left: 50px;
}

.p-l60 {
  padding-left: 60px;
}

.p-l70 {
  padding-left: 70px;
}

.p-l80 {
  padding-left: 80px;
}

.p-l90 {
  padding-left: 90px;
}

.p-l100 {
  padding-left: 100px;
}

.p-l110 {
  padding-left: 110px;
}

.p-l120 {
  padding-left: 120px;
}

.p-l130 {
  padding-left: 130px;
}

.p-l140 {
  padding-left: 140px;
}

.p-l150 {
  padding-left: 150px;
}

/*---Padding (right)---*/
.p-r0 {
  padding-right: 0px;
}

.p-r5 {
  padding-right: 5px;
}

.p-r10 {
  padding-right: 10px;
}

.p-r15 {
  padding-right: 15px;
}

.p-r20 {
  padding-right: 20px;
}

.p-r30 {
  padding-right: 30px;
}

.p-r40 {
  padding-right: 40px;
}

.p-r50 {
  padding-right: 50px;
}

.p-r60 {
  padding-right: 60px;
}

.p-r70 {
  padding-right: 70px;
}

.p-r80 {
  padding-right: 80px;
}

.p-r90 {
  padding-right: 90px;
}

.p-r100 {
  padding-right: 100px;
}

.p-r110 {
  padding-right: 110px;
}

.p-r120 {
  padding-right: 120px;
}

.p-r130 {
  padding-right: 130px;
}

.p-r140 {
  padding-right: 140px;
}

.p-r150 {
  padding-right: 150px;
}

/*---Padding (left right)---*/
.p-lr0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-lr120 {
  padding-left: 120px;
  padding-right: 120px;
}

.p-lr150 {
  padding-left: 150px;
  padding-right: 150px;
}

/*---Padding (top bottom)---*/
.p-tb0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.p-tb120 {
  padding-bottom: 120px;
  padding-top: 120px;
}

.p-tb150 {
  padding-bottom: 150px;
  padding-top: 150px;
}

/*----Margin (around)----*/
.m-a-1 {
  margin: -1px;
}

.m-a0 {
  margin: 0px;
}

.m-a5 {
  margin: 5px;
}

.m-a10 {
  margin: 10px;
}

.m-a15 {
  margin: 15px;
}

.m-a20 {
  margin: 20px;
}

.m-a30 {
  margin: 30px;
}

.m-a40 {
  margin: 40px;
}

.m-a50 {
  margin: 50px;
}

.m-a60 {
  margin: 60px;
}

.m-a70 {
  margin: 70px;
}

.m-a80 {
  margin: 80px;
}

.m-a90 {
  margin: 90px;
}

.m-a100 {
  margin: 100px;
}

.m-a110 {
  margin: 110px;
}

.m-a120 {
  margin: 120px;
}

.m-a130 {
  margin: 130px;
}

.m-a140 {
  margin: 140px;
}

.m-a150 {
  margin: 150px;
}

/*---Marging (top)----*/
.m-t0 {
  margin-top: 0px;
}

.m-t5 {
  margin-top: 5px;
}

.m-t10 {
  margin-top: 10px;
}

.m-t15 {
  margin-top: 15px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t30 {
  margin-top: 30px;
}

.m-t40 {
  margin-top: 40px;
}

.m-t50 {
  margin-top: 50px;
}

.m-t60 {
  margin-top: 60px;
}

.m-t70 {
  margin-top: 70px;
}

.m-t80 {
  margin-top: 80px;
}

.m-t90 {
  margin-top: 90px;
}

.m-t100 {
  margin-top: 100px;
}

.m-t110 {
  margin-top: 110px;
}

.m-t120 {
  margin-top: 120px;
}

.m-t130 {
  margin-top: 130px;
}

.m-t140 {
  margin-top: 140px;
}

.m-t150 {
  margin-top: 150px;
}

/*---Marging (bottom)---*/
.m-b0 {
  margin-bottom: 0px;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-b110 {
  margin-bottom: 110px;
}

.m-b120 {
  margin-bottom: 120px;
}

.m-b130 {
  margin-bottom: 130px;
}

.m-b140 {
  margin-bottom: 140px;
}

.m-b150 {
  margin-bottom: 150px;
}

/*---Marging (left)---*/
.m-l0 {
  margin-left: 0px;
}

.m-l5 {
  margin-left: 5px;
}

.m-l10 {
  margin-left: 10px;
}

.m-l15 {
  margin-left: 15px;
}

.m-l20 {
  margin-left: 20px;
}

.m-l30 {
  margin-left: 30px;
}

.m-l40 {
  margin-left: 40px;
}

.m-l50 {
  margin-left: 50px;
}

.m-l60 {
  margin-left: 60px;
}

.m-l70 {
  margin-left: 70px;
}

.m-l80 {
  margin-left: 80px;
}

.m-l90 {
  margin-left: 90px;
}

.m-l100 {
  margin-left: 100px;
}

.m-l110 {
  margin-left: 110px;
}

.m-l120 {
  margin-left: 120px;
}

.m-l130 {
  margin-left: 130px;
}

.m-l140 {
  margin-left: 140px;
}

.m-l150 {
  margin-left: 150px;
}

/*---Marging (right)---*/
.m-r0 {
  margin-right: 0px;
}

.m-r5 {
  margin-right: 5px;
}

.m-r10 {
  margin-right: 10px;
}

.m-r15 {
  margin-right: 15px;
}

.m-r20 {
  margin-right: 20px;
}

.m-r30 {
  margin-right: 30px;
}

.m-r40 {
  margin-right: 40px;
}

.m-r50 {
  margin-right: 50px;
}

.m-r60 {
  margin-right: 60px;
}

.m-r70 {
  margin-right: 70px;
}

.m-r80 {
  margin-right: 80px;
}

.m-r90 {
  margin-right: 90px;
}

.m-r100 {
  margin-right: 100px;
}

.m-r110 {
  margin-right: 110px;
}

.m-r120 {
  margin-right: 120px;
}

.m-r130 {
  margin-right: 130px;
}

.m-r140 {
  margin-right: 140px;
}

.m-r150 {
  margin-right: 150px;
}

/*---Marging (left right)---*/
.m-lr0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-lr120 {
  margin-left: 120px;
  margin-right: 120px;
}

.m-lr150 {
  margin-left: 150px;
  margin-right: 150px;
}

/*---Marging (top bottom)---*/
.m-tb0 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

.m-tb120 {
  margin-bottom: 120px;
  margin-top: 120px;
}

.m-tb150 {
  margin-bottom: 150px;
  margin-top: 150px;
}

/*---Colum gap less---*/
.no-col-gap [class*="col-xs-"],
.no-col-gap [class*="col-sm-"],
.no-col-gap [class*="col-md-"],
.no-col-gap [class*="col-lg-"] {
  padding-left: 0px;
  padding-right: 0px;
}

/*===Background Effect===*/
.bg-center {
  background-position: center;
}

.bg-top-left {
  background-position: top left;
}

.bg-top-right {
  background-position: top right;
}

.bg-top-center {
  background-position: top center;
}

.bg-bottom-left {
  background-position: bottom left;
}

.bg-bottom-right {
  background-position: bottom right;
}

.bg-bottom-center {
  background-position: bottom center;
}

.bg-left-center {
  background-position: left;
}

.bg-right-center {
  background-position: left;
}

.bg-auto {
  background-size: auto;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-full-width {
  background-size: 100% auto;
}

.bg-full-height {
  background-size: auto 100%;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-scroll {
  background-attachment: scroll;
}

/*===Overlay ( transparient box style)===*/
.overlay-wraper {
  position: relative;
}

.overlay-main {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.overlay-light .overlay-main {
  opacity: 0.3;
}

.overlay-dark .overlay-main {
  opacity: 0.9;
}

.overlay-wraper>.container,
.overlay-wraper>.container-fluid,
.overlay-wraper>.wt-icon-box-wraper,
.overlay-wraper>.wt-left-part,
.overlay-wraper>.wt-right-part {
  position: relative;
  z-index: 1;
}

/*===Boxes Css===*/
.rounded-bx,
.wt-box,
.wt-icon-box,
.wt-icon-box-small,
.wt-thum-bx,
.wt-post-thum {
  position: relative;
}

/*---Box content----*/
.wt-box {
  position: relative;
}

.wt-box.no-margin {
  margin-bottom: 0;
}

/*---For icons with box---*/
.wt-icon-box-xld {
  width: 180px;
  height: 180px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-xld i {
  font-size: 100px;
}

.wt-icon-box-xld img {
  height: 90px;
}

.wt-icon-box-xl {
  width: 150px;
  height: 150px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-xl i {
  font-size: 80px;
}

.wt-icon-box-xl img {
  height: 80px;
}

.wt-icon-box-lg {
  width: 120px;
  height: 120px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-lg i {
  font-size: 60px;
}

.wt-icon-box-lg img {
  height: 55px;
}

.wt-icon-box-md {
  width: 100px;
  height: 100px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-md i {
  font-size: 45px;
}

.wt-icon-box-md img {
  height: 40px;
}

.wt-icon-box-sm {
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-sm i {
  font-size: 30px;
}

.wt-icon-box-sm img {
  height: 30px;
}

.wt-icon-box-xs {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-xs i {
  font-size: 20px;
}

.wt-icon-box-xs img {
  height: 20px;
}

.wt-icon-box-xld.radius,
.wt-icon-box-xl.radius,
.wt-icon-box-lg.radius,
.wt-icon-box-md.radius,
.wt-icon-box-sm.radius,
.wt-icon-box-xs.radius {
  border-radius: 100%;
}

.wt-icon-box-xld i,
.wt-icon-box-xl i,
.wt-icon-box-lg i,
.wt-icon-box-md i,
.wt-icon-box-sm i,
.wt-icon-box-xs i {
  vertical-align: middle;
}

.wt-icon-box-xld img,
.wt-icon-box-xl img,
.wt-icon-box-lg img,
.wt-icon-box-md img,
.wt-icon-box-sm img,
.wt-icon-box-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

/*----For only icons---*/
.icon-xld,
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
}

.icon-xld i,
.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
  vertical-align: middle;
}

.icon-xld img,
.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.icon-xld {
  width: 120px;
}

.icon-xld i {
  font-size: 100px;
  line-height: 100px;
}

.icon-xld.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-xld img {
  height: 90px;
}

.icon-xld.inline-icon {
  width: auto;
}

.icon-xl {
  width: 100px;
}

.icon-xl i {
  font-size: 80px;
  line-height: 80px;
}

.icon-xl.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-xl img {
  height: 80px;
}

.icon-xl.inline-icon {
  width: auto;
}

.icon-lg {
  width: 80px;
}

.icon-lg i {
  font-size: 60px;
  line-height: 60px;
}

.icon-lg.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-lg img {
  height: 70px;
}

.icon-lg.inline-icon {
  width: auto;
}

.icon-md {
  width: 60px;
}

.icon-md i {
  font-size: 45px;
  line-height: 45px;
}

.icon-md.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-md img {
  height: 50px;
}

.icon-md.inline-icon {
  width: auto;
}

.icon-sm {
  width: 40px;
}

.icon-sm i {
  font-size: 30px;
  line-height: 30px;
}

.icon-sm.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-sm img {
  height: 30px;
}

.icon-sm.inline-icon {
  width: auto;
}

.icon-xs {
  width: 30px;
}

.icon-xs i {
  font-size: 20px;
  line-height: 20px;
}

.icon-xs.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-xs img {
  height: 20px;
}

.icon-xs.inline-icon {
  width: auto;
}

/*---Place icon with box---*/
.wt-icon-box-wraper {
  position: relative;
}

.wt-icon-box-wraper .wt-tilte {
  margin-top: 0;
}

.wt-icon-box-wraper .after-titile-line {
  margin-bottom: 10px;
}

.wt-icon-box-wraper p:last-child {
  margin: 0px;
}

.icon-content {
  overflow: hidden;
}

.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
  display: table;
}

.wt-icon-box-xld .icon-cell,
.wt-icon-box-xl .icon-cell,
.wt-icon-box-lg .icon-cell,
.wt-icon-box-md .icon-cell,
.wt-icon-box-sm .icon-cell,
.wt-icon-box-xs .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

/*---Icon box left align---*/
.wt-icon-box-wraper.left .wt-icon-box-xld,
.wt-icon-box-wraper.left .wt-icon-box-xl,
.wt-icon-box-wraper.left .wt-icon-box-lg,
.wt-icon-box-wraper.left .wt-icon-box-md,
.wt-icon-box-wraper.left .wt-icon-box-sm,
.wt-icon-box-wraper.left .wt-icon-box-xs {
  float: left;
  margin-right: 10px;
}

.wt-icon-box-wraper.left .icon-xld,
.wt-icon-box-wraper.left .icon-xl,
.wt-icon-box-wraper.left .icon-lg,
.wt-icon-box-wraper.left .icon-md,
.wt-icon-box-wraper.left .icon-sm,
.wt-icon-box-wraper.left .icon-xs {
  float: left;
  margin-right: 10px;
}

/*---Icon box right align---*/
.wt-icon-box-wraper.right {
  text-align: right;
}

.wt-icon-box-wraper.right .wt-icon-box-xld,
.wt-icon-box-wraper.right .wt-icon-box-xl,
.wt-icon-box-wraper.right .wt-icon-box-lg,
.wt-icon-box-wraper.right .wt-icon-box-md,
.wt-icon-box-wraper.right .wt-icon-box-sm,
.wt-icon-box-wraper.right .wt-icon-box-xs {
  float: right;
  display: table;
  margin-left: 20px;
}

.wt-icon-box-wraper.right .icon-xld,
.wt-icon-box-wraper.right .icon-xl,
.wt-icon-box-wraper.right .icon-lg,
.wt-icon-box-wraper.right .icon-md,
.wt-icon-box-wraper.right .icon-sm,
.wt-icon-box-wraper.right .icon-xs {
  float: right;
  margin-left: 20px;
}

/*---Icon box center align---*/
.wt-icon-box-wraper.center {
  text-align: center;
}

.wt-icon-box-wraper.center .wt-icon-box-xld,
.wt-icon-box-wraper.center .wt-icon-box-xl,
.wt-icon-box-wraper.center .wt-icon-box-lg,
.wt-icon-box-wraper.center .wt-icon-box-md,
.wt-icon-box-wraper.center .wt-icon-box-sm,
.wt-icon-box-wraper.center .wt-icon-box-xs {
  margin-left: auto;
  margin-right: auto;
}

.wt-icon-box-wraper.bx-style-1 {
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
}

.wt-icon-box-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
}

.wt-icon-box-wraper.bx-style-2.center [class*="wt-icon-box-"] {
  position: absolute;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%);
}

.wt-icon-box-wraper.bx-style-2.left [class*="wt-icon-box-"] {
  position: absolute;
}

.wt-icon-box-wraper.bx-style-2.right [class*="wt-icon-box-"] {
  position: absolute;
}

/*---Buttons--- */
.site-button-link {
  position: relative;
  color: #1967d2;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  transition: 0.5s all ease;
  overflow: hidden;
}

.site-button-link:after {
  content: "\f105";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0px;
  transition: 0.5s all ease;
  opacity: 0;
  font-weight: 900;
}

.site-button-link:hover {
  padding-left: 15px;
  color: #000;
}

.site-button-link:hover:after {
  font-size: 14px;
  left: 0px;
  opacity: 1;
}

.site-button-link.site-text-white {
  color: #fff;
}

.site-button-link.site-text-white:hover {
  color: #17171d;
}

.site-button-link.site-text-secondry {
  color: #171d28;
}

.site-button-link.site-text-secondry:hover {
  color: #171d28;
}

/* input arrow remove  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .................... */

/* Theme default button */
.site-button {
  outline: none;
  color: #fff;
  /* padding: 15px 30px; */
  padding: 5px 11px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-table;
  background-color: var(--btn-color);
  border: none;
  font-size: 16px;
  font-weight: 600;
  transition: 0.5s all ease;
}

.site-button:hover {
  color: #000;
}

.site-button i {
  padding-right: 10px;
}

@media (max-width: 480px) {
  .site-button {
    padding: 8px 8px;
    font-size: 14px;
  }
}

/* .site-button.white {
  background-color: #fff;
  color: #1967d2;
  text-shadow: 0 0 0 #1967d2, 400px 0 0 #1967d2;

}
*/
.site-button.outline-white {
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
}

.site-button.outline-primary {
  border: 1px solid #1967d2;
  background-color: transparent;
  text-shadow: 0 0 0 #1967d2, 400px 0 0 #1967d2;
}

.site-button.outline-primary:hover {
  color: #17171d;
  border: 1px solid #17171d;
}

.site-button.secondry {
  background-color: #17171d;
}

.site-button.secondry:hover {
  color: #1967d2;
}

.btn-hover-animation {
  z-index: 1;
  overflow: hidden;
  display: inline-block;
}

.btn-hover-animation:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -4px;
  left: -14px;
  background: rgba(0, 0, 0, 0.1);
  height: 50px;
  width: 50px;
  border-radius: 50px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 1s ease-out;
  transition: transform 1s ease-out;
}

.btn-hover-animation:hover:before {
  transform: scale(8);
}

.site-button-secondry {
  color: #fff;
  padding: 15px 40px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  background-color: #000;
  border: none;
  font-size: 15px;
  font-weight: 700;
}

.site-button-secondry.sb-bdr-light {
  border-right: 6px solid #fff;
}

.site-button-secondry:active,
.site-button-secondry:focus,
.site-button-secondry:visited {
  background-color: #000 !important;
  color: #fff;
}

.site-button-secondry:hover {
  background-color: #f9fcff;
  color: #000;
}

/*====Button Effect====*/
.site-button {
  color: rgba(0, 0, 0, 0) !important;
  transition: all 0.3s ease !important;
  text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
}

.site-button:hover {
  text-shadow: -400px 0 0 #fff, 0 0 0 #fff;
  background-color: #171d28 !important;
}

.mfp-video.video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  margin: -25px;
  color: #fff;
  display: block;
  z-index: 10;
  font-size: 14px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.mfp-video.video-play-btn:hover {
  background-color: #0f1221;
  color: #fff;
  border: 2px solid transparent;
}

.mfp-video.video-play-btn i {
  margin-right: -5px;
}

/*Button text uppercase */
.site-button.text-uppercase {
  text-transform: uppercase;
}

/*Button size */
.button-sm {
  padding: 5px 10px;
  font-size: 12px;
}

.button-lg {
  padding: 15px 30px;
  font-size: 16px;
}

.button-xl {
  padding: 30px 50px;
  font-size: 24px;
}

/*Button rounded */
.radius-no {
  border-radius: 0px;
}

.radius-sm {
  border-radius: 10px;
}

.radius-md {
  border-radius: 20px;
}

.radius-xl {
  border-radius: 100px;
}

.radius-bx {
  border-radius: 100%;
}

/*===Owl Slider===*/
/* .owl-carousel .owl-nav {
  margin-top: 40px;
  text-align: center;
} */

.owl-theme .owl-nav {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
}

.owl-carousel .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

/*---Owl dots button---*/
.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 30px;
}

.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}

.owl-carousel .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  margin: auto;
  display: block;
  border-radius: 50%;
  transition: opacity 200ms ease;
}

.owl-carousel .owl-dots .owl-dot:hover span {
  background: #000;
}

.owl-carousel .owl-dots .owl-dot.active {
  position: relative;
}

.owl-carousel .owl-dots .owl-dot.active span {
  height: 6px;
  width: 6px;
  position: relative;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #fff;
}

/*---Owl button top to center---*/
.owl-btn-top-center .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.owl-btn-top-left .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.owl-btn-top-right .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.owl-btn-top-center .owl-stage-outer {
  padding-top: 60px;
}

.owl-btn-top-left .owl-stage-outer {
  padding-top: 60px;
}

.owl-btn-top-right .owl-stage-outer {
  padding-top: 60px;
}

.owl-btn-top-center .owl-nav {
  text-align: center;
}

/*---Owl button top to left---*/
.owl-btn-top-left .owl-nav {
  text-align: left;
}

/*---Owl button top to right---*/
.owl-btn-top-right .owl-nav {
  text-align: right;
}

/*---Owl button bottom to center [it is default position ]---*/
.owl-btn-bottom-center .owl-nav {
  text-align: center;
}

/*---Owl button bottom to left---*/
.owl-btn-bottom-left .owl-nav {
  text-align: left;
}

/*Owl button bottom to right*/
.owl-btn-bottom-right .owl-nav {
  text-align: right;
}

/*---Owl button vertical to center--*/
.owl-btn-vertical-center .owl-nav {
  margin: 0px;
}

.owl-btn-vertical-center .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  margin: -15px 0;
  left: 0;
}

.owl-btn-vertical-center .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  margin: -15px 0;
  right: 0;
}

/*---Owl button show on hover---*/
.owl-btn-hover .owl-nav {
  opacity: 0;
}

.owl-btn-hover:hover .owl-nav {
  opacity: 1;
}

.owl-carousel .owl-item img {
  transform-style: inherit;
}

/*---Owl slider button---*/
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background-color: #1967d2;
  color: #fff;
  padding: 0px !important;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  opacity: 1;
  margin-right: 5px;
  font-weight: 500;
  /* box-shadow: 0px 0px 30px rgba(56, 152, 226, 0.3); */
}

/* .owl-carousel .owl-nav button.owl-next {
  box-shadow: 5px 1px 5px rgba(0, 0, 0, 0.5);
}
.owl-carousel .owl-nav button.owl-prev {
  box-shadow: -5px 1px 5px rgba(0, 0, 0, 0.5);
} */

.owl-carousel .owl-nav button.owl-next i,
.owl-carousel .owl-nav button.owl-prev i {
  font-style: normal;
  font-size: 26px;
  line-height: 42px;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  background-color: #fff;
  color: #1967d2;
  opacity: 0.9;
}

.owl-carousel .owl-nav button.owl-next:active,
.owl-carousel .owl-nav button.owl-prev:active {
  background-color: #fff;
  color: #1967d2;
  opacity: 0.9;
}

.owl-carousel .owl-nav button.owl-next:focus,
.owl-carousel .owl-nav button.owl-prev:focus {
  background-color: #fff;
  color: #1967d2;
  opacity: 0.9;
}

.owl-carousel button.owl-dot {
  width: 12px;
  height: 12px;
  text-align: center;
  background-color: #1967d2;
  cursor: pointer;
  border-radius: 50%;
  opacity: 1;
  margin-right: 5px;
}

.owl-btn-bottom-left .owl-nav {
  text-align: left;
}

/*---Owl dots nav position---*/
.owl-dots-bottom-left .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
  text-align: left;
}

.owl-dots-bottom-right .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
  text-align: right;
}

.owl-dots-bottom-center .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
}

/*-------------------------------------
	Text meant only for screen readers
-------------------------------------*/
.sticky {
  clear: both;
}

.gallery-caption {
  clear: both;
}

.bypostauthor {
  clear: both;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

/*-------------------------------------
	Tabs 
-------------------------------------*/
/*Tabs style 1 [ default ] */
.wt-tabs .tab-pane {
  padding: 20px 0px 0px 0px;
  background-color: #fff;
}

.wt-tabs .nav-tabs {
  border: 0px;
}

.wt-tabs .nav-tabs>li {
  border: 1px solid #ebebeb;
}

.wt-tabs .nav-tabs>li>a {
  color: #000;
  background-color: #fff;
  font-size: 16px;
  padding: 15px 40px;
  border: 0px;
  display: block;
  font-weight: 600;
}

.wt-tabs .nav-tabs>li>a i {
  margin-right: 5px;
}

.wt-tabs .nav-tabs>li a.active {
  background-color: #f9fcff;
  color: #000;
}

.wt-tabs .nav-tabs>li a.active:focus {
  background-color: #f9fcff;
  color: #000;
}

.wt-tabs .nav-tabs>li a.active:hover {
  background-color: #f9fcff;
  color: #000;
}

.wt-tabs .nav>li>a:focus {
  background-color: transparent;
  border: 0px;
}

.wt-tabs .nav>li>a:hover {
  background-color: transparent;
  border: 0px;
}

.wt-tabs .nav li a:hover {
  border: 1px;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .wt-tabs.tabs-default .tabs-default-nav .nav-tabs {
    justify-content: flex-start;
  }
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li>a {
  color: #000;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li>a span {
  display: block;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li>a span i {
  font-weight: normal;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li>a.active {
  color: #fff;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li.active>a {
  color: #000;
  border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li.active>a:focus {
  color: #000;
  border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li.active>a:hover {
  color: #000;
  border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default .tab-pane {
  color: #fff;
}

@media (max-width: 991px) {
  .wt-tabs.tabs-default .tab-pane {
    padding: 0px;
  }
}

.header-style-3 {
  position: relative;
  /*--is fixed---*/
  /*====Mobile Side =======*/
  /*---when header is sticky---*/
  /*---when header is sticky---*/
  /*---header id Fixed---*/
}

.header-style-3 .header-nav-btn-section {
  display: flex;
  align-items: center;
}

/* .header-style-3 .header-nav-btn-section .twm-nav-btn-left {
  margin-right: 10px;
} */

.header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
  background-color: #e0eeff;
  color: #1967d2;
  font-size: 16px;
  border-radius: 10px;
  padding: 8px 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up:before {
  width: 0%;
  height: 100%;
  position: absolute;
  right: 100%;
  bottom: 0px;
  content: "";
  background-color: #3898e2;
  opacity: 0.1;
  z-index: -1;
  transition: 0.5s all ease;
}

@media (max-width: 575px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 1360px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    font-size: 0px;
  }
}

.header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up i {
  padding-right: 5px;
}

@media (max-width: 1360px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up i {
    font-size: 16px;
    padding-right: 0px;
  }
}

.header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up:hover:before {
  width: 100%;
  right: 0%;
  opacity: 0.1;
}

.header-style-3 .header-nav-btn-section .twm-nav-btn-right {
  margin-right: 10px;
}

.header-style-3 .header-nav-btn-section .twm-nav-btn-right .dropdown .dropdown-toggle {
  background-color: var(--btn-color);
  color: #fff;
  border: none;
}

.header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
  background-color: var(--btn-color);
  color: #f0f6fe;
  font-size: 16px;
  border-radius: 10px;
  padding: 8px 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job:before {
  width: 0%;
  height: 100%;
  position: absolute;
  right: 100%;
  bottom: 0px;
  content: "";
  background-color: #17171d;
  opacity: 0.1;
  z-index: -1;
  transition: 0.5s all ease;
}

@media (max-width: 575px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 1360px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    font-size: 0px;
  }
}

.header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job i {
  padding-right: 5px;
}

@media (max-width: 1360px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job i {
    font-size: 16px;
    padding-right: 0px;
  }
}

.header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job:hover:before {
  width: 100%;
  right: 0%;
  opacity: 1;
}

.header-style-3 .header-search a.header-search-icon {
  color: #1967d2;
  width: 54px;
  height: 54px;
  line-height: 54px;
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  border-radius: 50%;
}

/* Header Search bar style start  */

.modal-dialog {
  max-width: 60%;
}

.model-icon:hover {
  color: blue;
}

.model-form {
  width: 100%;
  height: 20px;
}

.h-search-form {
  position: relative;
  padding: 10px;
  text-align: center;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  border-radius: 20px;
}

.h-search-form input {
  width: 90%;
  padding: 0 30px;
  padding-right: 110px;
  border-radius: 50px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important;
  text-transform: capitalize;
  position: relative;
  color: #333;
  height: 70px;
}

.h-search-form button {
  position: relative;
  padding: 9px 8px;
  top: -58px;
  right: -37%;
  border-radius: 50px;
  border: none;
  width: 100px;
}

.h-search-form button:hover {
  background: var(--btn-color) !important;
}

/* Header Search bar style  End*/

@media (max-width: 600px) {
  .header-style-3 .header-search a.header-search-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .extra-nav .g-translate {
    display: none !important;
  }

  .ftr-nw-content .ftr-nw-form .form-control {
    height: 41px !important;

  }

  .apply-process .card .card-content p {
    padding: 10px !important;
    letter-spacing: 1px;
    word-spacing: 0 !important;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .footer-dark .ftr-list li p {
    margin-bottom: 0px;
    font-size: 14px !important;
  }
}


/* The Serach bar model remove in mobile  */



@media (max-width: 567px) {
  .header-style-3 .logo-header {
    width: 100px !important;
  }

}

@media (max-width: 367px) {
  .extra-nav.header-2-nav .g-translate {
    display: none;
  }

  .header-style-3 .header-heading {
    display: none !important;
    padding: 0;
  }


  .header-style-3 .header-search a.header-search-icon {
    display: none;
  }
}

.header-style-3 .extra-nav {
  display: table;
  height: 70px;
  position: relative;
  z-index: 13;
  /* display: flex; */
  /* align-items: center; */
}

.header-style-3 .extra-nav .extra-cell {
  display: table-cell;
  padding-left: 25px;
  vertical-align: middle;
}

.header-style-3 .extra-nav .extra-cell:first-child {
  padding-left: 0px;
}

@media (max-width: 600px) {
  .header-style-3 .extra-nav .extra-cell {
    padding-left: 0px;
  }
}

.header-style-3 .logo-header {
  display: table;
  float: left;
  /* width: 190px;
  height: 70px; */
  vertical-align: middle;
  padding: 0px;
  font-size: 16px;
  color: #000;
  margin: 0px;
  position: relative;
  z-index: 20;

}

.header-style-3 .header-heading {
  display: flex;
  font-size: 13px;
  padding-left: 4px;
  font-weight: 500;
}

@media (max-width: 1199px) {
  .header-style-3 .logo-header {
    width: 280px;
    height: 70px;
  }
}

@media (max-width: 400px) {
  .header-style-3 .logo-header {
    width: 100px;
  }
}

.header-style-3 .logo-header .logo-header-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.header-style-3 .logo-header .logo-header-inner img {
  max-width: 100%;
  max-height: 100%;
  width: 72px;
  padding: 2px;
}

.header-style-3.site-header {
  /* position: absolute; */
  width: 100%;
  /* left: 0px;
  top: 0px;*/
  z-index: 99;
  /* max-height: 0;  */
}

.top_the_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
}


.header-style-3.site-header ul,
.header-style-3.site-header ol {
  margin-bottom: 0px;
}

.header-style-3 .main-bar {
  /*--main-bar--*/
  /* position: relative; */
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  width: 100%;
  background: gainsboro;
  left: 50%;
}

.header-style-3 .main-bar .container,
.header-style-3 .main-bar .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {

  .header-style-3 .main-bar .container,
  .header-style-3 .main-bar .container-fluid {
    display: block;
  }
}

.header-style-3 .main-bar:after,
.header-style-3 .main-bar:after {
  clear: both;
  content: "";
  display: table;
}

.header-style-3 .navbar-toggler {
  /*--Navbar Toggler---*/
  border: none;
  margin: 24px 0px 24px 15px;
  padding: 12px 10px;
}

@media (max-width: 540px) {
  .header-style-3 .navbar-toggler {
    margin-left: 0px;
  }
}

.header-style-3 .navbar-toggler:focus {
  box-shadow: none !important;
}

@media (max-width: 991px) {
  .header-style-3 .navbar-toggler {
    display: block;
    margin-top: 14px;
    margin-bottom: 14px;
    float: right;
    outline: none !important;
  }
}

.header-style-3 .navbar-toggler .icon-bar {
  background: #1967d2;
  height: 3px;
  border-radius: 0px;
  display: block;
  width: 22px;
}

.header-style-3 .navbar-toggler .icon-bar+.icon-bar {
  margin-top: 4px;
}

@media (max-width: 991px) {
  .header-style-3 .navbar-toggler {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

@media (max-width: 540px) {
  .header-style-3 .is-fixed .navbar-toggler {
    margin-bottom: 0px;
  }
}

.header-style-3 .is-fixed.mobile-sider-drawer-menu .header-nav .nav {
  height: 100vh !important;
  width: 100%;
}

.header-style-3 .is-fixed.mobile-sider-drawer-menu .header-nav .nav>li>a {
  padding: 12px 15px !important;
}

.header-style-3 .header-nav {
  position: relative;
  padding: 0px;
  z-index: 10;
  /*-- Submenu direction---*/
}

@media (max-width: 991px) {
  .header-style-3 .header-nav {
    clear: both;
    margin: 0px -15px;
    border-bottom: 1px solid #e9e9e9;
  }

  .header-style-3 .header-nav.navbar-collapse {
    align-items: start;
  }
}

.header-style-3 .header-nav .nav {
  display: flex;
  justify-content: flex-end;
  flex-direction: inherit;
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav {
    float: none;
    margin: 0px;
    background: #fff;
    display: block;
    width: 100%;
    left: 0px;
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav li {
    float: none;
  }
}

.header-style-3 .header-nav .nav li i {
  font-size: 9px;
  margin-left: 3px;
  margin-top: -3px;
  vertical-align: middle;
  opacity: 0.7;
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav li i {
    float: right;
  }
}

.header-style-3 .header-nav .nav>li {
  position: relative;
  margin: -10px 0px;
  padding: 10px 0px;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav>li ul {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
}

.header-style-3 .header-nav .nav>li>a {
  color: #2f2f2f;
  font-size: 17px;
  padding: 15px 16px;
  cursor: pointer;
  margin: 0px 0px;
  font-weight: 400;
  display: block;
}

@media (max-width: 1199px) {
  .header-style-3 .header-nav .nav>li>a {
    padding: 12px 12px;
  }
}

.header-style-3 .header-nav .nav>li>a:hover {
  background-color: transparent;
  color: #1967d2;
}

.header-style-3 .header-nav .nav>li>a:active,
.header-style-3 .header-nav .nav>li>a:focus {
  background-color: transparent;
}

@media (max-width: 991px) {

  .header-style-3 .header-nav .nav>li.active>a,
  .header-style-3 .header-nav .nav>li.current-menu-item>a {
    color: #000;
  }
}

@media (max-width: 991px) {

  .header-style-3 .header-nav .nav>li>a:hover,
  .header-style-3 .header-nav .nav>li>a:active,
  .header-style-3 .header-nav .nav>li>a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }
}

.header-style-3 .header-nav .nav>li.active>a,
.header-style-3 .header-nav .nav>li.current-menu-item>a {
  background-color: transparent;
  color: #000;
}

.header-style-3 .header-nav .nav>li:hover>a {
  color: #1967d2;
}

.header-style-3 .header-nav .nav>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
  transition: all 0.3s ease;
}

.header-style-3 .header-nav .nav>li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0px;
  top: 100%;
  list-style: none;
  margin: 20px 0 0;
  opacity: 0;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
    opacity: 1;
    margin: 0px !important;
  }
}

.header-style-3 .header-nav .nav>li .sub-menu li {
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  text-transform: none;
}

.header-style-3 .header-nav .nav>li .sub-menu li a {
  color: #000;
  display: block;
  font-size: 13px;
  padding: 11px 20px;
  padding-left: 20px;
  font-weight: 500;
}

.header-style-3 .header-nav .nav>li .sub-menu li a:hover {
  color: #000;
  text-decoration: none;
}

.header-style-3 .header-nav .nav>li .sub-menu li:hover>a {
  color: #1967d2;
}

.header-style-3 .header-nav .nav>li .sub-menu li:last-child {
  border-bottom: 0px;
}

.header-style-3 .header-nav .nav>li .sub-menu li .fa {
  color: #000;
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: -1;
}

.header-style-3 .header-nav .nav>li .sub-menu li.has-child.nav-active>a {
  color: #1967d2;
}

.header-style-3 .header-nav .nav>li .sub-menu li>.sub-menu {
  left: 220px;
  margin: 0px 0px 0px 20px;
  transition: all 0.1s ease;
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav>li .sub-menu li>.sub-menu>li {
    float: none;
    display: block;
    width: auto;
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav>li .sub-menu li>.sub-menu>li a {
    padding-left: 30px;
  }
}

.header-style-3 .header-nav .nav>li .sub-menu li:hover>.sub-menu {
  left: 220px;
  margin: 0px;
  opacity: 1;
  top: -1px;
  visibility: visible;
}

.header-style-3 .header-nav .nav>li .sub-menu li:hover>.sub-menu:before {
  background-color: transparent;
  bottom: 0px;
  content: "";
  display: block;
  height: 100%;
  left: -6px;
  position: absolute;
  top: 0px;
  width: 6px;
}

/* activ links  */
.header-nav .active {
  color: #1967d6 !important;
  font-weight: bold !important;
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav>li .sub-menu li .fa {
    z-index: 1;
    top: 4px;
    width: 100%;
    padding: 10px;
    text-align: right;
  }

  .header-style-3 .header-nav .nav>li .sub-menu li>a:hover+.fa {
    z-index: 0;
  }
}

.header-style-3 .header-nav .submenu-direction .sub-menu {
  left: auto;
  right: 0px;
}

.header-style-3 .header-nav .submenu-direction .sub-menu li>.sub-menu {
  left: auto;
  right: 220px;
  margin: 0px 20px 0px 0px;
}

.header-style-3 .header-nav .submenu-direction .sub-menu li:hover>.sub-menu {
  left: auto;
  right: 220px;
  margin: 0px 0px 0px 0px;
}

.header-style-3 .header-nav.nav-animation .nav>li>ul.sub-menu li>a {
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .header-style-3 .header-nav.nav-animation .nav>li>ul.sub-menu li>a {
    z-index: 0;
  }
}

.header-style-3 .header-nav.nav-animation .nav>li:hover>ul.sub-menu li:hover>a {
  padding-left: 40px;
}

@media (max-width: 991px) {
  .header-style-3 .has-child {
    position: relative;
  }
}

.header-style-3 .has-child .submenu-toogle {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #000;
  background-color: transparent;
  z-index: 900;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  display: none;
}

@media (max-width: 991px) {
  .header-style-3 .has-child .submenu-toogle {
    display: block;
  }
}

.header-style-3 .has-child li .submenu-toogle {
  opacity: 0.9;
}

.header-style-3 .has-child.nav-active>a+.submenu-toogle.glyphicon-chevron-right:before {
  content: "\e114";
}

.header-style-3 .has-child.nav-active>a+.submenu-toogle.glyphicon-plus::before {
  content: "−";
}

@media (max-width: 991px) {
  .header-style-3 .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right::before {
    content: "\f107";
  }
}

@media (max-width: 991px) {
  .header-style-3 .mobile-full-width-menu .header-nav .nav {
    position: inherit;
  }
}

@media (max-width: 991px) {
  .header-style-3.mobile-sider-drawer-menu .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }

  .header-style-3.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
    animation: none !important;
  }

  .header-style-3.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu {
    transition: none !important;
  }

  .header-style-3.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
    animation: none !important;
  }

  .header-style-3.mobile-sider-drawer-menu.active .header-nav {
    left: 15px;
  }

  .header-style-3.mobile-sider-drawer-menu .icon-bar {
    transition: all 0.5s linear;
    position: relative;
  }

  .header-style-3.mobile-sider-drawer-menu #mobile-side-drawer {
    min-height: 41px;
    position: relative;
  }

  .header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }

  .header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }

  .header-style-3.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }

  .header-style-3.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }

  .header-style-3.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
}

.header-style-3 .sticky-no .main-bar {
  position: static !important;
}

.header-style-3 .is-fixed .main-bar {
  position: fixed;
}

.header-style-3 .is-fixed .main-bar.color-fill {
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
}

.header-style-3 .is-fixed .header-nav .nav>li {
  margin: 0px;
  padding: 0px;
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav>li {
    margin: 0px;
    padding: 0px;
  }

  .header-style-3 .header-nav .nav>li>a {
    margin: 0px;
    padding: 10px 12px;
  }

  .header-style-3 .is-fixed .color-fill .header-nav .nav>li {
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .header-style-3 .main-bar {
    padding: 0px;
  }

  .header-style-3 .is-fixed .main-bar {
    padding: 0px;
  }

  .header-style-3 .is-fixed .main-bar.color-fill {
    padding: 0px;
  }

  h2 {
    font-size: 25px;
  }
}

.header-style-3.no-fixed {
  position: relative;
}

.header-full-width {
  position: relative;
  /*--is fixed---*/
  /*====Mobile Side =======*/
  /*---when header is sticky---*/
  /*---when header is sticky---*/
  /*---header id Fixed---*/
}

.header-full-width .header-nav-btn-section {
  float: right;
  display: flex;
}

.header-full-width .header-nav-btn-section .twm-nav-btn-left {
  margin-right: 10px;
}

.header-full-width .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
  background-color: #e0eeff;
  color: #1967d2;
  font-size: 16px;
  border-radius: 10px;
  padding: 8px 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.header-full-width .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up:before {
  width: 0%;
  height: 100%;
  position: absolute;
  right: 100%;
  bottom: 0px;
  content: "";
  background-color: #3898e2;
  opacity: 0.1;
  z-index: -1;
  transition: 0.5s all ease;
}

@media (max-width: 575px) {
  .header-full-width .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 1360px) {
  .header-full-width .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    font-size: 0px;
  }
}

.header-full-width .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up i {
  padding-right: 5px;
}

@media (max-width: 1360px) {
  .header-full-width .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up i {
    font-size: 16px;
    padding-right: 0px;
  }
}

.header-full-width .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up:hover:before {
  width: 100%;
  right: 0%;
  opacity: 0.1;
}

.header-full-width .header-nav-btn-section .twm-nav-btn-right {
  margin-right: 10px;
}

.header-full-width .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
  background-color: #1967d2;
  color: #f0f6fe;
  font-size: 16px;
  border-radius: 10px;
  padding: 8px 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.header-full-width .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job:before {
  width: 0%;
  height: 100%;
  position: absolute;
  right: 100%;
  bottom: 0px;
  content: "";
  background-color: #17171d;
  opacity: 0.1;
  z-index: -1;
  transition: 0.5s all ease;
}

@media (max-width: 575px) {
  .header-full-width .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 1360px) {
  .header-full-width .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    font-size: 0px;
  }
}

.header-full-width .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job i {
  padding-right: 5px;
}

@media (max-width: 1360px) {
  .header-full-width .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job i {
    font-size: 16px;
    padding-right: 0px;
  }
}

.header-full-width .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job:hover:before {
  width: 100%;
  right: 0%;
  opacity: 1;
}

.header-full-width .header-search a.header-search-icon {
  color: #1967d2;
  width: 54px;
  height: 54px;
  line-height: 54px;
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .header-full-width .header-search a.header-search-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.header-full-width .extra-nav {
  /*---Extra Nav---*/
  display: table;
  float: right;
  height: 70px;
  position: relative;
  z-index: 13;
}

.header-full-width .extra-nav .extra-cell {
  display: table-cell;
  padding-left: 25px;
  vertical-align: middle;
}

.header-full-width .extra-nav .extra-cell:first-child {
  padding-left: 0px;
}

@media (max-width: 600px) {
  .header-full-width .extra-nav .extra-cell {
    padding-left: 0px;
  }
}

.header-full-width .logo-header {
  /*--logo for header---*/
  display: table;
  float: left;
  width: 190px;
  height: 70px;
  vertical-align: middle;
  padding: 0px;
  font-size: 36px;
  color: #000;
  margin: 0px;
  position: relative;
  z-index: 11;
}

@media (max-width: 1199px) {
  .header-full-width .logo-header {
    width: 130px;
  }
}

@media (max-width: 400px) {
  .header-full-width .logo-header {
    width: 70px;
  }
}

.header-full-width .logo-header .logo-header-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.header-full-width .logo-header .logo-header-inner img {
  width: 54px;
}

.header-full-width.site-header {
  position: relative;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
}

.header-full-width.site-header ul,
.header-full-width.site-header ol {
  margin-bottom: 0px;
}

.header-full-width .main-bar {
  /*--main-bar--*/
  position: relative;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
}

.header-full-width .main-bar .container,
.header-full-width .main-bar .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {

  .header-full-width .main-bar .container,
  .header-full-width .main-bar .container-fluid {
    display: block;
  }
}

.header-full-width .main-bar:after,
.header-full-width .main-bar:after {
  clear: both;
  content: "";
  display: table;
}

.header-full-width .navbar-toggler {
  /*--Navbar Toggler---*/
  border: none;
  margin: 24px 0px 24px 15px;
  padding: 12px 10px;
}

@media (max-width: 540px) {
  .header-full-width .navbar-toggler {
    margin-left: 0px;
  }
}

.header-full-width .navbar-toggler:focus {
  box-shadow: none !important;
}

@media (max-width: 991px) {
  .header-full-width .navbar-toggler {
    display: block;
    margin-top: 14px;
    margin-bottom: 14px;
    float: right;
    outline: none !important;
  }
}

.header-full-width .navbar-toggler .icon-bar {
  background: #1967d2;
  height: 3px;
  border-radius: 0px;
  display: block;
  width: 22px;
}

.header-full-width .navbar-toggler .icon-bar+.icon-bar {
  margin-top: 4px;
}

@media (max-width: 991px) {
  .header-full-width .navbar-toggler {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

@media (max-width: 540px) {
  .header-full-width .is-fixed .navbar-toggler {
    margin-bottom: 0px;
  }
}

.header-full-width .is-fixed.mobile-sider-drawer-menu .header-nav .nav {
  height: 100vh !important;
  width: 100%;
}

.header-full-width .is-fixed.mobile-sider-drawer-menu .header-nav .nav>li>a {
  padding: 12px 15px !important;
}

.header-full-width .header-nav {
  position: relative;
  padding: 0px;
  z-index: 10;
  /*-- Submenu direction---*/
}

@media (max-width: 991px) {
  .header-full-width .header-nav {
    clear: both;
    margin: 0px -15px;
    border-bottom: 1px solid #e9e9e9;
  }

  .header-full-width .header-nav.navbar-collapse {
    align-items: start;
  }
}

.header-full-width .header-nav .nav {
  display: flex;
  justify-content: flex-end;
  flex-direction: inherit;
}

@media (max-width: 991px) {
  .header-full-width .header-nav .nav {
    float: none;
    margin: 0px;
    background: #fff;
    display: block;
    width: 100%;
    left: 0px;
  }
}

@media (max-width: 991px) {
  .header-full-width .header-nav .nav li {
    float: none;
  }
}

.header-full-width .header-nav .nav li i {
  font-size: 9px;
  margin-left: 3px;
  margin-top: -3px;
  vertical-align: middle;
  opacity: 0.7;
}

@media (max-width: 991px) {
  .header-full-width .header-nav .nav li i {
    float: right;
  }
}

.header-full-width .header-nav .nav>li {
  position: relative;
  margin: -10px 0px;
  padding: 10px 0px;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 991px) {
  .header-full-width .header-nav .nav>li ul {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
}

.header-full-width .header-nav .nav>li>a {
  color: #2f2f2f;
  font-size: 16px;
  padding: 15px 16px;
  cursor: pointer;
  margin: 0px 0px;
  font-weight: 400;
  display: block;
}

@media (max-width: 1199px) {
  .header-full-width .header-nav .nav>li>a {
    padding: 12px 12px;
  }
}

.header-full-width .header-nav .nav>li>a:hover {
  background-color: transparent;
  color: #1967d2;
}

.header-full-width .header-nav .nav>li>a:active,
.header-full-width .header-nav .nav>li>a:focus {
  background-color: transparent;
}

@media (max-width: 991px) {

  .header-full-width .header-nav .nav>li.active>a,
  .header-full-width .header-nav .nav>li.current-menu-item>a {
    color: #000;
  }
}

@media (max-width: 991px) {

  .header-full-width .header-nav .nav>li>a:hover,
  .header-full-width .header-nav .nav>li>a:active,
  .header-full-width .header-nav .nav>li>a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }
}

.header-full-width .header-nav .nav>li.active>a,
.header-full-width .header-nav .nav>li.current-menu-item>a {
  background-color: transparent;
  color: #000;
}

.header-full-width .header-nav .nav>li:hover>a {
  color: #1967d2;
}

.header-full-width .header-nav .nav>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
  transition: all 0.3s ease;
}

.header-full-width .header-nav .nav>li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0px;
  top: 100%;
  list-style: none;
  margin: 20px 0 0;
  opacity: 0;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
}

@media (max-width: 991px) {
  .header-full-width .header-nav .nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
    opacity: 1;
    margin: 0px !important;
  }
}

.header-full-width .header-nav .nav>li .sub-menu li {
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  text-transform: none;
}

.header-full-width .header-nav .nav>li .sub-menu li a {
  color: #000;
  display: block;
  font-size: 13px;
  padding: 11px 20px;
  padding-left: 20px;
  font-weight: 500;
}

.header-full-width .header-nav .nav>li .sub-menu li a:hover {
  color: #000;
  text-decoration: none;
}

.header-full-width .header-nav .nav>li .sub-menu li:hover>a {
  color: #1967d2;
}

.header-full-width .header-nav .nav>li .sub-menu li:last-child {
  border-bottom: 0px;
}

.header-full-width .header-nav .nav>li .sub-menu li .fa {
  color: #000;
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: -1;
}

.header-full-width .header-nav .nav>li .sub-menu li.has-child.nav-active>a {
  color: #1967d2;
}

.header-full-width .header-nav .nav>li .sub-menu li>.sub-menu {
  left: 220px;
  margin: 0px 0px 0px 20px;
  transition: all 0.1s ease;
}

@media (max-width: 991px) {
  .header-full-width .header-nav .nav>li .sub-menu li>.sub-menu>li {
    float: none;
    display: block;
    width: auto;
  }
}

@media (max-width: 991px) {
  .header-full-width .header-nav .nav>li .sub-menu li>.sub-menu>li a {
    padding-left: 30px;
  }
}

.header-full-width .header-nav .nav>li .sub-menu li:hover>.sub-menu {
  left: 220px;
  margin: 0px;
  opacity: 1;
  top: -1px;
  visibility: visible;
}

.header-full-width .header-nav .nav>li .sub-menu li:hover>.sub-menu:before {
  background-color: transparent;
  bottom: 0px;
  content: "";
  display: block;
  height: 100%;
  left: -6px;
  position: absolute;
  top: 0px;
  width: 6px;
}

@media (max-width: 991px) {
  .header-full-width .header-nav .nav>li .sub-menu li .fa {
    z-index: 1;
    top: 4px;
    width: 100%;
    padding: 10px;
    text-align: right;
  }

  .header-full-width .header-nav .nav>li .sub-menu li>a:hover+.fa {
    z-index: 0;
  }
}

.header-full-width .header-nav .submenu-direction .sub-menu {
  left: auto;
  right: 0px;
}

.header-full-width .header-nav .submenu-direction .sub-menu li>.sub-menu {
  left: auto;
  right: 220px;
  margin: 0px 20px 0px 0px;
}

.header-full-width .header-nav .submenu-direction .sub-menu li:hover>.sub-menu {
  left: auto;
  right: 220px;
  margin: 0px 0px 0px 0px;
}

.header-full-width .header-nav.nav-animation .nav>li>ul.sub-menu li>a {
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .header-full-width .header-nav.nav-animation .nav>li>ul.sub-menu li>a {
    z-index: 0;
  }
}

.header-full-width .header-nav.nav-animation .nav>li:hover>ul.sub-menu li:hover>a {
  padding-left: 40px;
}

@media (max-width: 991px) {
  .header-full-width .has-child {
    position: relative;
  }
}

.header-full-width .has-child .submenu-toogle {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #000;
  background-color: transparent;
  z-index: 900;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  display: none;
}

@media (max-width: 991px) {
  .header-full-width .has-child .submenu-toogle {
    display: block;
  }
}

.header-full-width .has-child li .submenu-toogle {
  opacity: 0.9;
}

.header-full-width .has-child.nav-active>a+.submenu-toogle.glyphicon-chevron-right:before {
  content: "\e114";
}

.header-full-width .has-child.nav-active>a+.submenu-toogle.glyphicon-plus::before {
  content: "−";
}

@media (max-width: 991px) {
  .header-full-width .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right::before {
    content: "\f107";
  }
}

@media (max-width: 991px) {
  .header-full-width .mobile-full-width-menu .header-nav .nav {
    position: inherit;
  }
}

@media (max-width: 991px) {
  .header-full-width.mobile-sider-drawer-menu .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }

  .header-full-width.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
    animation: none !important;
  }

  .header-full-width.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu {
    transition: none !important;
  }

  .header-full-width.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
    animation: none !important;
  }

  .header-full-width.mobile-sider-drawer-menu.active .header-nav {
    left: 15px;
  }

  .header-full-width.mobile-sider-drawer-menu .icon-bar {
    transition: all 0.5s linear;
    position: relative;
  }

  .header-full-width.mobile-sider-drawer-menu #mobile-side-drawer {
    min-height: 41px;
    position: relative;
  }

  .header-full-width.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }

  .header-full-width.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }

  .header-full-width.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-full-width.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }

  .header-full-width.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }

  .header-full-width.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
}

.header-full-width .sticky-no .main-bar {
  position: static !important;
}

.header-full-width .is-fixed .main-bar {
  position: fixed;
}

.header-full-width .is-fixed.color-fill .main-bar {
  background-color: #fff;
  border-radius: 10px;
}

.header-full-width .is-fixed.color-fill .header-nav .nav>li {
  margin: 0px;
  padding: 0px;
}

@media (max-width: 991px) {
  .header-full-width .header-nav .nav>li {
    margin: 0px;
    padding: 0px;
  }

  .header-full-width .header-nav .nav>li>a {
    margin: 0px;
    padding: 10px 12px;
  }

  .header-full-width .is-fixed.color-fill .header-nav .nav>li {
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .header-full-width .main-bar {
    padding: 0px;
  }

  .header-full-width .is-fixed .main-bar {
    padding: 0px;
  }

  .header-full-width .is-fixed.color-fill .main-bar {
    padding: 0px;
  }
}

.header-full-width.no-fixed {
  position: relative;
}

.header-full-width .color-fill .main-bar {
  position: fixed;
  animation: none;
}

.header-style-light {
  position: relative;
  /*--is fixed---*/
  /*====Mobile Side =======*/
  /*---when header is sticky---*/
  /*---when header is sticky---*/
  /*---header id Fixed---*/
}

.header-style-light .header-nav-btn-section {
  float: right;
  display: flex;
}

.header-style-light .header-nav-btn-section .twm-nav-btn-left {
  margin-right: 10px;
}

.header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
  background-color: #f0f6fe;
  color: #1967d2;
  font-size: 16px;
  border-radius: 10px;
  padding: 8px 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up:before {
  width: 0%;
  height: 100%;
  position: absolute;
  right: 100%;
  bottom: 0px;
  content: "";
  background-color: #3898e2;
  opacity: 0.1;
  z-index: -1;
  transition: 0.5s all ease;
}

@media (max-width: 575px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 540px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    font-size: 0px;
  }
}

.header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up i {
  padding-right: 5px;
}

@media (max-width: 540px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up i {
    font-size: 16px;
    padding-right: 0px;
  }
}

.header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up:hover:before {
  width: 100%;
  right: 0%;
  opacity: 0.1;
}

.header-style-light .header-nav-btn-section .twm-nav-btn-right {
  margin-right: 10px;
}

.header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
  background-color: #17171d;
  color: #f0f6fe;
  font-size: 16px;
  border-radius: 10px;
  padding: 8px 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job:before {
  width: 0%;
  height: 100%;
  position: absolute;
  right: 100%;
  bottom: 0px;
  content: "";
  background-color: #1967d2;
  opacity: 0.1;
  z-index: -1;
  transition: 0.5s all ease;
}

@media (max-width: 575px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 540px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    font-size: 0px;
  }
}

.header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job i {
  padding-right: 5px;
}

@media (max-width: 540px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job i {
    font-size: 16px;
    padding-right: 0px;
  }
}

.header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job:hover:before {
  width: 100%;
  right: 0%;
  opacity: 1;
}

.header-style-light .header-search a.header-search-icon {
  color: #fff;
  width: 54px;
  height: 54px;
  line-height: 54px;
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  border-radius: 50%;
}

.header-style-light .extra-nav {
  /*---Extra Nav---*/
  display: table;
  float: right;
  height: 70px;
  position: relative;
  z-index: 13;
}

.header-style-light .extra-nav .extra-cell {
  display: table-cell;
  padding-left: 25px;
  vertical-align: middle;
}

.header-style-light .extra-nav .extra-cell:first-child {
  padding-left: 0px;
}

@media (max-width: 540px) {
  .header-style-light .extra-nav .extra-cell {
    padding-left: 0px;
  }
}

.header-style-light .logo-header {
  /*--logo for header---*/
  display: table;
  float: left;
  width: 190px;
  height: 70px;
  vertical-align: middle;
  padding: 0px;
  font-size: 36px;
  color: #000;
  margin: 0px;
  position: relative;
  z-index: 11;
}

@media (max-width: 1199px) {
  .header-style-light .logo-header {
    width: 130px;
  }
}

@media (max-width: 400px) {
  .header-style-light .logo-header {
    width: 90px;
  }
}

.header-style-light .logo-header .logo-header-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.header-style-light .logo-header .logo-header-inner img {
  max-width: 100%;
  max-height: 100%;
}

.header-style-light.site-header {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
}

.header-style-light.site-header ul,
.header-style-light.site-header ol {
  margin-bottom: 0px;
}

.header-style-light .main-bar {
  /*--main-bar--*/
  position: relative;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  max-width: 1146px;
  left: 50%;
  transform: translateX(-50%);
}

.header-style-light .main-bar .container,
.header-style-light .main-bar .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {

  .header-style-light .main-bar .container,
  .header-style-light .main-bar .container-fluid {
    display: block;
  }
}

.header-style-light .main-bar:after,
.header-style-light .main-bar:after {
  clear: both;
  content: "";
  display: table;
}

.header-style-light .navbar-toggler {
  /*--Navbar Toggler---*/
  border: none;
  margin: 24px 0px 24px 15px;
  padding: 12px 10px;
}

@media (max-width: 540px) {
  .header-style-light .navbar-toggler {
    margin-left: 0px;
  }
}

.header-style-light .navbar-toggler:focus {
  box-shadow: none !important;
}

@media (max-width: 991px) {
  .header-style-light .navbar-toggler {
    display: block;
    margin-top: 14px;
    margin-bottom: 14px;
    float: right;
    outline: none !important;
  }
}

.header-style-light .navbar-toggler .icon-bar {
  background: #fff;
  height: 3px;
  border-radius: 0px;
  display: block;
  width: 22px;
}

.header-style-light .navbar-toggler .icon-bar+.icon-bar {
  margin-top: 4px;
}

@media (max-width: 991px) {
  .header-style-light .navbar-toggler {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

@media (max-width: 540px) {
  .header-style-light .is-fixed .navbar-toggler {
    margin-bottom: 0px;
  }
}

.header-style-light .is-fixed.mobile-sider-drawer-menu .header-nav .nav {
  height: 100vh !important;
  width: 100%;
}

.header-style-light .is-fixed.mobile-sider-drawer-menu .header-nav .nav>li>a {
  padding: 12px 15px !important;
}

.header-style-light .header-nav {
  position: relative;
  padding: 0px;
  z-index: 10;
  /*-- Submenu direction---*/
}

@media (max-width: 991px) {
  .header-style-light .header-nav {
    clear: both;
    margin: 0px -15px;
    border-bottom: 1px solid #e9e9e9;
  }

  .header-style-light .header-nav.navbar-collapse {
    align-items: start;
  }
}

.header-style-light .header-nav .nav {
  display: flex;
  justify-content: flex-end;
  flex-direction: inherit;
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav {
    float: none;
    margin: 0px;
    background: #fff;
    display: block;
    width: 100%;
    left: 0px;
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav li {
    float: none;
  }
}

.header-style-light .header-nav .nav li i {
  font-size: 9px;
  margin-left: 3px;
  margin-top: -3px;
  vertical-align: middle;
  opacity: 0.7;
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav li i {
    float: right;
  }
}

.header-style-light .header-nav .nav>li {
  position: relative;
  margin: -10px 0px;
  padding: 10px 0px;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav>li ul {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
}

.header-style-light .header-nav .nav>li>a {
  color: #fff;
  font-size: 16px;
  padding: 15px 16px;
  cursor: pointer;
  margin: 0px 0px;
  font-weight: 400;
  display: block;
}

@media (max-width: 1199px) {
  .header-style-light .header-nav .nav>li>a {
    padding: 12px 12px;
  }
}

.header-style-light .header-nav .nav>li>a:hover {
  background-color: transparent;
  opacity: 0.8;
}

.header-style-light .header-nav .nav>li>a:active,
.header-style-light .header-nav .nav>li>a:focus {
  background-color: transparent;
}

@media (max-width: 991px) {

  .header-style-light .header-nav .nav>li.active>a,
  .header-style-light .header-nav .nav>li.current-menu-item>a {
    color: #000;
  }
}

@media (max-width: 991px) {

  .header-style-light .header-nav .nav>li>a:hover,
  .header-style-light .header-nav .nav>li>a:active,
  .header-style-light .header-nav .nav>li>a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }
}

.header-style-light .header-nav .nav>li.active>a,
.header-style-light .header-nav .nav>li.current-menu-item>a {
  background-color: transparent;
  color: #000;
}

.header-style-light .header-nav .nav>li:hover>a {
  opacity: 0.8;
}

.header-style-light .header-nav .nav>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
  transition: all 0.3s ease;
}

.header-style-light .header-nav .nav>li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0px;
  top: 100%;
  list-style: none;
  margin: 20px 0 0;
  opacity: 0;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
    opacity: 1;
    margin: 0px !important;
  }
}

.header-style-light .header-nav .nav>li .sub-menu li {
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  text-transform: none;
}

.header-style-light .header-nav .nav>li .sub-menu li a {
  color: #000;
  display: block;
  font-size: 13px;
  padding: 11px 20px;
  padding-left: 20px;
  font-weight: 500;
}

.header-style-light .header-nav .nav>li .sub-menu li a:hover {
  color: #000;
  text-decoration: none;
}

.header-style-light .header-nav .nav>li .sub-menu li:hover>a {
  color: #1967d2;
}

.header-style-light .header-nav .nav>li .sub-menu li:last-child {
  border-bottom: 0px;
}

.header-style-light .header-nav .nav>li .sub-menu li .fa {
  color: #000;
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 2px;
  top: 6px;
  z-index: -1;
}

.header-style-light .header-nav .nav>li .sub-menu li.has-child.nav-active>a {
  color: #1967d2;
}

.header-style-light .header-nav .nav>li .sub-menu li>.sub-menu {
  left: 220px;
  margin: 0px 0px 0px 20px;
  transition: all 0.1s ease;
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav>li .sub-menu li>.sub-menu>li {
    float: none;
    display: block;
    width: auto;
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav>li .sub-menu li>.sub-menu>li a {
    padding-left: 30px;
  }
}

.header-style-light .header-nav .nav>li .sub-menu li:hover>.sub-menu {
  left: 220px;
  margin: 0px;
  opacity: 1;
  top: -1px;
  visibility: visible;
}

.header-style-light .header-nav .nav>li .sub-menu li:hover>.sub-menu:before {
  background-color: transparent;
  bottom: 0px;
  content: "";
  display: block;
  height: 100%;
  left: -6px;
  position: absolute;
  top: 0px;
  width: 6px;
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav>li .sub-menu li .fa {
    z-index: 1;
    top: 4px;
    width: 100%;
    padding: 10px;
    text-align: right;
  }

  .header-style-light .header-nav .nav>li .sub-menu li>a:hover+.fa {
    z-index: 0;
  }
}

.header-style-light .header-nav .submenu-direction .sub-menu {
  left: auto;
  right: 0px;
}

.header-style-light .header-nav .submenu-direction .sub-menu li>.sub-menu {
  left: auto;
  right: 220px;
  margin: 0px 20px 0px 0px;
}

.header-style-light .header-nav .submenu-direction .sub-menu li:hover>.sub-menu {
  left: auto;
  right: 220px;
  margin: 0px 0px 0px 0px;
}

.header-style-light .header-nav.nav-animation .nav>li>ul.sub-menu li>a {
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .header-style-light .header-nav.nav-animation .nav>li>ul.sub-menu li>a {
    z-index: 0;
  }
}

.header-style-light .header-nav.nav-animation .nav>li:hover>ul.sub-menu li:hover>a {
  padding-left: 40px;
}

@media (max-width: 991px) {
  .header-style-light .has-child {
    position: relative;
  }
}

.header-style-light .has-child .submenu-toogle {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #000;
  background-color: transparent;
  z-index: 900;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  display: none;
}

@media (max-width: 991px) {
  .header-style-light .has-child .submenu-toogle {
    display: block;
  }
}

.header-style-light .has-child li .submenu-toogle {
  opacity: 0.9;
}

.header-style-light .has-child.nav-active>a+.submenu-toogle.glyphicon-chevron-right:before {
  content: "\e114";
}

.header-style-light .has-child.nav-active>a+.submenu-toogle.glyphicon-plus::before {
  content: "−";
}

@media (max-width: 991px) {
  .header-style-light .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right::before {
    content: "\f107";
  }
}

@media (max-width: 991px) {
  .header-style-light .mobile-full-width-menu .header-nav .nav {
    position: inherit;
  }
}

@media (max-width: 991px) {
  .header-style-light.mobile-sider-drawer-menu .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }

  .header-style-light.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
    animation: none !important;
  }

  .header-style-light.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu {
    transition: none !important;
  }

  .header-style-light.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
    animation: none !important;
  }

  .header-style-light.mobile-sider-drawer-menu.active .header-nav {
    left: 14px;
  }

  .header-style-light.mobile-sider-drawer-menu .icon-bar {
    transition: all 0.5s linear;
    position: relative;
  }

  .header-style-light.mobile-sider-drawer-menu #mobile-side-drawer {
    min-height: 41px;
    position: relative;
  }

  .header-style-light.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }

  .header-style-light.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }

  .header-style-light.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-light.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }

  .header-style-light.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }

  .header-style-light.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
}

.header-style-light .sticky-no .main-bar {
  position: static !important;
}

.header-style-light .is-fixed .main-bar {
  position: fixed;
}

.header-style-light .is-fixed .main-bar.color-fill {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
}

.header-style-light .is-fixed .header-nav .nav>li {
  margin: 0px;
  padding: 0px;
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav>li {
    margin: 0px;
    padding: 0px;
  }

  .header-style-light .header-nav .nav>li>a {
    margin: 0px;
    padding: 10px 12px;
    color: #000;
  }

  .header-style-light .is-fixed .color-fill .header-nav .nav>li {
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .header-style-light .main-bar {
    padding: 0px;
  }

  .header-style-light .is-fixed .main-bar {
    padding: 0px;
  }

  .header-style-light .is-fixed .main-bar.color-fill {
    padding: 0px;
  }
}

.header-style-light .on-scroll-show {
  display: none;
}

.header-style-light .is-fixed .color-fill .on-scroll-show {
  display: block;
}

.header-style-light .is-fixed .color-fill .default-scroll-show {
  display: none;
}

.header-style-light .color-fill .navbar-toggler .icon-bar {
  background: #1967d2;
}

.header-style-light .color-fill .header-nav .nav li a {
  color: #17171d;
}

#map {
  height: 100%;
  width: 100%;
  margin: 0;
  z-index: 1;
}

#map-container {
  position: relative;
  top: 0;
  left: 0;
  height: 600px;
  width: 100%;
  z-index: 10;
}

#map-container.fullwidth-home-map {
  height: 620px;
  margin-bottom: 0px;
}

#map-container.fullwidth-home-map .main-search-inner {
  position: absolute;
  display: block;
  bottom: 60px;
  top: auto;
  transform: none;
  padding-bottom: 0;
  z-index: 9999;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

#map-container.fullwidth-home-map .main-search-input {
  margin-top: 0;
}

.gmnoprint {
  margin: 10px;
}

.cluster img {
  display: none;
}

.cluster0 {
  text-align: center;
  font-size: 15px !important;
  color: #17171d !important;
  font-weight: 500;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
  background-color: #fff;
}

.cluster0:before {
  border: 7px solid #1967d2;
  opacity: 0.7;
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {

  0%,
  100% {
    transform: scale(1.3) rotate(0deg);
  }

  50% {
    transform: scale(1.5) rotate(90deg);
  }
}

.map-marker-container {
  position: absolute;
  margin-top: 10px;
  transform: translate3d(-50%, -100%, 0);
}

.marker-container {
  position: relative;
  margin: 10px auto;
  width: 36px;
  height: 36px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  perspective: 1000;
}

.marker-container .marker-card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.4s ease-in-out;
  position: absolute;
  z-index: 1;
}

.marker-container:hover .marker-card {
  transform: rotateY(180deg) translateX(0px);
}

.marker-container.clicked .marker-card {
  transform: rotateY(180deg) translateX(0px);
}

#singleListingMap .marker-container {
  cursor: default;
}

.marker-arrow {
  width: 0;
  content: "";
  height: 0;
  border-style: solid;
  border-width: 14px 13px 0;
  border-color: #66676b transparent transparent;
  top: 37px;
  left: 50%;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  border-color: #1967d2 transparent transparent;
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  z-index: 100;
}

.face i {
  width: 28px;
  height: 28px;
  line-height: 28px;
  background-color: #fff;
  color: #1967d2;
  font-size: 14px;
  margin: 4px;
  display: block;
  border-radius: 100%;
}

.face.front {
  background-color: #1967d2;
  color: #fff;
  z-index: 100;
}

.face.back {
  transform: rotateY(180deg);
  background: #1967d2;
  border-color: #1967d2;
  color: #fff;
}

.face.back i {
  transform: scaleX(-1);
}

.custom-zoom-in,
.custom-zoom-out {
  background-color: #1967d2;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  margin: 5px 15px;
  transition: color 0.3s, background-color 0.3s;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 15px;
  height: 34px;
  width: 34px;
}

.custom-zoom-in:hover,
.custom-zoom-out:hover {
  background-color: #253641;
  color: #fff;
}

.custom-zoom-in:before,
.custom-zoom-out:before {
  font-family: fontawesome;
  width: 100%;
  line-height: 35px;
}

.custom-zoom-in:before {
  content: "\f067";
}

.custom-zoom-out:before {
  content: "\f068";
}

.zoomControlWrapper {
  position: absolute;
  left: 0;
  right: auto;
  width: 70px;
}

.map-box {
  text-align: center;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  position: relative;
}

.map-box .listing-img-container {
  position: relative;
  height: 100%;
  display: block;
  background-color: #f0f6fe;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
}

.map-box .listing-img-container img {
  width: 100%;
  border-radius: 10px;
  max-width: 136px;
  display: block;
  margin: 0px auto 20px;
}

.map-box h4 {
  margin: 0;
  padding: 0px;
}

.map-box h4 a {
  padding: 0 0 2px;
  font-size: 17px;
  line-height: 25px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}

.map-box h4 a:hover {
  color: #66676b;
}

.map-box p {
  padding: 0;
  line-height: 25px;
  margin: 2px 0 0;
  font-size: 14px;
}

.map-box .listing-title {
  padding: 2px 22px;
}

.map-box ul.listing-details {
  padding: 0;
  font-size: 14px;
  display: none;
}

.listing-img-content {
  padding: 18px 22px;
}

.listing-img-content span {
  font-size: 21px;
}

.listing-img-content .listing-price i {
  font-size: 15px;
  margin: -7px 0 0;
}

.infoBox>img {
  position: absolute !important;
  right: 0;
  top: 0;
}

.listing-item-content {
  padding: 0 24px;
  width: 100%;
  z-index: 50;
  box-sizing: border-box;
}

.listing-item-content h3 {
  color: #17171d;
  font-size: 16px;
  padding: 0 0 10px;
  font-weight: 600;
  margin: 0;
  line-height: 20px;
  text-transform: capitalize;
}

.listing-item-content span {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  color: #3c3c3c;
}

.infoBox-close {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
  z-index: 999;
  text-align: center;
  line-height: 29px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  height: 29px;
  width: 29px;
  background-color: #fff;
  color: #333;
  transition: all 0.2s ease-in-out;
  font-family: fontawesome;
  border-radius: 50%;
  -webkit-text-stroke: 1px #fff;
}

.infoBox-close:hover {
  color: #fff;
  background-color: #66676b;
  -webkit-text-stroke: 1px #66676b;
}

.map-box-icon {
  height: 38px;
  width: 38px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #3685cf;
  display: block;
  color: #fff;
  font-size: 12px;
  z-index: 101;
  text-align: center;
  transition: all 0.2s ease-in-out;
  transform: translateX(50%);
  opacity: 0;
  visibility: hidden;
}

.map-box-icon:before {
  content: "\f064";
  font-family: fontawesome;
  position: relative;
  top: 7px;
  display: block;
}

.map-box-image {
  position: relative;
  overflow: hidden;
  display: block;
}

.map-box-image:hover .map-box-icon {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.infoBox {
  animation: fadeIn 0.9s;
}

.infoBox .infoBox:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#mapnav-buttons {
  position: absolute;
  transform: translate(0, 0);
  z-index: 999;
  font-size: 14px;
  display: inline-block;
  bottom: 20px;
  right: 20px;
  list-style: none;
  padding: 0;
}

#mapnav-buttons.top {
  top: 20px;
  right: 20px;
  bottom: auto;
}

#mapnav-buttons li {
  float: left;
  margin-left: 4px;
  line-height: 20px;
}

#mapnav-buttons a {
  color: #333;
  background-color: #fff;
  padding: 7px 18px;
  padding-top: 9px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 50px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.2);
}

#mapnav-buttons a:hover {
  background-color: #66676b;
  color: #fff;
}

#geoLocation {
  color: #333;
  background-color: #fff;
  padding: 7px 18px;
  padding-top: 9px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 50px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.2);
  right: auto;
  left: 20px;
  padding: 8px 11px;
}

#geoLocation:before {
  content: "\f192";
  font-family: fontawesome;
  font-size: 16px;
  margin: 0;
  top: 2px;
}

#geoLocation:hover {
  background-color: #66676b;
  color: #fff;
}

#nextpoint:after {
  font-family: fontawesome;
  position: relative;
  font-weight: 500;
  margin: 0 0 0 6px;
  font-size: 17px;
  top: 0;
  line-height: 1px;
  content: "\f105";
  margin: 0 0 0 6px;
}

#prevpoint:before {
  font-family: fontawesome;
  position: relative;
  font-weight: 500;
  margin: 0 0 0 6px;
  font-size: 17px;
  top: 0;
  line-height: 1px;
  content: "\f104";
  margin: 0 6px 0 0;
}



/*Title separators*/
.wt-small-separator {
  font-family: "Rubik", sans-serif;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  color: #f69619;

  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}

.wt-small-separator.white {
  color: #fff;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1170px;
  }
}


@media (max-width: 992px) {
  .wt-small-separator {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .wt-separator-two-part-right {
    text-align: left;
    margin-bottom: 30px;
  }

  .section-head.left.wt-small-separator-outer h2 {
    font-size: 30px;
  }

  .twm-companies-wrap {
    padding-bottom: 0px !important;
  }

  .section-head.center.wt-small-separator-outer h2 {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .wt-small-separator {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0px;
  }
}

.wt-separator-two-part-row {
  align-items: center;
}



/*Center title*/
.section-head.left.wt-small-separator-outer {
  text-align: left;
}

.section-head.left.wt-small-separator-outer h2 {
  margin-bottom: 35px;
}

@media (max-width: 768px) {
  .section-head.left.wt-small-separator-outer h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .wt-small-separator h2 {
    margin-bottom: 20px;
  }

  .main_title {
    font-size: 25px !important;
    line-height: 25px !important;
  }

  .section-head.center.wt-small-separator-outer h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .twm-company-approch {
    max-width: 520px;
  }
}

@media (max-width: 575px) {
  .section-head.left.wt-small-separator-outer h2 {
    font-size: 22px;
  }
}


.section-head.left.wt-small-separator-outer p {
  margin-bottom: 40px;
}

.section-head.center.wt-small-separator-outer {
  margin: 50px;
  text-align: center;
  max-width: 645px;
  margin-left: auto;
  margin-right: auto;
}

.section-head.center.wt-small-separator-outer .section-head-text {
  margin: 20px 0px 0px 0px;
}

.section-head.center.wt-small-separator-outer h2 {
  max-width: 650px;
  margin: 0px auto;
  font-size: 24px;
}




@media (max-width: 575px) {
  .section-head.center.wt-small-separator-outer h2 {
    font-size: 22px;
  }
}

/*Counter*/


.twm-company-approch-outer {
  position: relative;
  z-index: 1;
}

.twm-company-approch-outer:before {
  content: "";
  position: absolute;
  background-color: #f0f6fe;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 40px;
  z-index: -1;
}

.twm-company-approch {
  max-width: 650px;
  margin: 0px auto;
  background-color: #fff;
  padding: 25px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1) inset;
  position: relative;
  z-index: 1;
}

.twm-company-approch:after {
  content: "";
  position: absolute;
  left: -28px;
  bottom: 0px;
  z-index: 0;
  width: 0;
  height: 0;
  border-left: 28px solid transparent;
  border-right: 0px solid transparent;
  border-top: 40px solid #b3c2d6;
}

.counter-outer-two {
  position: relative;
}

@media (max-width: 991px) {
  .counter-outer-two {
    margin-bottom: 30px;
  }
}

.counter-outer-two .tw-count-number {
  font-size: 38px;
  line-height: 38px;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 991px) {
  .counter-outer-two .tw-count-number {
    font-size: 30px;
    line-height: 30px;
  }
}

.counter-outer-two .counter {
  font-size: 46px;
  line-height: 50px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 991px) {
  .counter-outer-two .counter {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .counter-outer-two .counter {
    font-size: 24px;
  }
}

.counter-outer-two .icon-content-info {
  color: #17171d;
  margin-bottom: 0px;
  font-size: 16px;
}

/*Client carousel 1*/
.home-client-carousel {
  padding: 30px 10px 10px 10px;
  background-color: #1967d2;
  z-index: 1;
  position: relative;
  bottom: -70px;
  left: 0px;
}

@media (max-width: 991px) {
  .home-client-carousel {
    bottom: 0px;
  }
}

.home-client-carousel:after,
.home-client-carousel:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
}

.home-client-carousel:after {
  right: 10px;
  bottom: -10px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

.home-client-carousel:before {
  left: -10px;
  top: 10px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
}

.home-client-carousel.owl-carousel .owl-dots {
  margin-top: 10px;
}

.home-client-carousel .client-logo a img {
  width: auto;
  height: 70px;
  margin: auto;
}

/*Client carousel 2*/
.home-client-carousel2 {
  padding: 0px;
  margin-bottom: 120px;
}

@media (max-width: 991px) {
  .home-client-carousel2 {
    margin-bottom: 30px;
  }
}

.home-client-carousel2 .client-logo {
  max-width: 130px;
  margin-left: auto;
  margin-right: auto;
}

.home-client-carousel2 .client-logo a {
  height: 100px;
  display: flex;
  justify-content: space-around;
}

.home-client-carousel2 .client-logo a img {
  width: auto;
  height: auto;
  filter: grayscale(100%);
  transition: 0.5s all ease;
}

.home-client-carousel2 .client-logo a:hover img {
  filter: none;
}

/*Footer News Letter*/
.ftr-nw-content {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .ftr-nw-content {
    margin-bottom: 40px;
  }
}

.ftr-nw-content .ftr-nw-title {
  font-size: 20px;
  color: #fff;
}

@media (max-width: 991px) {
  .ftr-nw-content .ftr-nw-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .ftr-nw-content .ftr-nw-form {
    display: block;
  }
}

@media (max-width: 575px) {
  .ftr-nw-content .ftr-nw-form .form-control {
    height: 48px;
  }
}

.ftr-nw-content .ftr-nw-form {
  display: flex;
}

.ftr-nw-content .ftr-nw-form .form-control {
  height: 66px;
  border: 0px;
  background-color: #dbe6f7;
  border-radius: 10px 0px 0px 10px;
}




.ftr-nw-content .ftr-nw-form .ftr-nw-subcribe-btn {
  font-size: 16px;
  color: #fff;
  background-color: var(--btn-color);
  border: none;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  padding: 9px 30px;
  border-radius: 0px 10px 10px 0px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .ftr-nw-content .ftr-nw-form .ftr-nw-subcribe-btn {
    font-size: 16px;
    padding: 7px 20px;
  }
}

@media (max-width: 400px) {
  .ftr-nw-content .ftr-nw-form .ftr-nw-subcribe-btn {
    border-radius: 10px;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
}

/*Footer dark version css*/
.footer-dark {
  color: #fff;
  position: relative;
  font-size: 16px;
  padding-top: 80px;
  background-size: cover;
  background-image: url(../src/assets/images/f-bg.jpg);
}

.footer-dark .footer-arrow {
  font-size: 35px;
  position: fixed;
  right: 25px;
  bottom: 10px;
  z-index: 20;
  color: var(--c);
  cursor: pointer;
}

@media (max-width: 991px) {
  .footer-dark {
    padding-top: 10px;
  }
}

.footer-dark p {
  font-weight: 300;
}

.footer-dark .logo-footer {
  margin-bottom: 25px;
  max-width: 174px;
}

.footer-dark .logo-footer a img {
  width: 85px;
  background-color: whitesmoke;
  border-radius: 50%;
}

.footer-dark .footer-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 80px 0px 30px 0px;
}

@media (max-width: 991px) {
  .footer-dark .footer-top {
    padding-top: 40px;
  }
}

@media (max-width: 991px) {
  .footer-dark .footer-top {
    padding-bottom: 0px;
  }
}

.footer-dark .ftr-list {
  list-style: none;
}

.footer-dark .ftr-list li p {
  margin-bottom: 0px;
  font-size: 16px;
}

.footer-dark .ftr-list li p span {
  margin-right: 5px;
  font-weight: 400;
}

.footer-dark .widget-title {
  font-family: "Rubik", sans-serif;
  color: #f0f6fe;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 30px;
  padding-bottom: 23px;
  position: relative;
}

.footer-dark .widget-title:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: var(--btn-color);
  width: 40px;
  height: 3px;
}

.footer-dark .social-icons {
  margin: 0px;
}

.footer-dark .social-icons li {
  display: inline-block;
}

.footer-dark .social-icons li a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  padding: 0px;
  color: #fff;
  font-size: 22px;
  text-align: center;
  margin-right: 3px;
}

.footer-dark .social-icons li a:hover {
  color: var(--btn-color);
}

.footer-dark .footer-bottom {
  padding: 0px;
  color: #fff;
  position: relative;
  z-index: 1;
  font-weight: 400;
}

.footer-dark .footer-bottom .footer-bottom-info {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
  align-items: center;
}

@media (max-width: 991px) {
  .footer-dark .footer-bottom .footer-bottom-info {
    display: block;
  }
}

@media (max-width: 991px) {
  .footer-dark .footer-bottom .footer-copy-right {
    margin: 5px 10px 5px 0px;
    display: inline-block;
  }
}

.footer-dark .footer-bottom .footer-copy-right .copyrights-text {
  color: #fff;
  font-size: 14px;
}

.footer-dark .footer-bottom .footer-copy-right a .copyrights-text:hover {
  color: var(--btn-color);
}

.footer-top .widget {
  margin-bottom: 20px;
}

/*Footer Light version css*/
.footer-light {
  color: #17171d;
  font-size: 16px;
  padding-top: 80px;
  background-color: #f0f6fe;
}

.footer-light .ftr-nw-content {

  margin-bottom: 50px;
}

.footer-light .ftr-nw-content .ftr-nw-title {
  font-size: 20px;
  color: #17171d;
}

.footer-light .ftr-nw-content .ftr-nw-form {
  display: flex;
}

.footer-light .ftr-nw-content .ftr-nw-form .form-control {
  height: 66px;
  border: 0px;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.footer-light .ftr-nw-content .ftr-nw-form .ftr-nw-subcribe-btn {
  font-size: 16px;
  color: #fff;
  background-color: #1967d2;
  border: none;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  padding: 9px 30px;
  border-radius: 0px 10px 10px 0px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .footer-light .ftr-nw-content .ftr-nw-form .ftr-nw-subcribe-btn {
    font-size: 16px;
    padding: 7px 20px;
  }
}

.footer-light p {
  font-weight: 300;
}

.footer-light .logo-footer {
  margin-bottom: 25px;
  max-width: 174px;
}

.footer-light .footer-top {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 80px 0px 30px 0px;
}

.footer-light .ftr-list {
  list-style: none;
}

.footer-light .ftr-list li p {
  margin-bottom: 0px;
  font-size: 16px;
}

.footer-light .ftr-list li p span {
  margin-right: 5px;
  font-weight: 500;
}

.footer-light .widget-title {
  font-family: "Rubik", sans-serif;
  color: #1967d2;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 30px;
  padding-bottom: 23px;
  position: relative;
}

.footer-light .widget-title:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #17171d;
  width: 40px;
  height: 3px;
}

.footer-light .social-icons {
  margin: 0px;
}

.footer-light .social-icons li {
  display: inline-block;
}

.footer-light .social-icons li a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  padding: 0px;
  color: #1967d2;
  font-size: 22px;
  text-align: center;
  margin-right: 3px;
}

.footer-light .social-icons li a:hover {
  color: #17171d;
}

.footer-light .footer-bottom {
  padding: 0px;
  color: #fff;
  position: relative;
  z-index: 1;
  font-weight: 400;
}

.footer-light .footer-bottom .footer-bottom-info {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
  align-items: center;
}

@media (max-width: 991px) {
  .footer-light .footer-bottom .footer-bottom-info {
    display: block;
  }
}

@media (max-width: 991px) {
  .footer-light .footer-bottom .footer-copy-right {
    margin: 5px 10px 5px 0px;
    display: inline-block;
  }
}

.footer-light .footer-bottom .footer-copy-right .copyrights-text {
  color: #17171d;
  font-size: 14px;
}

.footer-light .widget_services ul li a {
  color: #17171d;
}

.footer-light .widget_services ul li a:hover {
  color: #1967d2;
}

.widget {
  margin-bottom: 40px;
}

/*Footer Newsletter*/
.ftr-list-center {
  display: grid;
  /* justify-content: start; */
}

@media (max-width: 991px) {
  .ftr-list-center {
    justify-content: left;
  }
}

/*Recent Post Entry*/
.recent-posts-entry .widget-post {
  margin-bottom: 20px;
}

.recent-posts-entry .widget-post:last-child {
  border: none;
  margin-bottom: 0px;
}

.recent-posts-entry .wt-post-media {
  width: 90px;
  float: left;
}

@media (max-width: 1024px) {
  .recent-posts-entry .wt-post-media {
    width: 60px;
  }
}

.recent-posts-entry .wt-post-info {
  margin-left: 105px;
}

@media (max-width: 1024px) {
  .recent-posts-entry .wt-post-info {
    margin-left: 70px;
  }
}

.recent-posts-entry .wt-post-info .wt-post-header {
  margin-bottom: 6px;
}

.recent-posts-entry .wt-post-info .wt-post-meta ul {
  list-style: none;
  margin-bottom: 0px;
}

.recent-posts-entry .wt-post-info .wt-post-meta ul li {
  font-size: 12px;
  line-height: 16px;
  color: #1967d2;
}

.recent-posts-entry .wt-post-info .post-title {
  margin-top: 0px;
}

/* Footer CSS */

.footer-dark {
  background-color: #222;
  color: #fff;
  padding: 5px 0;
}

.footer-top {
  padding-bottom: 30px;
  border-bottom: 1px solid #444;
}

.adresssftr {
  font-size: 12px;
  color: whitesmoke;
  text-transform: capitalize;
}

.footer-top ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-top ul li {
  /* display: flex; */
  /* align-items: center; */
  width: 100%;
  /* gap: 5px; */
  padding: 2px 0;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
}

.footer-top ul li a {
  color: #fff;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 0;
  transition: all 0.6s ease-in-out;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}

.footer-top ul li p span {
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;

}

.footer-top ul li span:hover {
  padding-left: 2px;
  color: var(--btn-color);
  transition: padding-left 0.5s ease-in-out, color 0.5s ease-in-out;
}

.footer-top ul li a:hover {
  padding-left: 5px;
  color: var(--btn-color);
  transition: padding-left 0.5s ease-in-out, color 0.5s ease-in-out;
}

.footer-top .widget-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--btn-color);
}

.footer-bottom {
  padding-top: 20px;
  text-align: center;
  font-size: 14px;
  border-top: 1px solid #444;
}

.footer-bottom .footer-bottom-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-bottom .footer-copy-right {
  margin-bottom: 10px;
}

.footer-bottom a:hover {
  color: var(--btn-color);
}

.footer-bottom .social-icons {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.footer-bottom .social-icons li {
  margin: 0;
}

.footer-bottom .social-icons a {
  color: #fff;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-bottom .social-icons a:hover {
  color: var(--btn-color);
}

.footer-arrow {
  cursor: pointer;
  color: #fff;
  font-size: 2rem;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: color 0.3s ease;
}

.footer-arrow:hover {
  color: var(--btn-color);
}

/* Newsletter Section */

.ftr-nw-content {
  padding: 30px 20px;
  margin-bottom: 30px;
}

.ftr-nw-title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}


.ftr-nw-form .form-control {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.ftr-nw-form .ftr-nw-subscribe-btn {
  padding: 10px 20px;
  background-color: var(--btn-color);
  color: #fff;
  border: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ftr-nw-form .ftr-nw-subscribe-btn:hover {
  background-color: #333;
}

/* Add CSS Variable for Button Color */


/*Widget Services*/
/* .widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.widget_services ul li {
  display: block;
  width: 100%;
  padding: 2px 0px;
  border: 0px;
  overflow: hidden;
}

.widget_services ul li a {
  color: #fff;
  position: relative;
  padding: 0px 0px 5px 0px;
  display: block;
  margin-left: 0px;
  transition: 0.5s all ease;
  font-size: 14px;
  font-weight: 300;
}

.widget_services ul li a:hover {
  color: #1967d2;
} */

/*scroll top btn css*/
button.scroltop {
  height: 40px;
  width: 40px;
  background: #1d1b18;
  border: none;
  position: fixed;
  right: 15px;
  bottom: 15px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: none;
  text-align: center;
  z-index: 999;
  color: #fff;
}

button.scroltop span {
  display: block;
  font-size: 24px;
  line-height: 24px;
}

/*Pricing Table*/
.pricing-table-1 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  padding: 50px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

@media (max-width: 575px) {
  .pricing-table-1 {
    padding: 30px;
  }
}

.pricing-table-1:after {
  content: "";
  width: 400px;
  height: 400px;
  border-radius: 50%;
  left: -50px;
  top: -240px;
  z-index: -1;
  background-color: #f0f6fe;
  position: absolute;
}

@media (max-width: 575px) {
  .pricing-table-1:after {
    top: -270px;
  }
}

.pricing-table-1.circle-yellow:after {
  background-color: #faf1de;
}

.pricing-table-1.circle-pink:after {
  background-color: #f3e9f5;
}

.pricing-table-1 .p-table-title {
  position: relative;
}

.pricing-table-1 .p-table-title .wt-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #1967d2;
  display: inline-block;
}

.pricing-table-1 .p-table-inner {
  position: relative;
}

.pricing-table-1 .p-table-inner .p-table-list ul {
  list-style: none;
  margin-bottom: 40px;
}

.pricing-table-1 .p-table-inner .p-table-list ul li {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 46px;
}

.pricing-table-1 .p-table-inner .p-table-list ul li i {
  color: #1967d2;
  position: absolute;
  left: 15px;
  top: 4px;
  font-size: 20px;
}

.pricing-table-1 .p-table-inner .p-table-list ul li.disable {
  color: #aaaaaa;
}

.pricing-table-1 .p-table-inner .p-table-list ul li.disable i {
  color: #aaaaaa;
}

.pricing-table-1 .p-table-inner .p-table-btn {
  text-align: center;
}

.pricing-table-1 .p-table-inner .p-table-price {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  color: #17171d;
}

@media (max-width: 767px) {
  .pricing-table-1 .p-table-inner .p-table-price {
    margin-bottom: 60px;
  }
}

.pricing-table-1 .p-table-inner .p-table-price span {
  font-family: "Rubik", sans-serif;
  font-size: 38px;
  line-height: 56px;
  font-weight: 600;
  display: block;
  padding-right: 15px;
}

@media (max-width: 991px) {
  .pricing-table-1 .p-table-inner .p-table-price span {
    font-size: 36px;
    line-height: 26px;
  }
}

.pricing-table-1 .p-table-inner .p-table-price p {
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

.pricing-table-1 .p-table-recommended {
  background-color: #2db346;
  color: #fff;
  border-radius: 5px;
  padding: 2px 16px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
}

/*inner page banner*/
.wt-bnr-inr {
  height: 170px;
  background-size: cover;
  background-position: center center;
  display: table;
  width: 100%;
  position: relative;
}

.wt-bnr-inr .container {
  display: table;
  height: 100%;
}

.wt-bnr-inr-entry {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  /* padding-bottom: 30px; */
}

.wt-bnr-inr-entry .banner-title-outer {
  position: relative;
}

.wt-bnr-inr-entry .banner-title-outer .wt-title {
  font-size: 28px;
  margin-bottom: 0px;
  position: relative;
}

.wt-bnr-inr-entry .banner-title-outer .banner-title-name {
  display: inline-block;
  margin-bottom: 30px;
}

.wt-bnr-inr-entry .banner-title-outer .banner-title-name .wt-title {
  color: #17171d;
}

/*Breadcrumb*/
.wt-breadcrumb {
  margin: 0;
  display: inline-block;
  list-style: none;
  position: relative;
}

.wt-breadcrumb li {
  padding-right: 30px;
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #1967d2;
}

.wt-breadcrumb li a {
  color: #17171d;
}

.wt-breadcrumb li a:hover {
  color: #1967d2;
}

.wt-breadcrumb li:after {
  content: "";
  position: absolute;
  right: 8px;
  width: 8px;
  height: 2px;
  background-color: #17171d;
  top: 50%;
  transform: translateY(-50%);
  color: #17171d;
  font-size: 12px;
  font-weight: 600;
}

.wt-breadcrumb li:last-child {
  color: #1967d2;
  padding-right: 0px;
}

.wt-breadcrumb li:last-child:after {
  display: none;
}

.wt-breadcrumb li:first-child a i {
  font-size: 18px;
  vertical-align: text-top;
}

/*  breadcrumb  */
.breadcrumbimg {
  background-image: url("../src/assets/images/banner/1.jpg");
}

.aboutus {
  background-image: url("../src/assets/images/background/bg-1.jpg");
  background-size: cover;
}

/*Contact Form*/
.twm-contact-one {
  position: relative;
}

.twm-contact-one .contact-one-inner {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}

@media (max-width: 991px) {
  .twm-contact-one .contact-one-inner {
    padding-top: 38px;
    padding-bottom: 0px;
  }
}

.twm-contact-one .contact-one-inner:after {
  content: "";
  position: absolute;
  width: 420px;
  right: -15px;
  top: -40px;
  bottom: -40px;
  background-color: #f0f6fe;
  z-index: -1;
}

@media (max-width: 1200px) {
  .twm-contact-one .contact-one-inner:after {
    width: 394px;
  }
}

@media (max-width: 991px) {
  .twm-contact-one .contact-one-inner:after {
    display: none;
  }
}

.twm-contact-one .contact-info-wrap {
  margin-left: 100px;
  margin-right: 25px;
  position: relative;
  z-index: 1;
}

@media (max-width: 1200px) {
  .twm-contact-one .contact-info-wrap {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .twm-contact-one .contact-info-wrap {
    margin: 0px;
    padding-top: 38px;
    padding-bottom: 40px;
  }
}

.twm-contact-one .contact-info-wrap .contact-info {
  border-radius: 10px 10px 10px 0px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  padding: 20px 15px 20px 0px;
  background-color: #fff;
}

@media (max-width: 991px) {
  .twm-contact-one .contact-info-wrap .contact-info {
    border-radius: 10px;
  }
}

@media (max-width: 400px) {
  .twm-contact-one .contact-info-wrap .contact-info {
    padding: 20px;
  }
}

.twm-contact-one .contact-info-wrap:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -57px;
  z-index: -1;
  width: 0;
  height: 0;
  border-left: 57px solid transparent;
  border-right: 0px solid transparent;
  border-top: 57px solid #d8d8d8;
}

@media (max-width: 991px) {
  .twm-contact-one .contact-info-wrap:before {
    display: none;
  }
}

.twm-contact-one .contact-info-section .c-info-column {
  padding: 0px 0px 0px 100px;
  color: #17171d;
  position: relative;
  min-height: 75px;
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .twm-contact-one .contact-info-section .c-info-column {
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .twm-contact-one .contact-info-section .c-info-column {
    padding: 0px 0px 0px 65px;
  }
}

.twm-contact-one .contact-info-section .c-info-column:last-child {
  margin-bottom: 0px;
}

.twm-contact-one .contact-info-section .c-info-column p {
  font-size: 13px;
}

.twm-contact-one .contact-info-section .c-info-column .c-info-icon {
  position: absolute;
  font-size: 22px;
  left: 0px;
  top: 0px;
  color: #1967d2;
  width: 72px;
  height: 72px;
  line-height: 72px;
  border-radius: 10px;
  text-align: center;
  background-color: #f0f6fe;
  z-index: 1;
}

.twm-contact-one .contact-info-section .c-info-column .c-info-icon.custome-size {
  font-size: 26px;
}

@media (max-width: 400px) {
  .twm-contact-one .contact-info-section .c-info-column .c-info-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.twm-contact-one .contact-info-section .c-info-column span {
  font-weight: 600;
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
}

.twm-contact-one .contact-info-section .c-info-column p {
  margin-bottom: 0px;
}

.twm-contact-one .contact-info-section .c-info-column p a {
  color: #17171d;
}

.twm-contact-one .section-head-small {
  font-size: 20px;
  font-weight: 500;
}

.twm-contact-one .section-head-small.white .wt-title {
  color: #fff;
}

.twm-contact-one .contact-form-outer .section-head.left.wt-small-separator-outer {
  text-align: left;
}

.twm-contact-one .contact-form-outer .section-head.left.wt-small-separator-outer h2 {
  margin-bottom: 15px;
  font-size: 28px;
}

.cons-contact-form .form-control {
  height: 56px;
  padding: 10px 20px;
  background-color: #d8e2f0e0;
  border: 0px;
  border-radius: 10px;
}

.cons-contact-form .form-control:focus {
  box-shadow: none;
  background-color: #f9fcff;
}

.cons-contact-form textarea.form-control {
  padding-top: 15px;
  height: 80px;
  margin-bottom: 40px;
  resize: none;
}

.form-control {
  width: 100%;
  height: 50px;
  padding: 10px 15px;
  border: none;
  background-color: whitesmoke;
  border-radius: 7px;
}

.form-control:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
  background-color: #f8f8f8;
}

.form-group {
  padding: 3px 8px;
}

/*Pagination Style*/
.pagination-outer {
  padding-top: 30px;
}

@media (max-width: 991px) {
  .pagination-outer {
    margin-bottom: 30px;
  }
}

.pagination-outer .pagination-style1 {
  position: relative;
  display: inline-block;
}

.pagination-outer .pagination-style1 ul {
  list-style: none;
  margin-bottom: 0px;
}

.pagination-outer .pagination-style1 ul li {
  position: relative;
  margin: 0px 3px 0px;
  display: inline-block;
}

@media (max-width: 400px) {
  .pagination-outer .pagination-style1 ul li {
    margin: 0px 1px 0px;
  }
}

.pagination-outer .pagination-style1 ul li.prev a {
  width: 50px;
  height: 50px;
  font-size: 16px;
  color: #17171d;
  font-weight: 500;
  transition: 0.5s all ease;
  letter-spacing: 1px;
}

@media (max-width: 420px) {
  .pagination-outer .pagination-style1 ul li.prev a {
    width: 30px;
    height: 30px;
  }
}

.pagination-outer .pagination-style1 ul li.next a {
  width: 50px;
  height: 50px;
  font-size: 16px;
  color: #17171d;
  font-weight: 500;
  transition: 0.5s all ease;
  letter-spacing: 1px;
}

@media (max-width: 420px) {
  .pagination-outer .pagination-style1 ul li.next a {
    width: 30px;
    height: 30px;
  }
}

.pagination-outer .pagination-style1 ul li a {
  position: relative;
  width: 50px;
  height: 50px;
  color: #17171d;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  transition: 0.5s all ease;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border-radius: 10px;
}

@media (max-width: 480px) {
  .pagination-outer .pagination-style1 ul li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.pagination-outer .pagination-style1 ul li.active a {
  background-color: #1967d2;
  color: #fff;
}

.pagination-outer .pagination-style1 ul li:hover a {
  background-color: #1967d2;
  color: #fff;
}

/*Blog Side Bar*/
.side-bar {
  background-color: #fcfcfc;
  padding: 30px 0px;
  border-radius: 10px;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: scroll;
  left: 0;
}

@media (max-width: 991px) {
  .side-bar {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .side-bar {
    padding: 15px;
  }
}

.side-bar .widget:last-child {
  margin-bottom: 0px;
}

.side-bar .widget .widget-title {
  position: relative;
  padding-bottom: 15px;
  text-align: center;
}

.side-bar .widget .widget-title:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1967d2;
  width: 20px;
  height: 2px;
}

.side-bar .search-bx .input-group .form-control {
  height: 60px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  padding: 0px 25px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.side-bar .sidebar_icon {
  align-items: center;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .side-bar .search-bx .input-group .form-control {
    height: 60px;
  }
}

.side-bar .search-bx .input-group .btn {
  color: #17171d;
  border-radius: 0px;
  height: 60px;
  padding: 0px 15px;
  font-size: 18px;
  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.side-bar .search-bx .input-group .btn:focus {
  box-shadow: none;
}

.side-bar .widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  background-color: #fff;
}

.side-bar .widget_services ul li {
  position: relative;
  padding: 10px 0px 10px 15px;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.side-bar .widget_services ul li:after {
  content: "";
  width: 4px;
  height: 5px;
  border-radius: 50%;
  background-color: #1967d2;
  position: absolute;
  left: 0px;
  top: 17px;
}

.side-bar .widget_services ul li:last-child {
  padding-bottom: 0px;
}

.side-bar .widget_services ul li a {
  color: #17171d;
  margin-left: 0px;
  padding: 0px;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.side-bar .widget_services ul li a:hover {
  color: #1967d2;
}

.side-bar .widget_services ul li a i {
  padding-right: 5px;
}

.side-bar .widget_services ul li a:before {
  display: none;
}

.side-bar .widget_services ul li .badge {
  background: none;
  font-size: 14px;
  font-weight: 600;
}

.side-bar .recent-posts-entry .widget-post {
  margin-bottom: 20px;
}

.side-bar .recent-posts-entry .widget-post:last-child {
  border: none;
  margin-bottom: 0px;
}

.side-bar .recent-posts-entry .wt-post-media {
  width: 72px;
  float: left;
}

.side-bar .recent-posts-entry .wt-post-media img {
  border-radius: 10px;
}

.side-bar .recent-posts-entry .wt-post-info {
  margin-left: 92px;
}

@media (max-width: 420px) {
  .side-bar .recent-posts-entry .wt-post-info {
    margin-left: 95px;
  }
}

.side-bar .recent-posts-entry .wt-post-info .post-date {
  margin-top: 0px;
  color: #1967d2;
  font-size: 14px;
  display: block;
}

.side-bar .recent-posts-entry .wt-post-info .post-title {
  margin-top: 0px;
  font-size: 14px;
  display: block;
}

/*Side bar tags*/
.tw-sidebar-tags-wrap .tagcloud {
  margin-top: -10px;
}

.tw-sidebar-tags-wrap .tagcloud a {
  padding: 5px 15px;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #1967d2;
  border-radius: 10px;
  font-size: 14px;
  background-color: #f0f6fe;
}

.tw-sidebar-tags-wrap .tagcloud a:hover {
  background-color: #1967d2;
  color: #fff;
}

/*Comment list section*/
ol.comment-list {
  list-style: none;
}

ol.comment-list li.comment {
  position: relative;
  padding: 0;
}

ol.comment-list li.comment .comment-body {
  padding: 0px 90px 20px 120px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  min-height: 130px;
}

@media (max-width: 480px) {
  ol.comment-list li.comment .comment-body {
    padding: 0px 0px 50px 0px;
  }
}

ol.comment-list li.comment .comment-author {
  display: inline-block;
  margin-bottom: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
}

@media (max-width: 480px) {
  ol.comment-list li.comment .comment-author {
    position: inherit;
    margin-bottom: 20px;
  }
}

ol.comment-list li.comment .comment-author .avatar {
  width: 90px;
  height: 90px;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
}

ol.comment-list li.comment .fn {
  display: inline-block;
  color: #17171d;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 10px;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 480px) {
  ol.comment-list li.comment .fn {
    display: block;
  }
}

ol.comment-list li.comment .comment-meta {
  margin-bottom: 5px;
  display: block;
  text-align: center;
}

ol.comment-list li.comment .comment-meta a {
  color: #818181;
  font-size: 12px;
  text-align: center;
}

ol.comment-list li.comment .reply {
  position: absolute;
  right: 0px;
  top: 0px;
}

@media (max-width: 480px) {
  ol.comment-list li.comment .reply {
    top: auto;
    right: auto;
    bottom: 10px;
    left: 0px;
  }
}

ol.comment-list li.comment .reply .comment-reply-link {
  position: relative;
  color: #1967d2;
  padding: 4px 12px;
  font-size: 14px;
  display: inline-block;
}

ol.comment-list li.comment .reply .comment-reply-link:hover {
  color: #17171d;
}

@media (max-width: 480px) {
  ol.comment-list li.comment .reply .comment-reply-link {
    padding: 4px 0px;
  }
}

ol.comment-list li.comment p {
  margin: 0px;
  font-weight: 400;
}

ol.comment-list li .children {
  list-style: none;
  margin-left: 40px;
}

@media (max-width: 767px) {
  ol.comment-list li .children {
    margin-left: 0px;
  }
}

ol.comment-list li .children li {
  padding: 0;
}

ol.comment-list li .children li:last-child .comment-body {
  margin-bottom: 0px;
}

/*comment form*/
@media (max-width: 991px) {
  .comment-respond {
    margin-bottom: 40px;
  }
}

@media (max-width: 480px) {
  .comment-respond {
    padding: 0px;
  }
}

.comment-respond .comment-reply-title {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.comment-respond .form-submit .site-button {
  position: relative;
}

/*Comment area*/
.comments-area {
  padding: 0;
}

.comments-area .comments-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.comments-area .comment-form {
  padding: 30px;
  background-color: #fcfcfc;
}

@media (max-width: 480px) {
  .comments-area .comment-form {
    margin: 0 -5px;
  }
}

.comments-area .comment-form .comment-form-author label,
.comments-area .comment-form .comment-form-email label,
.comments-area .comment-form .comment-form-comment label {
  display: none;
  line-height: 18px;
  margin-bottom: 10px;
}

.comments-area .comment-form .comment-form-author input[type="text"],
.comments-area .comment-form .comment-form-email input[type="text"],
.comments-area .comment-form .comment-form-comment input[type="text"] {
  width: 100%;
  height: 60px;
  padding: 20px;
  border: 1px solid transparent;
  background-color: #f0f6fe;
  border-radius: 10px;
}

.comments-area .comment-form .comment-form-author input[type="text"].form-control:focus,
.comments-area .comment-form .comment-form-email input[type="text"].form-control:focus,
.comments-area .comment-form .comment-form-comment input[type="text"].form-control:focus {
  box-shadow: none;
}

.comments-area .comment-form .comment-form-comment textarea {
  width: 100%;
  padding: 20px;
  height: 120px;
  resize: none;
  border: 1px solid transparent;
  background-color: #f0f6fe;
  border-radius: 10px;
}

.comments-area .comment-form .comment-form-comment textarea.form-control:focus {
  box-shadow: none;
}

ol.comment-list li.comment .comment-respond .comment-form p {
  padding: 0px 15px !important;
}

.comment-form .form-control:-webkit-input-placeholder {
  color: #17171d;
  font-size: 14px;
}

/*Single Blog Post*/
.blog-post-single-outer .blog-post-single .wt-post-meta-list {
  margin-bottom: 5px;
  width: 100%;
  clear: both;
  display: inline-block;
}

.blog-post-single-outer .blog-post-single .wt-post-meta-list .wt-list-content {
  float: left;
  position: relative;
  color: #17171d;
  font-weight: 500;
}

.blog-post-single-outer .blog-post-single .wt-post-meta-list .wt-list-content.post-date {
  margin-right: 10px;
}

.blog-post-single-outer .blog-post-single .wt-post-meta-list .wt-list-content.post-author a {
  color: #1967d2;
  padding-left: 10px;
}

.blog-post-single-outer .blog-post-single .wt-post-info {
  padding-top: 0px;
}

.blog-post-single-outer .blog-post-single .post-title {
  font-size: 46px;
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .blog-post-single-outer .blog-post-single .post-title {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .blog-post-single-outer .blog-post-single .post-title {
    font-size: 22px;
  }
}

.blog-post-single-outer .blog-post-single .twm-blog-s-title {
  margin: 30px 0px;
}

.blog-post-single-outer .blog-post-single .wt-post-media img {
  width: 100%;
  border-radius: 10px;
}

.blog-post-single-outer .blog-post-single .wt-post-media .wt-post-meta {
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.blog-post-single-outer .blog-post-single .wt-post-media .wt-post-meta ul {
  background-color: #1967d2;
  display: inline-block;
  margin-bottom: 0px;
}

.blog-post-single-outer .blog-post-single .wt-post-media .wt-post-meta ul li {
  color: #fff;
  display: inline-table;
  font-size: 14px;
  text-align: center;
  padding: 8px 10px;
  font-weight: 500;
  position: relative;
}

.blog-post-single-outer .blog-post-single .wt-post-media .wt-post-meta ul li:after {
  position: absolute;
  content: "|";
  right: -2px;
  top: 50%;
  transform: translateY(-50%);
}

.blog-post-single-outer .blog-post-single .wt-post-media .wt-post-meta ul li a {
  color: #fff;
}

.blog-post-single-outer .blog-post-single .wt-post-media .wt-post-meta ul li:last-child:after {
  display: none;
}

.blog-post-single-outer .blog-post-single blockquote {
  background: #f8f8f8;
  margin: 30px 0px;
  padding: 50px 80px 30px 80px;
  position: relative;
  overflow: hidden;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 400px) {
  .blog-post-single-outer .blog-post-single blockquote {
    padding: 30px;
  }
}

.blog-post-single-outer .blog-post-single blockquote p {
  font-size: 16px;
  font-weight: 400;
  color: #17171d;
  margin-bottom: 20px;
  position: relative;
}

.blog-post-single-outer .blog-post-single blockquote p span {
  font-size: 48px;
  line-height: 40px;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  position: absolute;
  left: -20px;
  top: -20px;
}

.blog-post-single-outer .blog-post-single blockquote strong {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  color: #17171d;
}

.blog-post-single-outer .post-social-icons-wrap {
  text-align: center;
}

.blog-post-single-outer .post-social-icons-wrap h4 {
  font-size: 14px;
}

/*Single Post Tags*/
.post-area-tags-wrap {
  margin-top: 30px;
  padding-top: 30px;
}

/*Single Post Tags*/
.post-social-icons {
  margin: 0px;
}

.post-social-icons li {
  display: inline-block;
}

.post-social-icons li a {
  height: 34px;
  width: 34px;
  line-height: 34px;
  color: #818181;
  padding: 0px;
  font-size: 18px;
  text-align: center;
  margin-right: 3px;
}

.post-social-icons li a:hover {
  color: #fff;
  background-color: #051721;
}

/*Posts Author*/
.twm-posts-author {
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 10px;
  display: table;
  width: 100%;
}

@media (max-width: 400px) {
  .twm-posts-author {
    display: block;
  }
}

.twm-posts-author .twm-post-author-pic {
  width: 105px;
  height: 105px;
  display: table-cell;
  padding-right: 30px;
}

@media (max-width: 400px) {
  .twm-posts-author .twm-post-author-pic {
    display: block;
  }
}

.twm-posts-author .twm-post-author-pic img {
  border-radius: 10px;
  margin-right: 30px;
}

.twm-posts-author .twm-post-author-content {
  display: table-cell;
  vertical-align: top;
}

@media (max-width: 400px) {
  .twm-posts-author .twm-post-author-content {
    display: block;
  }
}

.twm-posts-author .twm-post-author-content span {
  display: block;
  color: #1967d2;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 10px;
}

.twm-posts-author .twm-post-author-content strong {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  color: #17171d;
}

/*Single Post Navigation*/
.post-navigation {
  margin-bottom: 36px;
}

.post-navigation .post-nav-links {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 30px 0px;
  border-top: 1px solid #dedede;
  position: relative;
}

.post-navigation .post-nav-links .post-nav-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 50%;
}

@media (max-width: 640px) {
  .post-navigation .post-nav-links .post-nav-item {
    display: block;
  }
}

.post-navigation .post-nav-links .post-nav-item .nav-post-arrow {
  margin-right: 20px;
}

@media (max-width: 640px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-arrow {
    margin-bottom: 10px;
  }
}

.post-navigation .post-nav-links .post-nav-item .nav-post-arrow i {
  font-size: 50px;
  line-height: 0.7;
  color: #1967d2;
}

.post-navigation .post-nav-links .post-nav-item .nav-post-meta {
  padding-right: 50px;
}

@media (max-width: 767px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-meta {
    padding-right: 15px;
  }
}

@media (max-width: 480px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-meta {
    padding-right: 10px;
  }
}

.post-navigation .post-nav-links .post-nav-item .nav-post-meta label {
  color: #616161;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 3px;
}

.post-navigation .post-nav-links .post-nav-item .nav-post-meta a {
  font-size: 16px;
  line-height: 24px;
  color: #17171d;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 640px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-meta a {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
  }
}

@media (max-width: 420px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-meta a {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    display: block;
  }
}

.post-navigation .post-nav-links .post-nav-item.nav-post-next {
  flex-direction: row-reverse;
  text-align: right;
}

.post-navigation .post-nav-links .post-nav-item.nav-post-next .nav-post-meta {
  padding-left: 50px;
  padding-right: 0px;
}

@media (max-width: 767px) {
  .post-navigation .post-nav-links .post-nav-item.nav-post-next .nav-post-meta {
    padding-left: 15px;
  }
}

@media (max-width: 480px) {
  .post-navigation .post-nav-links .post-nav-item.nav-post-next .nav-post-meta {
    padding-left: 10px;
  }
}

.post-navigation .post-nav-links .post-nav-item.nav-post-next .nav-post-arrow {
  margin-right: 0;
  margin-left: 20px;
}

@media (max-width: 991px) {

  .section-full.p-t120,
  .section-full.p-b90,
  .section-full.p-tb120 {
    padding-top: 38px;
    padding-bottom: 10px;
  }
}

/*Loading*/
.loading-area {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: hidden;
}

.loading-area .loading-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 9999;
}

.loading-area .loading-pic {
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: 99999;
  text-align: center;
  transform: translateY(-50%);
}

/*Loading Animation Start*/
.wrapper {
  margin: 0px auto;
  display: block;
}

.wrapper .cssload-loader {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 48px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-color: #1967d2;
  animation: 1.15s infinite linear;
}

.wrapper .cssload-loader:before,
.wrapper .cssload-loader:after {
  content: "";
  animation: 1.15s infinite linear;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper .cssload-loader:before {
  content: "";
  border: 7px solid #fff;
  top: 0px;
  left: 0px;
  animation-name: cssload-animation;
}

@keyframes cssload-animation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(2);
  }
}

/*Description list*/
.description-list {
  margin: 30px 0px;
}

.description-list li {
  padding-left: 45px;
  list-style: none;
  position: relative;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
}

.description-list li:last-child {
  margin-bottom: 0px;
}

.description-list li i {
  background-color: #1967d2;
  color: #fff;
  line-height: 26px;
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  font-size: 22px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 50%;
}

@media (max-width: 575px) {
  .description-list li {
    padding-left: 30px;
  }

  .description-list li i {
    font-size: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}

/*Description list*/
.description-list-2 {
  margin: 15px 0px;
}

.description-list-2 li {
  padding-left: 45px;
  list-style: none;
  position: relative;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
}

.description-list-2 li:last-child {
  margin-bottom: 0px;
}

.description-list-2 li i {
  color: #1967d2;
  line-height: 26px;
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  font-size: 22px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 50%;
}

/*Section Overlay*/
.overlay-wraper {
  position: relative;
}

.overlay-wraper .overlay-main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.overlay-wraper>.container {
  position: relative;
  z-index: 1;
}

.overlay-wraper>.container-fluid {
  position: relative;
  z-index: 1;
}

/*Services*/
.all_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.all_services ul li {
  position: relative;
  margin-bottom: 20px;
}

.all_services ul li:last-child {
  margin-bottom: 0px;
}

.all_services ul li a {
  transition: 0.5s all ease;
  position: relative;
  font-size: 16px;
  color: #666666;
}

.all_services ul li a:hover {
  color: #1967d2;
}

.all_services ul li .badge {
  float: right;
  background: none;
  color: #666666;
  font-weight: normal;
  font-size: 14px;
}

/*Search*/
/* 
#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  display: none;
}

#search.open {
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  z-index: 999;
  display: block;
}

#search form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  color: #1967d2;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 30px;
  font-weight: 300;
  text-align: left;
  outline: none;
  padding: 10px;
  display: flex;
  align-items: center;
}

#search form span {
  display: block;
}

#search input[type=search] {
  background: none;
  border: none;
  padding: 0px 12px;
  outline: none;
  color: #17171d;
  font-size: 24px;
  text-align: center;
}

@media (max-width: 575px) {
  #search input[type=search] {
    font-size: 14px;
  }
}

#search input[type=search]:focus {
  box-shadow: none;
}

#search input[type=search]::placeholder {
  color: #17171d;
}

#search .search-btn {
  border: none;
  background: none;
  padding: 0px 30px;
  outline: none;
  display: block;
  width: 100%;
  text-align: center;
}

#search .search-btn i {
  font-size: 36px;
  line-height: 60px;
  color: #1967d2;
}

#search .close {
  position: fixed;
  top: 30px;
  right: 30px;
  border-radius: 10px;
  opacity: 1;
  font-size: 27px;
  color: #fff;
  width: 60px;
  height: 60px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #1967d2;
}

*/
#search .close:after,
#search .close:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 32px;
  background-color: #fff;
  right: 28px;
  top: 16px;
}

#search .close:after {
  transform: rotate(45deg);
}

#search .close:before {
  transform: rotate(-45deg);
}

#search .close:hover:after,
#search .close:hover:before {
  cursor: pointer;
}

/*Google map*/
.google-map {
  width: 100%;
  height: 460px;
}

.google-map iframe {
  width: 100%;
  border: 0px;
  filter: grayscale(100%);
}

.form-select {
  height: 50px;
  font-size: 13px;
  line-height: 20px;
  padding: 10px;
  border-radius: 0px;
  color: #6c757d;
}

.form-select option {
  padding-left: 0px;
}

.form-select:focus {
  box-shadow: none;
}

.form-select.large {
  height: 60px;
  font-size: 16px;
  line-height: 20px;
  padding: 20px;
  color: #a0a0a0;
  border-radius: 10px;
  border: none;
}

.form-select.large option {
  padding-left: 0px;
}

.form-select.large:focus {
  box-shadow: none;
}

/*Gallery*/
.twm-two-part-section {
  margin-bottom: 30px;
}

.tw-sidebar-gallery ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
}

.tw-sidebar-gallery ul li {
  width: 25%;
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
}

.tw-sidebar-gallery ul li .tw-service-gallery-thumb {
  position: relative;
  z-index: 1;
  transition: 0.5s all ease;
}

.tw-sidebar-gallery ul li .tw-service-gallery-thumb:after {
  position: absolute;
  left: -3px;
  top: -3px;
  right: -3px;
  bottom: -3px;
  content: "";
  border: 2px solid #1967d2;
  border-radius: 10px;
  opacity: 0;
  z-index: -1;
}

.tw-sidebar-gallery ul li .tw-service-gallery-thumb:hover {
  padding: 0px;
}

.tw-sidebar-gallery ul li .tw-service-gallery-thumb:hover:after {
  opacity: 1;
}

.tw-sidebar-gallery ul li a {
  display: block;
  text-align: center;
  position: relative;
  background-color: #000;
  border-radius: 10px;
}

.tw-sidebar-gallery ul li a img {
  transition: 0.5s all ease;
  border-radius: 10px;
}

.tw-sidebar-gallery ul li a i {
  opacity: 0;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s all ease;
}

.tw-sidebar-gallery ul li:hover a img {
  opacity: 0.3;
}

.tw-sidebar-gallery ul li:hover a i {
  opacity: 1;
}

.tw-sidebar-gallery-2 .row {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.tw-sidebar-gallery-2 .row [class*="col-"] {
  margin-bottom: 30px;
}

.tw-sidebar-gallery-2 .row [class*="col-"] .tw-service-gallery-thumb {
  position: relative;
  z-index: 1;
  transition: 0.5s all ease;
}

.tw-sidebar-gallery-2 .row [class*="col-"] .tw-service-gallery-thumb:after {
  position: absolute;
  left: -3px;
  top: -3px;
  right: -3px;
  bottom: -3px;
  content: "";
  border: 2px solid #1967d2;
  border-radius: 10px;
  opacity: 0;
  z-index: -1;
}

.tw-sidebar-gallery-2 .row [class*="col-"] .tw-service-gallery-thumb:hover {
  padding: 0px;
}

.tw-sidebar-gallery-2 .row [class*="col-"] .tw-service-gallery-thumb:hover:after {
  opacity: 1;
}

.tw-sidebar-gallery-2 .row [class*="col-"] a {
  display: block;
  text-align: center;
  position: relative;
  background-color: #000;
  border-radius: 10px;
}

.tw-sidebar-gallery-2 .row [class*="col-"] a img {
  transition: 0.5s all ease;
  border-radius: 10px;
}

.tw-sidebar-gallery-2 .row [class*="col-"] a i {
  opacity: 0;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s all ease;
}

.tw-sidebar-gallery-2 .row [class*="col-"]:hover a img {
  opacity: 0.3;
}

.tw-sidebar-gallery-2 .row [class*="col-"]:hover a i {
  opacity: 1;
}

/*Video*/
.video-section-first {
  height: 270px;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 1199px) {
  .video-section-first {
    height: 236px;
  }
}

@media (max-width: 991px) {
  .video-section-first {
    height: 266px;
  }
}

.play-now-video .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 78px;
  width: 78px;
  text-align: center;
  line-height: 78px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  z-index: 1;
  font-size: 32px;
  padding-left: 0px;
  display: block;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: center center;
  border-radius: 50%;
  box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
}

.play-now-video .ripple,
.play-now-video .ripple:before,
.play-now-video .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 78px;
  width: 78px;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: center center;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
  animation: ripple-video 3s infinite;
  z-index: 1;
}

.play-now-video .ripple:before {
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.play-now-video .ripple:after {
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@keyframes ripple-video {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/*Slide Top Animation*/
.slide-top {
  animation: slide-top 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite alternate-reverse both;
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-50px);
  }
}

/*Zoom in Zoom Out Animation*/
.zoon-in-out {
  animation: zoom-in-zoom-out 8s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.1, 1.1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1.1, 1.1);
  }
}

/*Up Down Animation*/
.up-down {
  animation: slide-top 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

/*==================================================
	Start Work
==================================================*/
/*Working Process*/
.bg-clr-sky {
  background-color: #3898e2;
}

.bg-clr-pink {
  background-color: #bc84ca;
}

.bg-clr-green {
  background-color: #56d8b1;
}

.bg-clr-sky-light {
  background-color: #7cb5ea;
}

.bg-clr-pink-light {
  background-color: #cea7d9;
}

.bg-clr-green-light {
  background-color: #8be3c6;
}

.bg-clr-yellow-light {
  background-color: #eac87c;
}

.bg-sky-light-shadow {
  box-shadow: 0px 0px 40px rgba(124, 181, 234, 0.7);
}

.bg-pink-light-shadow {
  box-shadow: 0px 0px 40px rgba(206, 167, 217, 0.7);
}

.bg-green-light-shadow {
  box-shadow: 0px 0px 40px rgba(139, 227, 198, 0.7);
}

.bg-yellow-light-shadow {
  box-shadow: 0px 0px 40px rgba(234, 200, 124, 0.7);
}

.text-clr-sky {
  color: #3898e2;
}

.text-clr-pink {
  color: #bc84ca;
}

.text-clr-green {
  color: #56d8b1;
}

.text-clr-green2 {
  color: #05ad05;
}

.text-clr-yellow {
  color: #e2b438;
}

.text-clr-yellow-2 {
  color: #ffe119;
}

.text-clr-red {
  color: #ff0000;
}

/*process steps*/
.twm-how-it-work-section .row {
  display: flex;
  justify-content: center;
}

.twm-w-process-steps {
  padding-left: 80px;
  padding-top: 60px;
  margin-bottom: 30px;
  position: relative;
}

@media (max-width: 420px) {
  .twm-w-process-steps {
    padding-left: 0px;
    padding-top: 40px;
  }
}

.twm-w-process-steps .twm-large-number {
  color: #efefef;
  font-size: 75px;
  font-weight: 800;
  line-height: 0px;
  position: absolute;
  left: 0px;
  top: 30px;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 420px) {
  .twm-w-process-steps .twm-large-number {
    position: inherit;
    top: auto;
    left: auto;
  }
}

.twm-w-process-steps .twm-w-pro-top {
  margin-left: 20px;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.twm-w-process-steps .twm-w-pro-top .twm-media {
  position: absolute;
  left: -20px;
  top: 10px;
  width: 90px;
  height: 95px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: -5px 10px 25px rgba(56, 152, 226, 0.4);
}

.twm-w-process-steps .twm-w-pro-top .twm-title {
  color: #fff;
  padding-left: 80px;
  margin-bottom: 0px;
}

@media (max-width: 991px) {
  .twm-w-process-steps-2-wrap {
    margin-top: 30px;
  }
}

.twm-w-process-steps-2-wrap .row [class*="col-"]:nth-child(odd) {
  margin-top: -30px;
}

.twm-w-process-steps-2 {
  padding-left: 80px;
  padding-top: 30px;
  position: relative;
}

@media (max-width: 1024px) {
  .twm-w-process-steps-2 {
    padding-left: 50px;
  }
}

@media (max-width: 991px) {
  .twm-w-process-steps-2 {
    padding-left: 30px;
  }
}

.twm-w-process-steps-2 .twm-large-number {
  font-size: 60px;
  font-weight: 600;
  line-height: 50px;
  position: absolute;
  right: 15px;
  top: 15px;
  font-family: "Poppins", sans-serif;
}

.twm-w-process-steps-2 .twm-w-pro-top {
  margin-left: 20px;
  margin-bottom: 40px;
  padding: 25px;
  border-radius: 10px;
  position: relative;
}

.twm-w-process-steps-2 .twm-w-pro-top .twm-media {
  position: absolute;
  left: -50px;
  top: 10px;
  width: 90px;
  height: 95px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: -5px 10px 25px rgba(56, 152, 226, 0.4);
}

.twm-w-process-steps-2 .twm-w-pro-top .twm-title {
  color: #17171d;
  margin-bottom: 30px;
  margin-top: 40px;
  margin-left: 30px;
}

.twm-w-process-steps-2 p {
  margin-bottom: 0px;
  font-size: 14px;
}

/*how it work*/
.twm-how-it-work-area2 .container>.row {
  display: flex;
  align-items: center;
}

/*testimonial style 1*/
.twm-testimonial-1-carousel {
  margin-bottom: 30px;
}

.twm-testimonial-1 {
  position: relative;
}

.twm-testimonial-1 .twm-testimonial-1-content {
  border-radius: 10px;
  display: table;
  width: 100%;
  position: relative;
  z-index: 1;
}

.twm-testimonial-1 .twm-testimonial-1-content:after {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: 100px;
  background-color: #f9fcff;
  content: "";
  z-index: -1;
  border-radius: 10px;
}

@media (max-width: 575px) {
  .twm-testimonial-1 .twm-testimonial-1-content:after {
    left: 0px;
  }
}

.twm-testimonial-1 .twm-testimonial-1-content .twm-testi-media {
  width: 235px;
  max-width: 235px;
  display: table-cell;
  vertical-align: bottom;
}

@media (max-width: 1199px) {
  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-media {
    width: 170px;
  }
}

.twm-testimonial-1 .twm-testimonial-1-content .twm-testi-media img {
  width: auto;
}

.twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content {
  display: table-cell;
  padding: 50px 30px 50px 30px;
}

@media (max-width: 575px) {
  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content {
    padding: 0px;
  }
}

.twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content .twm-quote {
  margin-bottom: 50px;
}

.twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content .twm-quote img {
  width: auto;
}

@media (max-width: 575px) {
  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content .twm-quote {
    margin-bottom: 0px;
  }

  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content .twm-quote img {
    width: 30px;
  }
}

.twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content .twm-testi-info {
  font-size: 18px;
  font-style: italic;
  color: #17171d;
  margin-bottom: 40px;
}

.twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content .twm-testi-detail .twm-testi-name {
  color: #1967d2;
  font-size: 18px;
  font-weight: 500;
}

.twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content .twm-testi-detail .twm-testi-position {
  color: #17171d;
  font-size: 14px;
  position: relative;
  display: inline-block;
}

.twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content .twm-testi-detail .twm-testi-position:after {
  content: "";
  width: 20px;
  height: 1px;
  background-color: #1967d2;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 575px) {
  .twm-testimonial-1 .twm-testimonial-1-content {
    display: block;
    text-align: center;
    padding: 30px;
  }

  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content,
  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-media {
    display: block;
  }

  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-media {
    margin: 0px auto;
  }
}

/*testimonial style 1*/
.twm-testimonial-2-carousel {
  margin-bottom: 30px;
}

.twm-testimonial-2 {
  position: relative;
  padding: 0px 15px 15px;
}

.twm-testimonial-2 .twm-testimonial-2-content {
  border-radius: 10px;
}

.twm-testimonial-2 .twm-testimonial-2-content .twm-testi-media img {
  width: auto;
  height: 220px;
}

.twm-testimonial-2 .twm-testimonial-2-content .twm-testi-content {
  padding: 50px 30px 30px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(56, 152, 226, 0.3);
  position: relative;
}

.twm-testimonial-2 .twm-testimonial-2-content .twm-testi-content .twm-quote {
  position: absolute;
  right: 30px;
  top: -45px;
  opacity: 0.3;
}

.twm-testimonial-2 .twm-testimonial-2-content .twm-testi-content .twm-quote img {
  width: auto;
}

.twm-testimonial-2 .twm-testimonial-2-content .twm-testi-content .twm-testi-info {
  font-size: 18px;
  color: #17171d;
  margin-bottom: 20px;
}

.twm-testimonial-2 .twm-testimonial-2-content .twm-testi-content .twm-testi-detail .twm-testi-name {
  color: #1967d2;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.twm-testimonial-2 .twm-testimonial-2-content .twm-testi-content .twm-testi-detail .twm-testi-position {
  color: #fff;
  background-color: #1967d2;
  font-size: 14px;
  position: relative;
  display: inline-block;
  border-radius: 0px 8px 8px 0px;
  padding: 8px 40px 8px 30px;
  margin-left: -30px;
}

.twm-testimonial-2 .twm-testimonial-2-content .twm-testi-content .twm-testi-detail .twm-testi-position:after {
  content: "";
  width: 20px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

/*Blog post 1*/
.twm-blog-post-wrap-right {
  margin-left: 30px;
}

@media (max-width: 991px) {
  .twm-blog-post-wrap-right {
    margin-left: 0px;
  }
}

.twm-blog-post-wrap-right .twm-blog-post-1-outer {
  margin-top: 0px;
}

.twm-blog-post-1-outer-wrap {
  padding-top: 20px;
}

.twm-blog-post-1-outer-wrap .owl-carousel .owl-stage-outer {
  padding: 0px;
}

.twm-blog-post-1-outer-wrap .owl-carousel .twm-blog-post-1-outer {
  margin-left: 10px;
  box-shadow: none;
  border: 1px solid #f7f7f7;
}

.twm-blog-post-1-outer {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 25px rgba(180, 205, 237, 0.7);
  margin-bottom: 30px;
  margin-top: 20px;
}

.twm-blog-post-1-outer.shadow-none {
  box-shadow: none;
}

.twm-blog-post-1-outer .wt-post-media {
  margin-top: -40px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 10px 25px rgba(56, 152, 226, 0.3);
  margin-bottom: 50px;
}

.twm-blog-post-1-outer .wt-post-media img {
  width: 100%;
}

.twm-blog-post-1-outer .wt-post-info {
  position: relative;
  z-index: 1;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-meta {
  position: relative;
  margin-bottom: 20px;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-meta ul {
  list-style: none;
  margin-bottom: 0px;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-meta ul li {
  position: relative;
  z-index: 1;
  display: inline-block;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-meta ul li.post-date {
  background-color: #7a98bf;
  color: #fff;
  font-size: 14px;
  line-height: 28px;
  padding: 5px 15px 5px 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-meta ul li.post-date:after {
  content: "";
  background-color: #7a98bf;
  position: absolute;
  top: 0px;
  left: -30px;
  bottom: 0px;
  width: 30px;
  z-index: -1;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-meta ul li.post-date:before {
  content: "";
  position: absolute;
  left: -29px;
  bottom: -8px;
  z-index: -1;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 0px solid transparent;
  border-top: 8px solid #6881a2;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-meta ul li.post-author {
  color: #17171d;
  font-weight: 600;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-meta ul li.post-author a {
  color: #1967d2;
  display: inline-block;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-title .post-title {
  margin-bottom: 15px;
}

.twm-blog-post-1-outer .wt-post-info .wt-post-text p {
  font-size: 16px;
  margin-bottom: 10px;
}

/*Blog post 2*/
.twm-blog-post-2-outer {
  position: relative;
}

.twm-blog-post-2-outer .wt-post-media {
  border-radius: 10px;
  overflow: hidden;
}

.twm-blog-post-2-outer .wt-post-media img {
  width: 100%;
}

.twm-blog-post-2-outer .wt-post-info {
  background-color: #1967d2;
  border-radius: 10px;
  padding: 25px 30px;
  margin: -50px 10px 0px 10px;
  position: relative;
}

.twm-blog-post-2-outer .wt-post-info .wt-post-meta {
  position: relative;
  margin-bottom: 20px;
}

.twm-blog-post-2-outer .wt-post-info .wt-post-meta ul {
  list-style: none;
  margin-bottom: 0px;
}

.twm-blog-post-2-outer .wt-post-info .wt-post-meta ul li {
  position: relative;
  z-index: 1;
  display: inline-block;
}

.twm-blog-post-2-outer .wt-post-info .wt-post-meta ul li.post-date {
  background-color: #171d28;
  color: #fff;
  font-size: 14px;
  line-height: 28px;
  padding: 5px 30px;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  top: -48px;
  left: 0px;
}

.twm-blog-post-2-outer .wt-post-info .wt-post-title .post-title {
  margin-bottom: 15px;
}

.twm-blog-post-2-outer .wt-post-info .wt-post-title .post-title a {
  color: #fff;
}

.twm-blog-post-2-outer .wt-post-info .site-button-link.site-text-secondry {
  color: #f9fcff;
}

/*BLog Post List Style*/
.twm-blog-list-style {
  display: flex;
  position: relative;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .twm-blog-list-style {
    display: block;
  }
}

.twm-blog-list-style .wt-post-media {
  margin-bottom: 0px;
  margin-right: 30px;
  max-width: 320px;
}

@media (max-width: 767px) {
  .twm-blog-list-style .wt-post-media {
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

.twm-blog-list-style .wt-post-info {
  position: static;
}

.twm-blog-list-style .wt-post-info .wt-post-meta {
  position: inherit;
}

.twm-blog-list-style .wt-post-info .wt-post-meta ul li.post-date {
  position: absolute;
  left: 20px;
  top: 20px;
}

/*Job post section*/
.twm-jobs-list-wrap {
  max-width: 910px;
  margin: 0px auto;
}

.twm-jobs-list-wrap ul li {
  list-style: none;
}

.twm-jobs-list-style1 {
  background-color: #fff;
  border: 10px;
  border: 1px solid #efefef;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 767px) {
  .twm-jobs-list-style1 {
    text-align: center;
    display: block;
    margin-bottom: 30px !important;
  }
}

.twm-jobs-list-style1 .twm-media {
  width: 150px;
  height: 150px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3); */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),
    0 8px 16px rgba(0, 0, 0, 0.11);
  margin-right: 40px;
  position: absolute;
  bottom: -20px;
  line-height: 150px;
  overflow: hidden;
}

.twm-explore-media-wrap .twm-media img {
  filter: drop-shadow(2px 4px 6px black);
}

@media (max-width: 767px) {
  .twm-jobs-list-style1 .twm-media {
    position: inherit;
    bottom: inherit;
    margin-right: 0px;
    margin-bottom: 30px;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

.twm-jobs-list-style1 .twm-media img {
  display: inline-block;
}

.twm-jobs-list-style1 p {
  font-size: 16px;
}

.twm-jobs-list-style1 .twm-mid-content {
  padding-left: 180px;
}

.twm-mid-content {
  padding-top: 4px;
}

@media (max-width: 767px) {
  .twm-mid-content {
    padding-top: 8px;
  }

  .twm-jobs-list-style1 .twm-mid-content {
    padding-left: 0px;
  }
}

.twm-jobs-list-style1 .twm-right-content {
  text-align: right;
  width: 178px;
}

@media (max-width: 767px) {
  .twm-jobs-list-style1 .twm-right-content {
    text-align: inherit;
    width: auto;
  }
}

.twm-jobs-list-style1 .twm-right-content .twm-job-address {
  margin-bottom: 0px;
}

.twm-jobs-list-style1 .twm-right-content .twm-jobs-amount {
  font-weight: 500;
  color: #17171d;
  margin: 20px 15px 10px 0px;
  font-size: 14px;
}

.twm-jobs-list-style1 .twm-right-content .twm-jobs-amount span {
  color: darkcyan;
}

.twm-jobs-list-style1 .twm-right-content .twm-job-websites {
  font-size: 14px;
}

.twm-jobs-list-style1 .twm-right-content .twm-jobs-browse {
  font-weight: 600;
  font-size: 15px;
}

.twm-jobs-list-style1 .twm-job-title .twm-job-post-duration {
  color: darkcyan;
  font-size: 14px;
}

.twm-jobs-category span {
  color: #fff;
  border-radius: 5px;
  padding: 5px 12px;
  margin-top: 10px;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .twm-jobs-category span {
    display: inline-block;
  }
}

.cabdidate-de-info .twm-jobs-category span {
  color: #fff;
  border-radius: 5px;
  padding: 5px 12px;
}

@media (max-width: 767px) {
  .cabdidate-de-info .twm-jobs-category span {
    margin-top: 0px;
    display: inline-block;
  }
}

.twm-jobs-grid-style1 {
  background-color: #fff;
  border: 10px;
  /* box-shadow: 0px 0px 15px rgba(56, 152, 226, 0.3); */
  box-shadow: 1px 4px 8px rgba(1, 0, 0, 0.5);
  position: relative;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin-top: 30px;
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 {
    text-align: center;
    margin-top: 0px;
  }
}

.twm-jobs-grid-style1 .twm-media {
  width: 90px;
  height: 90px;
  line-height: 90px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3); */
  box-shadow: 0px 1px 5px rgba(1, 0, 0, 0.5);
  margin-right: 30px;
  position: absolute;
  top: -30px;
  overflow: hidden;
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-media {
    position: inherit;
    top: inherit;
    margin: 0px auto;
  }
}

.twm-jobs-grid-style1 .twm-media img {
  display: block;
}

.twm-jobs-grid-style1 .twm-mid-content {
  padding-top: 60px;
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-mid-content {
    padding-top: 30px;
  }
}

.twm-jobs-grid-style1 .twm-right-content {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-right-content {
    display: block;
  }
}

.twm-jobs-grid-style1 .twm-right-content .twm-job-address {
  margin-bottom: 0px;
}

.twm-jobs-grid-style1 .twm-right-content .twm-jobs-amount {
  font-weight: 500;
  color: #17171d;
  margin: 20px 0px 10px 0px;
}

.twm-jobs-grid-style1 .twm-right-content .twm-jobs-amount span {
  color: #046c38;
}

.twm-jobs-grid-style1 .twm-right-content .twm-jobs-browse {
  font-weight: 600;
  font-size: 14px;
}

.twm-jobs-grid-style1 .twm-job-post-duration {
  color: #2db346;
  font-size: 14px;
  margin-left: 120px;
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-job-post-duration {
    display: block;
    margin: 0px;
  }
}

.twm-jobs-grid-style1 .twm-jobs-category {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-jobs-category {
    position: inherit;
    top: inherit;
    right: inherit;
    margin-top: 10px;
  }
}

/*Employer List*/
.twm-employer-list-wrap ul {
  margin: 0px;
}

.twm-employer-list-wrap ul li {
  list-style: none;
}

.twm-employer-list-style1 {
  background-color: #fff;
  border: 10px;
  border: 1px solid #efefef;
  position: relative;
  padding: 25px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 575px) {
  .twm-employer-list-style1 {
    display: block;
    text-align: center;
    margin-bottom: 30px !important;
  }
}

.twm-employer-list-style1 .twm-media {
  width: 150px;
  height: 150px;
  display: block;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
  margin-right: 40px;
  position: absolute;
  bottom: -20px;
  line-height: 150px;
  overflow: hidden;
}

@media (max-width: 575px) {
  .twm-employer-list-style1 .twm-media {
    position: inherit;
    bottom: inherit;
    margin: 0px 0px 30px 0px;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

.twm-employer-list-style1 .twm-media img {
  display: inline-block;
  border-radius: 10px;
}

.twm-employer-list-style1 p {
  font-size: 14px;
}

.twm-employer-list-style1 .twm-mid-content {
  padding-left: 180px;
}

@media (max-width: 575px) {
  .twm-employer-list-style1 .twm-mid-content {
    padding-left: 0px;
  }
}

.twm-employer-list-style1 .twm-right-content {
  text-align: center;
  width: 100px;
}

@media (max-width: 575px) {
  .twm-employer-list-style1 .twm-right-content {
    margin: 0px auto;
  }
}

.twm-employer-list-style1 .twm-right-content .twm-job-address {
  margin-bottom: 0px;
}

.twm-employer-list-style1 .twm-right-content .twm-jobs-vacancies {
  font-weight: 500;
  color: #17171d;
  margin: 20px 0px 10px 0px;
  font-size: 14px;
}

.twm-employer-list-style1 .twm-right-content .twm-jobs-vacancies span {
  color: #00aa18;
  font-size: 18px;
  font-weight: 600;
  display: block;
  text-align: center;
}

.twm-employer-list-style1 .twm-right-content .twm-job-websites {
  font-size: 14px;
}

/*Employer grid*/
.twm-employer-grid-style1 {
  background-color: #fff;
  border: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  position: relative;
  padding: 25px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.twm-employer-grid-style1 .twm-media {
  width: 90px;
  height: 90px;
  line-height: 90px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
  margin-right: 40px;
  position: absolute;
  top: -20px;
  overflow: hidden;
}

.twm-employer-grid-style1 .twm-media img {
  display: block;
  border-radius: 10px;
}

.twm-employer-grid-style1 p {
  font-size: 14px;
}

.twm-employer-grid-style1 .twm-mid-content {
  padding-top: 80px;
}

.twm-employer-grid-style1 .twm-right-content {
  text-align: center;
  width: 100px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.twm-employer-grid-style1 .twm-right-content .twm-job-address {
  margin-bottom: 0px;
}

.twm-employer-grid-style1 .twm-right-content .twm-jobs-vacancies {
  font-weight: 500;
  color: #17171d;
  margin: 20px 0px 10px 0px;
  font-size: 14px;
}

.twm-employer-grid-style1 .twm-right-content .twm-jobs-vacancies span {
  color: #00aa18;
  font-size: 18px;
  font-weight: 600;
  display: block;
  text-align: center;
}

.twm-employer-grid-style1 .twm-right-content .twm-job-websites {
  font-size: 14px;
}

.twm-bg-red {
  background-color: #ff0000;
}

.twm-bg-green {
  background-color: #2db346;
}

.twm-bg-brown {
  background-color: #b3692d;
}

.twm-bg-purple {
  background-color: #8883ec;
}

.twm-bg-sky {
  background-color: #2d9bb3;
}

.twm-bg-golden {
  background-color: #b7912a;
}

.twm-clr-1-red {
  color: #ff0000;
}

.twm-clr-1-green {
  color: #2db346;
}

.twm-clr-1-brown {
  color: #b3692d;
}

.twm-clr-1-purple {
  color: #8883ec;
}

.twm-clr-1-sky {
  color: #2d9bb3;
}

.twm-clr-1-golden {
  color: #b7912a;
}

.twm-bg-ring-wrap {
  overflow: hidden;
  position: relative;
}

.twm-bg-ring-wrap .twm-bg-ring-right,
.twm-bg-ring-wrap .twm-bg-ring-left {
  position: absolute;
  border-radius: 50%;
}

.twm-bg-ring-wrap .twm-bg-ring-left {
  width: 495px;
  height: 495px;
  border: 100px solid #56d8b1;
  left: -150px;
  bottom: -150px;
  opacity: 0.1;
}

.twm-bg-ring-wrap .twm-bg-ring-right {
  width: 700px;
  height: 700px;
  border: 130px solid #1967d2;
  right: -80px;
  top: 150px;
  opacity: 0.1;
}

.twm-bg-ring-wrap2 {
  overflow: hidden;
  position: relative;
}

.twm-bg-ring-wrap2 .twm-bg-ring-right,
.twm-bg-ring-wrap2 .twm-bg-ring-left {
  position: absolute;
  border-radius: 50%;
}

.twm-bg-ring-wrap2 .twm-bg-ring-left {
  width: 495px;
  height: 495px;
  border: 100px solid #56d8b1;
  left: -150px;
  bottom: -150px;
  opacity: 0.3;
}

.twm-bg-ring-wrap2 .twm-bg-ring-right {
  width: 700px;
  height: 700px;
  border: 130px solid #1967d2;
  right: -250px;
  top: -250px;
  opacity: 0.05;
}

/*Explore New Life*/
.twm-explore-area {
  overflow: hidden;
}

.twm-explore-content-outer {
  position: relative;
  z-index: 1;
}

.twm-explore-content-outer .twm-bold-circle-left {
  width: 140px;
  height: 140px;
  border: 30px solid #56d8b1;
  left: 50px;
  bottom: -110px;
  opacity: 0.4;
  border-radius: 50%;
  position: absolute;
}

.twm-explore-content-outer .twm-bold-circle-right {
  width: 250px;
  height: 250px;
  border: 50px solid #1967d2;
  right: -140px;
  top: -90px;
  opacity: 0.2;
  border-radius: 50%;
  position: absolute;
}

.who-we-are-content h2 {
  font-size: 25px;
  font-weight: 600;
}

.who-we-are-content span {
  color: #f69619;
}

@media (max-width: 991px) {
  .twm-explore-media-wrap .twm-media {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media (max-width: 575px) {
  .twm-explore-media-wrap .twm-media {
    margin-right: 0px;
  }

  .twm-explore-media-wrap .twm-media img {
    width: 100%;
  }
}

.twm-explore-content {
  background-color: #1967d2;
  border-radius: 10px;
  padding: 55px 70px;
  position: relative;
  overflow: hidden;
  margin-top: 70px;
  margin-left: 30px;
  z-index: 1;
}

@media (max-width: 991px) {
  .twm-explore-content {
    margin-bottom: 30px;
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .twm-explore-content {
    padding: 30px;
  }
}

.twm-explore-content .twm-l-line-1 {
  width: 475px;
  height: 475px;
  position: absolute;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  opacity: 0.1;
  left: -290px;
  top: -290px;
  z-index: -1;
}

.twm-explore-content .twm-l-line-2 {
  width: 475px;
  height: 475px;
  position: absolute;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  opacity: 0.1;
  left: -230px;
  top: -230px;
  z-index: -1;
}

.twm-explore-content .twm-r-circle-1 {
  width: 500px;
  height: 500px;
  position: absolute;
  background-color: #e7e7e7;
  border-radius: 50%;
  opacity: 0.05;
  right: -140px;
  top: -200px;
  z-index: -1;
}

.twm-explore-content .twm-r-circle-2 {
  width: 500px;
  height: 500px;
  position: absolute;
  background-color: #e7e7e7;
  border-radius: 50%;
  opacity: 0.05;
  right: -90px;
  top: -145px;
  z-index: -1;
}

.twm-explore-content .twm-title-small {
  margin-bottom: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;
}

.twm-explore-content .twm-title-large {
  margin-bottom: 40px;
}

.twm-explore-content .twm-title-large h2 {
  color: #fff;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .twm-explore-content .twm-title-large h2 {
    font-size: 30px;
  }
}

.twm-explore-content .twm-title-large p {
  color: #e7e7e7;
}

.twm-explore-content .twm-upload-file .site-button {
  color: rgba(0, 0, 0, 0) !important;
  transition: all 0.3s ease !important;
  text-shadow: 0 0 0 #1967d2, 400px 0 0 #1967d2;
  background-color: #fff;
}

.twm-explore-content .twm-upload-file .site-button:hover {
  text-shadow: -400px 0 0 #1967d2, 0 0 0 #1967d2;
}

.twm-explore-content .twm-upload-file .site-button:hover i {
  text-shadow: none;
}

.twm-explore-content .twm-upload-file .site-button i {
  color: #1967d2;
  padding-right: 0px;
  padding-left: 5px;
  font-size: 24px;
  text-shadow: none;
}

.twm-explore-content-2 {
  background-color: #1967d2;
  border-radius: 50px;
  padding: 55px 70px;
  position: relative;
  z-index: 1;
  margin-right: 100px;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .twm-explore-content-2 {
    padding: 30px;
  }
}

@media (max-width: 600px) {
  .twm-explore-content-2 {
    margin-right: 0px;
  }
}

.twm-explore-content-2 .twm-explore-top-section {
  margin-right: 110px;
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .twm-explore-content-2 .twm-explore-top-section {
    margin-right: 0px;
  }
}

.twm-explore-content-2 .twm-explore-top-section .twm-title-small {
  margin-bottom: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;
}

.twm-explore-content-2 .twm-explore-top-section .twm-title-large {
  margin-bottom: 40px;
}

.twm-explore-content-2 .twm-explore-top-section .twm-title-large h2 {
  color: #fff;
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .twm-explore-content-2 .twm-explore-top-section .twm-title-large h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .twm-explore-content-2 .twm-explore-top-section .twm-title-large h2 {
    font-size: 22px;
  }
}

.twm-explore-content-2 .twm-explore-top-section .twm-title-large p {
  color: #e7e7e7;
}

.twm-explore-content-2 .twm-explore-top-section .twm-read-more .site-button {
  background-color: #fff;
  color: #1967d2;
  text-shadow: 0 0 0 #1967d2, 400px 0 0 #1967d2;
}

.twm-explore-content-2 .twm-explore-top-section .twm-read-more .site-button:hover {
  text-shadow: -400px 0 0 #fff, 0 0 0 #fff;
  background-color: #171d28;
}

.twm-explore-content-2 .twm-explore-top-section .twm-read-more .site-button i {
  color: #1967d2;
  padding-right: 0px;
  padding-left: 5px;
  font-size: 24px;
}

.twm-explore-content-2 .twm-explore-bottom-section .counter-outer-two {
  position: relative;
}

.twm-explore-content-2 .twm-explore-bottom-section .counter-outer-two .tw-count-number {
  font-size: 38px;
  line-height: 38px;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
}

.twm-explore-content-2 .twm-explore-bottom-section .counter-outer-two .counter {
  font-size: 46px;
  line-height: 50px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

.twm-explore-content-2 .twm-explore-bottom-section .counter-outer-two .icon-content-info {
  color: #fff;
  margin-bottom: 0px;
  font-size: 16px;
}

.twm-explore-content-2 .twm-explore-media-wrap2 {
  position: relative;
  z-index: 1;
  height: 100%;
}

.twm-explore-content-2 .twm-explore-media-wrap2:after {
  background-color: #fff;
  content: "";
  position: absolute;
  width: 467px;
  height: 467px;
  border-radius: 50%;
  right: -170px;
  top: 0px;
  z-index: -1;
  box-shadow: 20px 20px 0px #4780ce inset;
}

@media (max-width: 600px) {
  .twm-explore-content-2 .twm-explore-media-wrap2:after {
    display: none;
  }
}

.twm-explore-content-2 .twm-explore-media-wrap2 .twm-media {
  position: absolute;
  width: 350px;
  right: -170px;
  bottom: -40px;
}

@media (max-width: 600px) {
  .twm-explore-content-2 .twm-explore-media-wrap2 .twm-media {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .twm-explore-content-2 .twm-explore-media-wrap2 .twm-media {
    position: inherit;
    right: inherit;
    bottom: inherit;
    margin: 0px auto;
  }
}

.twm-explore-content-2 .twm-explore-media-wrap2 .twm-media img {
  width: auto;
}

@media (max-width: 600px) {
  .twm-explore-content-2 .twm-explore-media-wrap2 .twm-media img {
    width: 95%;
  }
}

/*Explore New Life*/
.twm-explore-content-outer-3 {
  position: relative;
  z-index: 1;
  margin-bottom: 130px;
}

@media (max-width: 991px) {
  .twm-explore-content-outer-3 {
    margin-bottom: 70px;
  }
}

@media (max-width: 575px) {
  .twm-explore-content-outer-3 {
    margin-bottom: 30px;
  }
}

.twm-explore-content-outer-3 .twm-l-line-1 {
  width: 73px;
  height: 270px;
  position: absolute;
  background-color: #eac87c;
  border-radius: 50px;
  left: -30px;
  bottom: -140px;
  z-index: -1;
  opacity: 0.2;
}

@media (max-width: 991px) {
  .twm-explore-content-outer-3 .twm-l-line-1 {
    display: none;
  }
}

.twm-explore-content-outer-3 .twm-l-line-2 {
  width: 73px;
  height: 270px;
  position: absolute;
  background-color: #cea7d9;
  border-radius: 50px;
  left: 55px;
  bottom: -120px;
  z-index: -1;
  opacity: 0.2;
}

@media (max-width: 991px) {
  .twm-explore-content-outer-3 .twm-l-line-2 {
    display: none;
  }
}

.twm-explore-content-outer-3 .twm-explore-content-3 {
  background-color: #fff;
  box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
  border-radius: 10px;
  padding: 55px 70px;
  position: relative;
  margin-top: 70px;
  margin-left: 30px;
  z-index: 1;
}

@media (max-width: 991px) {
  .twm-explore-content-outer-3 .twm-explore-content-3 {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .twm-explore-content-outer-3 .twm-explore-content-3 {
    padding: 30px;
  }
}

.twm-explore-content-outer-3 .twm-explore-content-3:after {
  content: "";
  right: -40px;
  top: 40px;
  position: absolute;
  width: 100%;
  height: 100%;
  border-right: 40px solid #f0f6fe;
  border-bottom: 40px solid #f0f6fe;
  border-radius: 10px;
  z-index: -1;
}

@media (max-width: 575px) {
  .twm-explore-content-outer-3 .twm-explore-content-3:after {
    display: none;
  }
}

.twm-explore-content-outer-3 .twm-explore-content-3 .twm-title-small {
  margin-bottom: 10px;
  color: #17171d;
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;
}

.twm-explore-content-outer-3 .twm-explore-content-3 .twm-title-large {
  margin-bottom: 40px;
}

.twm-explore-content-outer-3 .twm-explore-content-3 .twm-title-large h2 {
  color: #17171d;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .twm-explore-content-outer-3 .twm-explore-content-3 .twm-title-large h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .twm-explore-content-outer-3 .twm-explore-content-3 .twm-title-large h2 {
    font-size: 22px;
  }
}

.twm-explore-content-outer-3 .twm-explore-content-3 .twm-upload-file .site-button {
  background-color: #1967d2;
  color: #fff;
}

.twm-explore-content-outer-3 .twm-explore-content-3 .twm-upload-file .site-button i {
  color: #fff;
  padding-right: 0px;
  padding-left: 5px;
  font-size: 24px;
  text-shadow: none;
}

.twm-explore-content-outer-3 .twm-explore-content-3 .twm-upload-file .site-button:hover {
  background-color: #171d28;
}

.twm-explore-content-outer-3 .twm-explore-content-3 .twm-upload-file .site-button:hover i {
  text-shadow: none;
}

/*Job categories*/
.twm-job-categories-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.twm-job-categories-section {
  margin-bottom: 30px;
}

.twm-job-categories-section:after {
  width: 100%;
  height: 100%;
  content: "";
  left: calc(50% - 120px);
  top: 0px;
  background-color: #fff;
  position: absolute;
  z-index: -1;
}

@media (max-width: 991px) {
  .twm-job-categories-section:after {
    left: 0px;
  }
}

.twm-job-categories-section-2 {
  margin-bottom: 30px;
}

.job-categories-block {
  display: flex;
  padding: 50px 30px;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s all ease;
}

@media (max-width: 1199px) {
  .job-categories-block {
    padding: 30px 20px;
  }
}

@media (max-width: 991px) {
  .job-categories-block {
    margin: 0px 20px;
  }
}

.job-categories-block:after {
  width: 200px;
  height: 200px;
  position: absolute;
  left: -100px;
  bottom: -35px;
  content: "";
  background-color: #3898e2;
  border-radius: 50%;
  opacity: 0.1;
  z-index: -1;
  transition: 0.5s all ease;
}

.job-categories-block:before {
  width: 200px;
  height: 200px;
  position: absolute;
  left: -80px;
  bottom: -30px;
  content: "";
  background-color: #3898e2;
  border-radius: 50%;
  opacity: 0.1;
  z-index: -1;
  transition: 0.5s all ease;
}

.job-categories-block:hover:after {
  width: 500%;
  height: 500%;
  position: absolute;
  left: -50px;
  bottom: -50px;
  content: "";
  background-color: #3898e2;
  border-radius: 50%;
  opacity: 0.05;
  z-index: -1;
  transition: 0.5s all ease;
}

.job-categories-block:hover:before {
  opacity: 0;
}

.job-categories-block .twm-media {
  width: 100px;
}

.job-categories-block .twm-media img {
  width: auto;
}

.job-categories-block .twm-media [class*="flaticon-"] {
  color: #1967d2;
  font-size: 70px;
  line-height: 70px;
  display: flex;
}

.job-categories-block .twm-content .twm-jobs-available {
  background-color: #1967d2;
  color: #fff;
  font-size: 14px;
  border-radius: 10px;
  padding: 4px 15px;
  display: inline-block;
  margin-bottom: 10px;
}

.job-categories-block .twm-content a {
  margin-bottom: 0px;
  display: block;
}

.job-categories-style1 .owl-carousel .owl-stage-outer {
  display: table;
  position: relative;
  padding: 30px 0px 30px 10px;
}

@media (max-width: 991px) {
  .job-categories-style1 .owl-carousel .owl-stage-outer {
    display: block;
    padding: 30px 0px;
  }
}

.owl-btn-left-bottom .owl-nav {
  text-align: left;
  position: absolute;
}

.owl-btn-left-bottom .owl-nav .owl-next {
  right: -1px;
  transition: all 0.2s linear;
}

.owl-btn-left-bottom .owl-nav .owl-prev {
  transition: all 0.2s linear;
}

.job-categories-block-2 {
  padding: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  transition: 0.5s all ease;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
}

.job-categories-block-2:after {
  width: 110px;
  height: 110px;
  position: absolute;
  right: -60px;
  bottom: -60px;
  content: "";
  background-color: #1967d2;
  border-radius: 50%;
  opacity: 0.04;
  z-index: -1;
  transition: 0.5s all ease;
}

.job-categories-block-2:before {
  width: 110px;
  height: 110px;
  position: absolute;
  left: -60px;
  top: -60px;
  content: "";
  background-color: #1967d2;
  border-radius: 50%;
  opacity: 0.04;
  z-index: -1;
  transition: 0.5s all ease;
}

.job-categories-block-2 .twm-media {
  width: 100px;
  height: 100px;
  line-height: 0;
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
}

.job-categories-block-2 .twm-media img {
  transition: 0.5s all ease;
  height: 60px;
  max-width: 60px;
  border-radius: 50%;
}

.job-categories-block-2 .twm-media [class*="flaticon-"] {
  color: var(--category-color);
  font-size: 70px;
  line-height: 0px;
  transition: 0.5s all ease;
}

.job-categories-block-2 .twm-media [class*="flaticon-"]:before {
  transform: translateX(-50%);
  left: 50%;
  position: inherit;
}

.job-categories-block-2 .twm-media:after {
  transition: 0.5s all ease;
  width: 70px;
  height: 70px;
  background-color: #fff;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0.1);
  position: absolute;
  left: 50%;
  content: "";
  z-index: -1;
  top: 50%;
  opacity: 0;
}

.job-categories-block-2 .twm-content .twm-jobs-available {
  background-color: #f0f6fe;
  color: var(--category-color);
  font-size: 14px;
  border-radius: 10px;
  padding: 4px 15px;
  display: inline-block;
  margin-bottom: 10px;
}

.job-categories-block-2 .twm-content a {
  margin-bottom: 0px;
  display: block;
  transition: 0.5s all ease;
}

.job-categories-block-2:hover {
  background-color: var(--category-color);
}

.job-categories-block-2:hover:after {
  background-color: #fff;
  opacity: 0.1;
  width: 500%;
  height: 500%;
}

.job-categories-block-2:hover:before {
  display: none;
}

.job-categories-block-2:hover .twm-content a {
  color: #fff;
}

.job-categories-block-2:hover .twm-media img {
  transform: scale(0.8);
}

.job-categories-block-2:hover .twm-media [class*="flaticon-"] {
  transform: scale(0.8);
}

.job-categories-block-2:hover .twm-media:after {
  transform: translateX(-50%) translateY(-50%) scale(1);
  opacity: 1;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

/*Search Bar*/

.twm-bnr-search-bar {
  border-radius: 2px;
  height: auto;
  text-align: left;
  padding: 10px;
  background-color: #e4edfa;
  border-radius: 10px;
  width: 100%;
  /* margin-top: 60px; */
}

label.slabel {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}

/* home title  */

.main_title {
  font-size: 40px;
  line-height: 15px;
  font-weight: 600;
  color: #000;
  /* margin-bottom: 30px; */
}

@media (max-width: 1199px) {
  .twm-bnr-search-bar {
    height: auto;
    padding: 17px;
  }

  .twm-bnr-search-bar .form-group {
    margin: 5px 0px;
  }
}

.twm-bnr-search-bar label {
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  text-transform: uppercase;
  display: block;
  padding-top: 0px;
  margin-top: 7px;
}

.twm-bnr-search-bar .form-select,
.twm-bnr-search-bar .form-control {
  font-size: 14px;
  height: 34px;
  padding: 0px;
  font-weight: 500;
  border: none;
}

.twm-bnr-search-bar .form-control:focus {
  box-shadow: none;
}

.twm-bnr-search-bar .form-control::-webkit-input-placeholder {
  color: #000;
  font-size: 14px;
}

.twm-bnr-search-bar .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
  font-size: 14px;
}

.twm-bnr-search-bar .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
  font-size: 14px;
}

.twm-bnr-search-bar .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
  font-size: 14px;
}

.twm-inputicon-box {
  position: relative;
}

.twm-inputicon-box .twm-input-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 30px;
  z-index: 5;
  font-size: 18px;
  text-align: center;
}

.twm-bnr-popular-search {
  position: relative;
  font-size: 14px;
  margin-top: 30px;
}

.twm-bnr-popular-search .twm-title {
  margin-right: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

/*Search Bar Section home 2*/
.twm-search-bar-2-wrap {
  position: relative;
  z-index: 2;
}

.twm-search-bar-2-wrap .twm-search-bar-2-inner {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(56, 152, 226, 0.3);
  margin: -80px 0px 0px 0px;
  position: relative;
  background-color: #fff;
  z-index: 1;
}

@media (max-width: 991px) {
  .twm-search-bar-2-wrap .twm-search-bar-2-inner {
    margin: 40px 0px 30px 0px;
  }
}

.twm-search-bar-2-wrap .twm-search-bar-2-inner>.row {
  margin-bottom: 10px;
}

.twm-search-bar-2-wrap .twm-search-bar-2-inner .twm-bnr-popular-search {
  margin-top: 10px;
}

/*trusted Logo*/
.twm-trusted-by-wrap {
  margin-left: 5px;
}

@media (max-width: 1199px) {
  .twm-trusted-by-wrap {
    margin-left: 0px;
  }
}

@media (max-width: 991px) {
  .twm-trusted-by-wrap {
    margin: 40px 0px 30px 0px;
  }
}

.twm-trusted-by-wrap .twm-trusted-by-title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

@media (max-width: 991px) {
  .twm-trusted-by-wrap .twm-trusted-logo a {
    text-align: center;
  }

  .twm-trusted-by-wrap .twm-trusted-logo a img {
    margin: 0px auto;
  }
}

.twm-trusted-logo a img {
  width: auto !important;
}

.rotate-center {
  animation: rotate-center 30s linear infinite both;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-center-reverse {
  animation: rotate-center 30s linear infinite reverse both;
}

@keyframes rotate-center-reverse {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.slide-top-animation {
  animation: slide-top-animation 3s ease-in-out infinite alternate-reverse both;
}

@keyframes slide-top-animation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
}

.scale-up-center {
  animation: scale-up-center 2s ease-in-out infinite alternate-reverse both;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

/*tabs*/
.twm-tabs-style-1.center .nav-tabs {
  text-align: center;
  display: block;
}

@media (max-width: 575px) {
  .twm-tabs-style-1.center .nav-tabs {
    text-align: left;
  }
}

.twm-tabs-style-1.center .nav-tabs .nav-item {
  display: inline-block;
}

.twm-tabs-style-1 .nav-tabs {
  margin-bottom: 50px;
  border: none;
}

.twm-tabs-style-1 .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 5px 15px;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #1967d2;
  border-radius: 10px;
  font-size: 14px;
  background-color: #f0f6fe !important;
}

.twm-tabs-style-1 .nav-tabs .nav-item .nav-link.active {
  background-color: #1967d2 !important;
  color: #fff;
}

/*tabs*/
.twm-tabs-style-2.center .nav-tabs {
  text-align: center;
  display: block;
}

.twm-tabs-style-2.center .nav-tabs .nav-item {
  display: inline-block;
}

.twm-tabs-style-2 .nav-tabs {
  margin-bottom: 20px;
  border: none;
}

.twm-tabs-style-2 .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 8px 15px;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #1967d2;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #1967d2;
}

@media (max-width: 575px) {
  .twm-tabs-style-2 .nav-tabs .nav-item .nav-link {
    padding: 4px 10px;
    font-size: 14px;
  }
}

.twm-tabs-style-2 .nav-tabs .nav-item .nav-link i {
  margin-right: 5px;
}

.twm-tabs-style-2 .nav-tabs .nav-item .nav-link.active {
  background-color: #1967d2 !important;
  color: #fff;
}

/*Side bar Advertisement*/
.twm-advertisment {
  border-radius: 10px;
  overflow: hidden;
  padding: 50px 30px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  color: #fff;
  margin: 30px 0px;
}

.twm-advertisment.m-b0 {
  margin-bottom: 0px;
}

@media (max-width: 991px) {
  .twm-advertisment.m-b0 {
    margin-bottom: 30px;
  }
}

.twm-advertisment .overlay {
  background-color: #1967d2ba;
  opacity: 0.8;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
}

.twm-advertisment .twm-title {
  color: #fff;
  margin-bottom: 20px;
}

/* .twm-advertisment p {
  margin-bottom: 30px;
} */

.form-control:focus {
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #a0a0a0;
  font-size: 14px;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #a0a0a0;
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #a0a0a0;
  font-size: 14px;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #a0a0a0;
  font-size: 14px;
}

/*Side Bar Elements*/
.twm-sidebar-ele-filter {
  margin: 30px 0px;
}

.twm-sidebar-ele-filter ul {
  list-style: none;
}

.twm-sidebar-ele-filter ul li {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 20px;
  color: #666666;
}

.twm-sidebar-ele-filter ul li .twm-job-type-count {
  padding-left: 30px;
  color: #1967d2;
  font-size: 14px;
}

/*Checked Input*/
.form-check-input {
  width: 16px;
  height: 16px;
}

.form-check-input:checked[type="radio"] {
  background-image: none;
  position: relative;
}

.form-check-input:checked[type="radio"]:after {
  background-color: #1967d2;
  width: 10px;
  height: 10px;
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
}

.form-check-input[type="radio"] {
  background-color: transparent;
}

.product-filter-wrap {
  padding-bottom: 30px;
}

.product-filter-wrap .woocommerce-result-count-left {
  font-size: 16px;
  font-weight: 600;
}

.twm-filter-select .wt-select-bar-2 {
  word-wrap: normal;
  padding: 10px 0px;
  margin: 0 9px;
  text-align: center;
  background-color: #edb859;
  color: #fff;
  font-weight: 500;
  outline: none;
  border: none;
  box-shadow: 1px 2px 3px rgb(0, 0, 0, 0.5);
  border-radius: 5px;
}

@media (max-width: 767px) {
  .product-filter-wrap {
    display: block !important;
  }

  .product-filter-wrap .woocommerce-result-count-left {
    margin-bottom: 10px;
    display: block;
  }
}

.product-filter-wrap .twm-filter-select {
  display: flex;
  align-items: center;
}

@media (max-width: 400px) {
  .product-filter-wrap .twm-filter-select {
    display: block;
  }
}

.product-filter-wrap .twm-filter-select .woocommerce-result-count {
  margin-right: 15px;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .product-filter-wrap .twm-filter-select .woocommerce-result-count {
    display: block;
    width: 100%;
    max-width: 120px;
  }
}

.product-filter-wrap .twm-filter-select .form-select-3:last-child {
  margin-right: 0px;
}

.form-select-3 {
  width: 160px;
  margin-right: 20px;
  background-color: #f0f6fe;
  border: 0px;
  border-radius: 10px;
}

@media (max-width: 400px) {
  .form-select-3 {
    margin-top: 10px;
    width: 100%;
  }
}

/*Employer detail*/
.twm-employer-self-wrap {
  border-radius: 10px;
  margin-bottom: 40px;
}

.twm-employer-self-info {
  position: relative;
  z-index: 1;
}

.twm-employer-self-info .twm-employer-self-top {
  position: relative;
  padding-top: 20px;
}

.twm-employer-self-info .twm-employer-self-top .twm-media-bg img {
  border-radius: 10px;
}

.twm-employer-self-info .twm-employer-self-top .twm-media {
  width: 90px;
  height: 90px;
  line-height: 90px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
  position: absolute;
  top: -40px;
  left: 20px;
  overflow: hidden;
  margin-bottom: -20px;
}

.twm-employer-self-info .twm-employer-self-top .twm-media img {
  width: 100%;
  display: block;
}

.twm-employer-self-info .twm-employer-self-top .twm-job-title {
  margin-bottom: 15px;
  font-size: 16px;
}

.twm-employer-self-info .twm-employer-self-top .twm-employer-address i {
  padding-right: 3px;
}

.twm-employer-self-info .twm-employer-self-top .twm-employer-websites {
  margin-bottom: 10px;
}

.twm-employer-self-info .twm-employer-self-top .twm-mid-content {
  position: relative;
  padding-top: 80px;
}

.twm-employer-self-info .twm-employer-self-bottom {
  position: absolute;
  top: 20px;
  right: 0px;
}

@media (max-width: 767px) {
  .twm-employer-self-info .twm-employer-self-bottom {
    position: inherit;
    top: inherit;
    right: inherit;
    margin-top: 10px;
  }
}

.twm-employer-self-info .twm-employer-self-bottom a {
  margin-right: 10px;
}

.twm-employer-self-info .twm-employer-self-bottom a:last-child {
  margin-right: 0px;
}

/*Work Experience*/
.twm-timing-list-wrap {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 50px;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .twm-timing-list-wrap {
    padding: 20px 30px;
  }
}

.twm-timing-list {
  margin-bottom: 30px;
  margin-left: 30px;
}

.twm-timing-list:last-child {
  margin-bottom: 0px;
}

.twm-timing-list .twm-time-list-date {
  font-size: 14px;
  color: #666666;
}

.twm-timing-list .twm-time-list-title {
  color: #1967d2;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.twm-timing-list .twm-time-list-title:before {
  width: 20px;
  height: 20px;
  border: 3px solid #f0f6fe;
  border-radius: 50%;
  background-color: #1967d2;
  content: "";
  position: absolute;
  left: -50px;
  top: 3px;
}

.twm-timing-list .twm-time-list-title:after {
  width: 22px;
  height: 1px;
  background-color: #1967d2;
  content: "";
  position: absolute;
  left: -26px;
  top: 13px;
}

.twm-timing-list .twm-time-list-position {
  margin: 10px 0px;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.twm-timing-list .twm-time-list-discription p:last-child {
  margin-bottom: 0px;
}

/*Social Share*/
.twm-social-tags {
  /* margin-top: -10px; */
  margin: 15px 0;
}

.twm-social-tags a {
  padding: 5px 15px;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}

.twm-social-tags a:hover {
  opacity: 0.9;
}

.twm-social-tags a.circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0px;
  outline: 1px solid;
  transition: 0.5s all ease;
}

.twm-social-tags a.circle:hover {
  transform: scale(0.9) rotate(360deg);
}

.fb-clr {
  background-color: #4d69b2;
}

.tw-clr {
  background-color: gray;
}

.link-clr {
  background-color: #0579b6;
}

.whats-clr {
  background-color: #2ca53b;
}

.pinte-clr {
  background-color: #f03c41;
}

/*Employer detail*/
.twm-job-self-wrap {
  border-radius: 10px;
  margin-bottom: 25px;
}

.twm-job-self-wrap .twm-job-self-info {
  position: relative;
  z-index: 1;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top {
  position: relative;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-media-bg img {
  border-radius: 10px;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-jobs-category {
  position: absolute;
  left: 20px;
  top: 20px;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-media {
  width: 90px;
  height: 90px;
  line-height: 90px;
  display: inline-block;
  text-align: center;
  background-color: var(--c);
  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
  position: absolute;
  top: -40px;
  left: 20px;
  overflow: hidden;
  margin-bottom: -20px;
}

@media (max-width: 420px) {
  .twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-media {
    left: auto;
    top: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    position: inherit;
  }
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-media img {
  width: 100%;
  display: block;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-job-title {
  margin-bottom: 16px;
  font-size: 24px;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-job-title .twm-job-post-duration {
  color: darkcyan;
  font-size: 14px;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-job-address i {
  padding-right: 3px;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-job-self-mid {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-job-self-mid {
    display: block;
  }
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-job-self-mid .twm-job-apllication-area {
  font-weight: 500;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-job-self-mid .twm-job-apllication-area .twm-job-apllication-date {
  color: darkcyan;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-jobs-amount {
  font-weight: 500;
  color: #17171d;
  font-size: 14px;
  /* display: inline-block; */
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-jobs-amount span {
  color: darkcyan;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-job-websites {
  margin-bottom: 10px;
  margin-right: 5px;
}

.heading_ .twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-mid-content {
  position: relative;
  padding-top: 80px;
}

@media (max-width: 420px) {
  .twm-job-self-wrap .twm-job-self-info .twm-job-self-top .twm-mid-content {
    padding-top: 0px;
  }
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-bottom {
  position: absolute;
  top: 20px;
  right: 0px;
}

@media (max-width: 767px) {
  .twm-job-self-wrap .twm-job-self-info .twm-job-self-bottom {
    position: inherit;
    top: inherit;
    right: inherit;
    margin-top: 10px;
  }
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-bottom a {
  margin-right: 10px;
}

.twm-job-self-wrap .twm-job-self-info .twm-job-self-bottom a:last-child {
  margin-right: 0px;
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-media-bg {
  position: relative;
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-media-bg img {
  width: 100%;
}

@media (max-width: 420px) {
  .twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-media-bg img {
    height: 200px;
    object-fit: cover;
  }
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-media-bg .twm-job-self-bottom {
  right: 20px;
}

@media (max-width: 767px) {
  .twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-media-bg .twm-job-self-bottom {
    position: absolute;
    top: 20px;
    right: 20px;
    margin-top: 0px;
  }
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-jobs-category span {
  color: #fff;
  border-radius: 5px;
  padding: 5px 12px;
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-media {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

@media (max-width: 420px) {
  .twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-media {
    left: 50%;
    top: -60px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-job-title {
  font-size: 24px;
}

@media (max-width: 420px) {
  .twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-job-title {
    font-size: 18px;
  }

  .twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-job-title .twm-job-post-duration {
    display: block;
    padding: 5px 0px 0px 0px;
  }
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-job-self-mid {
  display: block;
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-jobs-amount {
  display: block;
  margin-bottom: 15px;
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-jobs-amount span {
  color: #046c38;
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-job-websites {
  margin-bottom: 15px;
  display: block;
}

.twm-job-self-wrap.twm-job-detail-v2 .twm-job-self-info .twm-job-self-top .twm-mid-content {
  position: relative;
  padding-top: 80px;
  text-align: center;
}

/*Side Map*/
.twm-s-map-iframe {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
}

.twm-s-map-iframe iframe {
  width: 100%;
  border: 0px;
  filter: grayscale(100%);
}

/*Side Map2*/
.twm-m-map-iframe {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.twm-m-map-iframe iframe {
  width: 100%;
  border: 0px;
  filter: grayscale(100%);
}

/*Sidebar Info*/
.twm-s-info3 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(56, 152, 226, 0.3);
  padding: 30px;
  margin-top: 90px;
  position: relative;
}

.twm-s-info3 .twm-s-info-logo-section {
  position: relative;
}

.twm-s-info3 .twm-s-info-logo-section .twm-media {
  width: 90px;
  height: 90px;
  line-height: 90px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 18px rgba(56, 152, 226, 0.3);
  position: relative;
  top: -80px;
  left: 0px;
  overflow: hidden;
  margin-bottom: -50px;
}

.twm-s-info3 ul {
  list-style: none;
  margin-bottom: 30px;
  line-height: 35px;
}

.twm-s-info3 ul li {
  margin-bottom: 10px;
}

.twm-s-info3 ul li:last-child {
  margin-bottom: 0px;
}

.twm-s-info3 ul li .twm-s-info-inner {
  position: relative;
  padding-left: 40px;
}

.twm-s-info3 ul li .twm-s-info-inner i {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 18px;
  color: #1967d2;
}

.twm-s-info3 ul li .twm-s-info-inner .twm-title {
  font-size: 15px;
  color: #666666;
}

.twm-s-info3 ul li .twm-s-info-inner .twm-s-info-discription {
  font-size: 14px;
  color: #17171d;
  word-break: break-word;
}

.twm-s-info3 .site-button {
  display: block;
  text-align: center;
}

/*Sidebar Info*/
.twm-s-info {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  padding: 40px;
}

@media (max-width: 575px) {
  .twm-s-info {
    padding: 20px;
  }
}

.twm-s-info ul {
  list-style: none;
  margin-bottom: 0px;
}

.twm-s-info ul li {
  margin-bottom: 10px;
}

.twm-s-info ul li:last-child {
  margin-bottom: 0px;
}

.twm-s-info ul li .twm-s-info-inner {
  position: relative;
  padding-left: 40px;
}

.twm-s-info ul li .twm-s-info-inner i {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 18px;
  color: #1967d2;
}

.twm-s-info ul li .twm-s-info-inner .twm-title {
  font-size: 14px;
  color: #666666;
}

.twm-s-info ul li .twm-s-info-inner .twm-s-info-discription {
  font-size: 16px;
  color: #17171d;
  word-break: break-word;
}

/*Sidebar  Info 2*/
.twm-job-hilites {
  padding-bottom: 30px;
}

.twm-job-hilites li {
  display: block;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 15px 15px 50px;
  position: relative;
  color: #17171d;
  font-size: 16px;
  margin-bottom: 10px;
}

.twm-job-hilites li:last-child {
  margin-bottom: 0px;
}

.twm-job-hilites li i {
  position: absolute;
  left: 15px;
  top: 19px;
  font-size: 18px;
  color: #1967d2;
}

.twm-s-info2 .twm-job-hilites2 {
  list-style: none;
  margin-bottom: 0px;
  margin-left: 15px;
}

.twm-s-info2 .twm-job-hilites2 li {
  margin-bottom: 10px;
}

.twm-s-info2 .twm-job-hilites2 li:last-child {
  margin-bottom: 0px;
}

.twm-s-info2 .twm-job-hilites2 li .twm-s-info-inner {
  position: relative;
  padding-left: 40px;
}

.twm-s-info2 .twm-job-hilites2 li .twm-s-info-inner i {
  position: absolute;
  left: 0px;
  top: 15px;
  font-size: 18px;
  color: #1967d2;
}

.twm-s-info2 .twm-job-hilites2 li .twm-s-info-inner .twm-title {
  font-size: 14px;
  color: #666666;
}

.twm-s-info2 .twm-job-hilites2 li .twm-s-info-inner .twm-s-info-discription {
  font-size: 16px;
  color: #17171d;
}

/*Sidebar-contact*/
.twm-s-contact {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  padding: 40px;
}

@media (max-width: 575px) {
  .twm-s-contact {
    padding: 20px;
  }
}

.twm-s-contact textarea.form-control {
  height: 100px;
  resize: none;
}

.twm-s-contact .form-control {
  width: 100%;
  height: 60px;
  padding: 20px;
  border: 1px solid transparent;
  background-color: #f0f6fe;
  border-radius: 10px;
}

/*Faq*/
.tw-faq-section {
  max-width: 830px;
  margin: 0px auto;
}

.tw-faq-section .tw-faq .accordion-item {
  border: 1px solid transparent;
  margin-bottom: 20px;
  overflow: hidden;
  border: 1px solid #efefef;
  border-radius: 10px;
  background-color: #f0f6fe;
}

.tw-faq-section .tw-faq .accordion-item .accordion-button {
  font-size: 18px;
  color: #17171d;
  padding: 10px 16px 10px 16px;
  font-weight: 500;
}

.tw-faq-section .tw-faq .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: #f0f6fe;
}

.tw-faq-section .tw-faq .accordion-item .accordion-button:focus {
  box-shadow: none !important;
}

/*Accordion button*/
.accordion-button:after {
  background-image: none;
  content: "\e9b3";
  font-family: "Feather";
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #17171d;
  text-align: center;
  font-size: 22px;
}

.accordion-button:not(.collapsed):after {
  background-image: none;
  content: "\e998";
  transform: none;
}

/*Sign Up popup*/
.twm-sign-up {
  margin-bottom: 10px;
}

.twm-sign-up .modal-dialog {
  max-width: 590px;
}

@media (max-width: 575px) {
  .twm-sign-up .modal-dialog {
    margin: 20px;
  }
}

.twm-sign-up .modal-content {
  border-top: 6px solid #1967d2;
}

.twm-sign-up .modal-content .form-check-label a {
  color: #1967d2;
}

.twm-sign-up .modal-content .form-check-label a:hover {
  color: #17171d;
}

.twm-sign-up .modal-content .twm-backto-login {
  background-color: transparent;
  color: #1967d2;
  border: none;
}

.twm-sign-up .modal-content .twm-backto-login:hover {
  color: #17171d;
}

.twm-sign-up .modal-header {
  display: block;
  text-align: center;
  border: none;
  margin-top: 50px;
}

.twm-sign-up .modal-header .modal-title {
  font-size: 34px;
  margin-bottom: 15px;
}

.twm-sign-up .modal-header .btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 1;
}

.twm-sign-up .modal-body {
  padding: 20px 30px;
}

@media (max-width: 575px) {
  .twm-sign-up .modal-body {
    padding: 20px;
  }
}

.twm-sign-up .modal-footer {
  border: none;
  background-color: #f0f6fe;
  text-align: center;
  display: block;
  padding: 0px 0px 40px 0px;
  margin-top: 30px;
}

.twm-sign-up .modal-footer .modal-f-title {
  display: inline-block;
  background-color: #f0f6fe;
  padding: 6px 15px;
  border-radius: 10px;
  position: relative;
  top: -22px;
  margin-bottom: -11px;
}

.twm-sign-up .rem-forgot {
  display: flex;
  justify-content: space-between;
}

.twm-sign-up .site-button {
  display: block;
  text-align: center;
  width: 100%;
}

.twm-modal-social {
  list-style: none;
}

.twm-modal-social li {
  display: inline-block;
}

.twm-modal-social li a {
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: block;
  border-radius: 50%;
  text-align: center;
  border-width: 1px;
  border-style: solid;
}

.facebook-clr {
  color: #4d69b2;
  border-color: #4d69b2;
}

.twitter-clr {
  color: #38b2e1;
  border-color: #38b2e1;
}

.linkedin-clr {
  color: #0579b6;
  border-color: #0579b6;
}

.google-clr {
  color: #d8483d;
  border-color: #d8483d;
}

/*============================================================================================
	DashBoard Css Start
=============================================================================================*/
strong {
  font-weight: 500;
}

/*Dashboard Header*/
.header-widget-wrap .listing-user .dropdown-menu {
  width: 100%;
  min-width: 180px;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  border: 0px;
  padding: 20px;
}

.nav-btn-wrap {
  display: table-cell;
  vertical-align: middle;
}

.nav-btn-admin {
  font-size: 28px;
  line-height: 68px;
  cursor: pointer;
  display: block;
  color: #0f1221;
}

#header-admin.active {
  overflow: hidden;
}

#header-admin.active .nav-btn-admin {
  transform: rotate(180deg);
}

#header-admin.active .container {
  min-width: 480px;
}

.header-left {
  float: left;
  display: table;
}

.header-left>.header-widget-wrap {
  display: table-cell;
  vertical-align: middle;
}

.header-right {
  float: right;
  display: table;
}

.header-right>.header-widget-wrap {
  display: table-cell;
  vertical-align: middle;
}

.header-right>.header-login-signup {
  display: table-cell;
  vertical-align: middle;
}

.header-widget-wrap>.header-widget {
  display: inline-block;
  margin-left: 25px;
  position: relative;
  cursor: pointer;
}

.header-widget-wrap .dropdown-menu {
  width: 350px;
  min-width: 350px;
  top: 60px;
  left: auto;
  right: -15px;
  padding: 0;
}

@media (max-width: 480px) {
  .header-widget-wrap .dropdown-menu {
    width: 300px;
    min-width: 300px;
    left: -5px !important;
  }
}

.listing-user {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.listing-user .dropdown-toggle {
  padding-right: 20px;
  display: block;
}

.listing-user .dropdown-toggle::after {
  display: none;
}

.listing-user .user-name {
  transition: 0.5s all ease;
  color: #fff;
}

.listing-user span {
  display: inline-block;
  width: 40px;
  margin-right: 15px;
}

.listing-user span img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.listing-user ul {
  list-style: none;
}

.listing-user ul li a {
  line-height: 22px;
  color: #17171d;
  font-size: 13px;
  padding: 5px 5px 5px 24px;
  display: block;
  transition: 0.2s;
  position: relative;
}

.listing-user ul li a i {
  position: absolute;
  left: 0px;
  top: 10px;
  color: #1967d2;
}

.listing-user ul li a:hover {
  color: #1967d2;
}

.listing-user .user-name:before {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  transition: 0.5s all ease;
  display: inline-block;
  margin-left: 6px;
  color: #1967d2;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

@media (max-width: 400px) {
  .listing-user .user-name:before {
    font-size: 18px;
  }
}

.dashboard-user-section .listing-user {
  margin-right: 0px;
  position: relative;
  padding: 14px 0px;
}

.dashboard-user-section .listing-user .user-name {
  color: inherit;
}

@media (max-width: 400px) {
  .dashboard-user-section .listing-user .user-name {
    font-size: 0px;
  }
}

.dashboard-user-section .listing-user .user-name:after {
  color: inherit;
}

.wt-admin-right-page-header {
  position: relative;
  padding: 15px 0px;
  margin-bottom: 20px;
}

.wt-admin-right-page-header h2 {
  font-size: 28px;
  color: #17171d;
  font-weight: 400;
  margin-bottom: 15px;
}

.wt-admin-right-page-header .breadcrumbs {
  position: relative;
}

.wt-admin-right-page-header .breadcrumbs a {
  margin-right: 24px;
  position: relative;
  font-weight: 400;
}

.wt-admin-right-page-header .breadcrumbs a:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -16px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #0f1221;
}

.wt-admin-right-page-header .breadcrumbs span {
  margin-right: 24px;
  position: relative;
  font-weight: 400;
  color: #1967d2;
}

.content-admin-main .panel.panel-default {
  border-radius: 10px;
  background: #fff;
}

.wt-panel-heading {
  border-bottom: 1px solid #ddd;
}

/*My Account css*/
#header-admin {
  width: calc(100% - 320px);
  background: #fff;
  border-bottom: 1px solid #ddd;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  transition: all 0.3s;
}

@media (max-width: 1360px) {
  #header-admin {
    width: calc(100% - 260px);
  }
}

#header-admin.active {
  width: 100%;
}

#header-admin .container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 100%;
}

#sidebar-admin-wraper {
  width: 320px;
  position: fixed;
  z-index: 1 !important;
  top: 0px;
  left: 0px;
  height: 100vh;
  z-index: 999;
  background: #d4e6ff;
  color: #17171d;
  transition: 0.5s all ease;
}

@media (max-width: 1360px) {
  #sidebar-admin-wraper {
    width: 260px;
  }
}

#sidebar-admin-wraper.active {
  margin-left: -320px;
}

@media (max-width: 768px) {
  #sidebar-admin-wraper.active {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  #sidebar-admin-wraper {
    margin-left: -320px;
  }
}

#content {
  width: calc(100% - 320px);
  padding-top: 70px;
  min-height: 100vh;
  transition: 0.5s all ease;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #f9fcff;
}

@media (max-width: 1360px) {
  #content {
    width: calc(100% - 260px);
  }
}

#content.active {
  width: 100%;
  overflow: hidden;
}

@media (max-width: 768px) {
  #content.active {
    width: calc(100% - 260px);
  }
}

#content.active .content-admin-main {
  min-width: 480px;
}

@media (max-width: 768px) {
  #content {
    width: 100%;
  }
}

@media (max-width: 768px) {
  #header-admin {
    width: 100%;
  }

  #header-admin.active {
    width: calc(100% - 260px);
  }
}

/*Dashboard message Dropdown*/
.dashboard-message-dropdown .dropdown-menu {
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  border: 0px;
}

/*Dashboard Notification Dropdown*/
.dashboard-noti-dropdown .dropdown-menu {
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  border: 0px;
}

/*Dashboard Message*/
.jobzilla-admin-messange {
  color: #999;
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  position: relative;
  top: 10px;
}

.jobzilla-admin-messange.dropdown-toggle:after {
  display: none;
}

.jobzilla-admin-messange span {
  position: absolute;
  top: -15px;
  right: -12px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 100%;
  background-color: #1967d2;
}

/*Dashboard Notification*/
.jobzilla-admin-notification {
  color: #999;
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  position: relative;
  top: 10px;
}

.jobzilla-admin-notification.dropdown-toggle:after {
  display: none;
}

.jobzilla-admin-notification span {
  position: absolute;
  top: -15px;
  right: -12px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 100%;
  background-color: #1967d2;
}

.page-logo {
  padding: 40px 10px 20px 30px;
  margin-bottom: 16px;
}

.page-logo a img {
  max-width: 130px;
}

/*Admin Nav*/
.admin-nav.scrollbar-macosx {
  height: calc(100vh - 150px);
  padding-left: 30px;
  overflow-y: scroll;
}

.admin-nav ul {
  list-style: none;
  margin: 0;
  font-weight: 500;
}

.admin-nav ul li {
  float: none;
}

.admin-nav ul li:last-child {
  border-bottom: none;
}

.admin-nav ul li.has-child {
  position: relative;
}

.admin-nav ul li a>i {
  font-size: 18px;
  margin-right: 25px;
  position: absolute;
  left: 10px;
  top: 14px;
}

.admin-nav ul li .sub-menu {
  opacity: 1;
  margin-left: 30px;
  background: #f9fcff;
  border-radius: 10px 0px 0px 10px;
}

.admin-nav ul li .sub-menu>li a {
  padding: 5px 20px;
  color: #1967d2;
  border-radius: 5px;
  margin-bottom: 1px;
  font-size: 13px;
  font-weight: normal;
}

.admin-nav ul li .sub-menu>li a:hover {
  color: #17171d;
}

.admin-nav ul li .sub-menu>li a>i {
  font-size: 12px;
}

.admin-nav ul>li>a {
  padding: 10px 15px 10px 40px;
  color: #323232;
  display: block;
  font-size: 14px;
  position: relative;
  font-weight: 400;
  transition: 0.5s all ease;
}

.admin-nav ul>li>a:hover {
  color: #1967d2;
}

.admin-nav ul>li ul {
  display: none;
  position: static;
  visibility: visible;
  width: auto;
}

.admin-nav ul>li .sub-menu {
  display: none;
  position: static;
  visibility: visible;
  width: auto;
}

.admin-nav ul>li.active>a {
  color: #1967d2;
  background-color: #f9fcff;
  border-radius: 10px 0px 0px 10px;
}

.admin-nav ul>li.has-child.nav-active>.submenu-toogle:before {
  content: "\f107";
}

.admin-nav .has-child .submenu-toogle {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
  padding: 7px;
  font-size: 16px;
  display: none;
  display: block;
  color: #000;
}

.admin-nav .has-child li .submenu-toogle {
  opacity: 0.9;
}

.admin-nav .has-child.nav-active>a+.submenu-toogle.fa-angle-down:before {
  content: "\f106";
}

.content-admin-main {
  padding: 30px;
}

@media (max-width: 540px) {
  .content-admin-main {
    padding: 15px;
  }
}

.content-admin-main textarea.form-control {
  height: auto;
  resize: none;
}

.ls-inputicon-box {
  position: relative;
}

.ls-inputicon-box .fs-input-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 60px;
  z-index: 5;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  color: #1967d2;
}

.ls-inputicon-box .form-control {
  padding-left: 35px;
}

.ls-inputicon-box .bootstrap-select .dropdown-toggle {
  padding-left: 35px;
}

.ls-inputicon-box .remove_field {
  position: absolute;
  right: 4px;
  top: 2px;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  display: block;
}

/*Form Elements*/
.panel-default>.panel-heading .panel-tittle i {
  margin-right: 20px;
}

.panel-default>.panel-heading .panel-heading-left {
  float: left;
}

.panel-default>.panel-heading .panel-heading-right {
  float: right;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #e7ecf1;
}

.dropdown.bootstrap-select.wt-select-box {
  width: 100% !important;
}

.dropdown.bootstrap-select.wt-select-box .btn-light {
  background-color: #f0f6fe;
  border-color: #f0f6fe;
}

.dropdown.bootstrap-select.wt-select-box .form-control {
  height: 30px;
}

.dropdown.bootstrap-select.wt-select-box .dropdown-toggle {
  height: 60px;
  border-radius: 10px;
  font-size: 14px;
}

.dropdown.bootstrap-select.wt-select-box .dropdown-toggle:focus {
  box-shadow: none !important;
  outline: none !important;
  background-color: #f8f8f8;
}

.bootstrap-select.wt-select-box .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
  padding-top: 12px;
}

.wt-input-icon .input-group-addon2 {
  position: absolute;
  left: 0px;
  top: 12px;
  z-index: 4;
  border: none;
  padding: 0;
  color: #222;
  border-radius: 6px;
  background: none;
}

.form-group.form-inline .radio {
  margin-right: 15px;
}

.form-group.form-inline .checkbox {
  margin-right: 15px;
}

/*Dashboard Card*/
.dashboard-card {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
}

.wt-card-right {
  text-align: right;
  font-size: 50px;
  line-height: 50px;
  font-weight: 300;
  margin-bottom: 10px;
}

.wt-card-bottom {
  text-align: right;
}

.wt-card-bottom h4 {
  font-weight: 400;
}

.wt-card-icon {
  font-size: 48px;
  line-height: 0px;
  color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  position: absolute;
  left: 20px;
  top: 20px;
}

/*Upload Video Button*/
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  display: block;
}

/*dashboard List*/
.dashboard-list-box ul {
  list-style: none;
  margin-bottom: 0px;
}

.dashboard-list-box ul li {
  display: block;
  padding: 15px 35px 15px 0px;
  border-bottom: 1px solid #e7ecf1;
  position: relative;
}

.dashboard-list-box ul li:last-child {
  border-bottom: 0px;
}

.dashboard-list-box ul li a {
  padding: 0px 3px;
  font-weight: 500;
}

.dashboard-list-box ul li:hover a.close-list-item {
  display: block;
}

.dashboard-list-box a.close-list-item {
  color: red;
  position: absolute;
  right: 20px;
  font-weight: normal;
  padding: 0px 6px;
  display: none;
  right: 20px;
  top: 20px;
}

.dashboard-list-box a.close-list-item i {
  padding-right: 5px;
}

.dashboard-list-box.list-box-with-icon ul li .list-box-icon {
  font-size: 18px;
  vertical-align: middle;
  margin-right: 0px;
  position: relative;
  left: -10px;
}

.list-box-with-icon ul li {
  padding: 15px 80px 15px 50px;
  position: relative;
}

@media (max-width: 540px) {
  .list-box-with-icon ul li {
    padding: 15px 15px 15px 50px;
  }
}

.list-box-with-icon ul li .list-box-icon {
  margin-left: -20px;
}

/*dashboard message*/
.dashboard-messages-box-scroll {
  height: 394px;
  overflow-y: scroll;
}

.dashboard-messages-box {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.dashboard-messages-box:first-child {
  padding-top: 20px;
}

.dashboard-messages-box:last-child {
  border-bottom: 0px;
}

.dashboard-message-avtar {
  float: left;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-left: 20px;
  border-radius: 10px;
}

@media (max-width: 480px) {
  .dashboard-message-avtar {
    float: none;
  }
}

.dashboard-message-avtar img {
  width: 100%;
}

.dashboard-message-area {
  margin-left: 50px;
  text-align: left;
  padding-left: 50px;
  padding-right: 20px;
}

@media (max-width: 480px) {
  .dashboard-message-area {
    padding: 15px;
    margin-left: 0px;
  }
}

.dashboard-message-area p {
  margin-bottom: 20px;
}

.dashboard-message-area h5 {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.dashboard-message-area h5 i {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 18px;
  line-height: 40px;
  font-style: normal;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #64bc36;
  text-align: center;
  border-radius: 50%;
}

.dashboard-message-area h5 span {
  font-size: 12px;
  color: #1967d2;
  display: block;
  font-weight: 500;
  padding-top: 4px;
}

.dashboard-message-area .dropdown-toggle {
  color: #1967d2;
}

.dashboard-message-area .dropdown-toggle:after {
  display: none;
}

.dashboard-message-area .dashboard-message-reply-textarea .form-control {
  border-radius: 10px !important;
}

.dashboard-message-reply {
  margin-right: 20px;
}

.dashboard-message-reply .acod-head a {
  font-size: 14px;
  border: none;
  padding: 0px;
  display: inline-block;
  color: #000;
  font-weight: 700;
}

.dashboard-message-reply .acod-head a:hover {
  color: #f96b39;
}

.dashboard-message-reply-textarea {
  margin-top: 20px;
}

/*Edit Profile*/
.dashboard-profile-photo {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #f0f6fe;
}

.dashboard-profile-photo img {
  border-radius: 10px;
  display: block;
  width: 128px;
  height: 128px;
  object-fit: cover;
  object-position: top;
}

.dashboard-profile-photo .upload-btn-wrapper {
  position: absolute;
  left: 5px;
  bottom: 5px;
}

.dashboard-profile-photo .upload-btn-wrapper button {
  left: 50%;
  transform: translateX(-50%);
}

.dashboard-profile-photo .upload-btn-wrapper #upload-image-grid img {
  object-fit: cover;
  object-position: center;
  width: 128px;
  height: 128px;
  padding: 0px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-radius: 10px;
}

.dashboard-cover-pic .dropzone {
  margin-bottom: 10px;
}

/*Dashboard Widget*/
.dashboard-widgets-header {
  padding: 20px 20px 0px 20px;
  font-weight: 500;
  font-size: 14px;
  color: #1967d2;
}

/*notification list*/
.dashboard-widget-scroll {
  position: relative;
  padding: 20px;
}

.noti-list ul {
  list-style: none;
  margin-bottom: 10px;
}

.noti-list ul li {
  position: relative;
  padding: 5px 15px 5px 20px;
  display: block;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.noti-list ul li a {
  color: #000;
}

.noti-list ul li .noti-icon {
  position: absolute;
  left: 0px;
  top: 4px;
  font-size: 14px;
  color: #2db346;
}

.noti-list ul li .noti-texting b {
  font-weight: 500;
}

.noti-view-all {
  padding: 0px;
}

.noti-view-all a {
  color: #1967d2;
}

/*Message list*/
.message-list ul {
  list-style: none;
  margin-bottom: 10px;
}

.message-list ul li {
  position: relative;
  padding: 15px 0px 15px 0px;
  display: block;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
}

.message-list ul li .msg-avtar {
  float: left;
}

.message-list ul li .msg-avtar img {
  height: 30px;
  margin-top: 4px;
  border-radius: 10px;
}

.message-list ul li .msg-texting {
  overflow: hidden;
  padding-left: 10px;
}

.message-list ul li .msg-texting strong {
  color: #17171d;
  font-size: 14px;
  font-weight: 500;
}

.message-list ul li .msg-texting .msg-time {
  float: right;
  color: #1967d2;
  font-weight: 500;
}

.message-list ul li .msg-texting .msg-time span {
  padding-right: 3px;
}

.message-list ul li .msg-texting p {
  margin-bottom: 0px;
  line-height: inherit;
}

.message-view-all {
  padding: 0px;
}

.message-view-all a {
  color: #1967d2;
}

/*Msg style 2*/
.wt-admin-dashboard-msg-2 {
  display: flex;
}

.wt-admin-dashboard-msg-2 .user-msg-list-btn-open {
  top: -62px;
  right: 20px;
}

.wt-admin-dashboard-msg-2 .wt-dashboard-msg-user-list {
  transition: all 0.5s linear;
  display: block;
  width: 100%;
  margin-right: 30px;
  max-width: 400px;
}

@media (max-width: 1360px) {
  .wt-admin-dashboard-msg-2 .wt-dashboard-msg-user-list {
    width: 250px;
  }
}

@media (max-width: 991px) {
  .wt-admin-dashboard-msg-2 .wt-dashboard-msg-user-list {
    position: fixed;
    right: -250px;
    top: 0px;
    z-index: 999;
    width: 250px;
    margin-right: 0px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    transition: all 0.5s linear;
  }
}

.wt-admin-dashboard-msg-2.active .user-msg-list-btn-open {
  right: 257px;
  display: none;
}

.wt-admin-dashboard-msg-2.active .user-msg-list-btn-close {
  display: block;
}

@media (max-width: 991px) {
  .wt-admin-dashboard-msg-2.active .wt-dashboard-msg-user-list {
    right: 0%;
  }
}

.wt-admin-dashboard-msg-2 .wt-dashboard-msg-box {
  display: block;
  background-color: #fff;
  position: relative;
  flex: 1;
}

.wt-admin-dashboard-msg-2 .wt-dashboard-msg-search {
  position: relative;
  padding: 20px;
  background-color: #fff;
}

.wt-admin-dashboard-msg-2 .wt-dashboard-msg-search .form-control {
  padding-right: 40px;
  border-radius: 10px !important;
  border: 0px;
}

.wt-admin-dashboard-msg-2 .wt-dashboard-msg-search .btn {
  outline: none !important;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #1967d2;
  font-size: 20px;
  z-index: 10;
  background: none;
}

.wt-admin-dashboard-msg-2 .msg-find-list .bootstrap-select .dropdown-toggle {
  border: 0px;
  box-shadow: none !important;
  border-radius: 0px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-bottom: 1px solid #dbdbdb;
}

.wt-admin-dashboard-msg-2 .msg-find-list .bootstrap-select .dropdown-toggle:hover,
.wt-admin-dashboard-msg-2 .msg-find-list .bootstrap-select .dropdown-toggle:active,
.wt-admin-dashboard-msg-2 .msg-find-list .bootstrap-select .dropdown-toggle:focus {
  border: 0px solid transparent !important;
  border-bottom: 1px solid #dbdbdb !important;
}

.wt-admin-dashboard-msg-2 .wt-dashboard-msg-search-list {
  background-color: #fff;
  height: 700px;
}

.wt-admin-dashboard-msg-2 .wt-dashboard-msg-search-list-wrap {
  display: table;
  width: 100%;
}

.wt-admin-dashboard-msg-2 .wt-dashboard-msg-search-list-wrap .msg-user-info {
  position: relative;
  display: block;
  padding: 30px 80px 30px 20px;
  background-color: #fff;
  margin: 0px 20px;
  border-radius: 10px;
}

@media (max-width: 1366px) {
  .wt-admin-dashboard-msg-2 .wt-dashboard-msg-search-list-wrap .msg-user-info {
    padding: 40px 0px 10px 0px;
  }
}

.wt-admin-dashboard-msg-2 .wt-dashboard-msg-search-list-wrap:hover .msg-user-info {
  background-color: #f0f6fe;
}

.wt-admin-dashboard-msg-2 .msg-user-timing {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 12px;
  color: #969696;
}

.wt-admin-dashboard-msg-2 .msg-user-info-text {
  overflow: hidden;
}

.wt-admin-dashboard-msg-2 .msg-user-info-pic {
  width: 40px;
  height: 40px;
  float: left;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 6px;
}

.wt-admin-dashboard-msg-2 .msg-user-name {
  font-size: 14px;
  color: #1967d2;
  font-weight: 500;
}

.wt-admin-dashboard-msg-2 .msg-user-discription {
  font-size: 14px;
  color: #222;
}

.wt-admin-dashboard-msg-2 .single-msg-user-name-box {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
  min-height: 85px;
}

.wt-admin-dashboard-msg-2 .single-msg-user-name-box .message-action {
  color: #ff0000;
  font-size: 13px;
}

.wt-admin-dashboard-msg-2 .single-msg-user-name-box h4 {
  margin-bottom: 6px;
}

.wt-admin-dashboard-msg-2 .single-user-msg-conversation {
  padding: 0px;
  margin: 30px 40px;
  height: 550px;
}

@media (max-width: 540px) {
  .wt-admin-dashboard-msg-2 .single-user-msg-conversation {
    margin: 10px;
  }
}

.wt-admin-dashboard-msg-2 .single-user-comment-block {
  position: relative;
}

.wt-admin-dashboard-msg-2 .single-user-comment-wrap {
  padding: 15px 0px;
  margin: 0px 15px;
}

.wt-admin-dashboard-msg-2 .single-user-comment-wrap.sigle-user-reply {
  margin-left: 0px;
}

.wt-admin-dashboard-msg-2 .single-user-comment-wrap.sigle-user-reply .single-user-com-text {
  background-color: #f5f5f5;
}

@media (max-width: 991px) {
  .wt-admin-dashboard-msg-2 .single-user-comment-wrap.sigle-user-reply {
    margin-left: 15px;
  }
}

@media (max-width: 575px) {
  .wt-admin-dashboard-msg-2 .single-user-comment-wrap {
    margin: 0px 10px;
  }
}

.wt-admin-dashboard-msg-2 .single-user-com-pic {
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 15px;
  overflow: hidden;
  padding: 3px;
  background-color: #fff;
}

@media (max-width: 575px) {
  .wt-admin-dashboard-msg-2 .single-user-com-pic {
    float: none;
  }
}

.wt-admin-dashboard-msg-2 .single-user-com-pic img {
  border-radius: 6px;
}

.wt-admin-dashboard-msg-2 .single-user-com-text {
  overflow: hidden;
  background-color: #edf6ff;
  padding: 20px 30px;
  margin-top: 10px;
  border-radius: 10px;
}

.wt-admin-dashboard-msg-2 .single-user-msg-time {
  font-size: 12px;
  color: #969696;
  text-align: right;
  padding-top: 10px;
}

.wt-admin-dashboard-msg-2 .single-msg-reply-comment {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.wt-admin-dashboard-msg-2 .single-msg-reply-comment textarea {
  resize: none;
}

.wt-admin-dashboard-msg-2 .single-msg-reply-comment textarea.form-control {
  border-radius: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  box-shadow: none;
  padding: 15px 50px 15px 20px;
  height: 60px;
}

.wt-admin-dashboard-msg-2 .single-msg-reply-comment .btn {
  outline: none !important;
  position: absolute;
  right: 0;
  top: 50%;
  color: #1967d2;
  font-size: 20px;
  z-index: 10;
  background: none;
  transform: translateY(-50%);
}

.wt-admin-dashboard-msg-2 .user-msg-list-btn-outer {
  display: none;
  position: absolute;
  top: 50%;
  right: 99%;
  z-index: 10;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .wt-admin-dashboard-msg-2 .user-msg-list-btn-outer {
    display: block;
    z-index: 999;
  }
}

.wt-admin-dashboard-msg-2 .user-msg-list-btn-outer.active .user-msg-list-btn-open {
  display: none;
}

.wt-admin-dashboard-msg-2 .user-msg-list-btn-outer.active .user-msg-list-btn-close {
  display: block;
}

.wt-admin-dashboard-msg-2 .user-msg-list-btn-outer .user-msg-list-btn-open {
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  background-color: #1967d2;
  border: none;
  padding: 2px 15px;
  color: #fff;
  position: absolute;
  transform: rotate(270deg);
  transform-origin: center right;
  border-radius: 8px;
}

.wt-admin-dashboard-msg-2 .user-msg-list-btn-outer .user-msg-list-btn-close {
  cursor: pointer;
  white-space: nowrap;
  background-color: #1967d2;
  border: none;
  padding: 2px 15px;
  color: #fff;
  position: absolute;
  transform: rotate(270deg);
  transform-origin: center right;
  display: none;
  top: -31px;
  right: 18px;
  z-index: 10;
  border-radius: 8px;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .wt-dashboard-msg-user-list {
  max-width: 260px;
  margin-right: 10px;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .msg-user-info-pic {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .msg-user-info-pic img {
  border-radius: 50%;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .wt-dashboard-msg-search {
  padding: 10px;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .msg-user-info {
  margin: 0px 10px;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .msg-user-info {
  padding: 30px 15px 15px 15px;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .single-user-msg-conversation {
  margin: 30px 0px;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .single-user-com-pic {
  float: none;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .single-user-com-pic img {
  border-radius: 50%;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .msg-user-discription {
  font-size: 12px;
  color: #3c3c3c;
}

.wt-admin-dashboard-msg-2.twm-dashboard-style-2 .single-user-com-text {
  font-size: 13px;
}

.wt-admin-dashboard-msg-2.active .user-msg-list-btn-open {
  right: 0px;
}

@media (max-width: 991px) {
  .wt-admin-dashboard-msg-2 .wt-dashboard-msg-user-list {
    right: -260px;
  }
}

.gradi-1 {
  color: rgb(78, 215, 207);
}

.gradi-2 {
  color: rgb(234, 111, 157);
}

.gradi-3 {
  color: rgb(98, 124, 235);
}

.gradi-4 {
  color: rgb(251, 188, 117);
}

/*Chart*/
.twm-pro-view-chart {
  padding: 30px;
}

@media (max-width: 540px) {
  .twm-pro-view-chart {
    padding: 5px;
  }
}

.twm-pro-view-chart #profileViewChart {
  height: 334px !important;
}

@media (max-width: 540px) {
  .twm-pro-view-chart #profileViewChart {
    height: auto !important;
  }
}

/*Dashboard candidates style*/
.twm-dashboard-candidates-wrap {
  padding: 30px;
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap {
    padding: 15px;
  }
}

.twm-dashboard-candidates-wrap [class*="col-"]:last-child .twm-dash-candidates-list {
  margin-bottom: 0px;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list {
  background-color: #fff;
  border: 1px solid #ddd;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list {
    display: block;
    text-align: center;
  }
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-media {
  width: 70px;
  display: inline-block;
  margin-right: 10px;
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-media {
    margin-right: 0px;
    width: 60px;
  }
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-media .twm-media-pic {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-media .twm-media-pic img {
  display: inline-block;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list p {
  font-size: 14px;
  margin-bottom: 5px;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-mid-content {
  flex-grow: 1;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content {
    display: block;
    text-align: center;
  }
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-jobs-vacancies {
  font-weight: 500;
  color: #17171d;
  font-size: 14px;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-jobs-vacancies span {
  color: #046c38;
  margin-left: 5px;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-left-info {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-left-info {
    display: block;
  }

  .twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-left-info p {
    margin-right: 0px;
  }
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-right-btn {
    display: inline-block;
  }
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-candidate-address {
  font-size: 14px;
  margin-bottom: 0px;
  margin-right: 30px;
  font-weight: 500;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-candidate-address i {
  margin-right: 5px;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-view-prifile {
  font-weight: 600;
  font-size: 18px;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-controls-icon {
  display: flex;
  margin-bottom: 0px;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-controls-icon li {
  margin-right: 3px;
  text-align: center;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-controls-icon li:last-child {
  margin-right: 0px;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-controls-icon li button {
  background-color: #f0f6fe;
  color: #1967d2;
  border: none;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-controls-icon li button:hover {
  background-color: #1967d2;
  color: #fff;
}

/* Data Table Candidets */
.twm-DT-candidates-list {
  position: relative;
  display: flex;
  width: 100%;
}

.twm-DT-candidates-list .twm-media {
  width: 100%;
  max-width: 50px;
  display: inline-block;
  margin-right: 10px;
}

.twm-DT-candidates-list .twm-media .twm-media-pic {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.twm-DT-candidates-list .twm-media .twm-media-pic img {
  display: inline-block;
}

.twm-DT-candidates-list .twm-job-title h4 {
  color: #1967d2;
  font-size: 14px;
  font-weight: 500;
}

.twm-DT-candidates-list .twm-candidate-address {
  font-size: 14px;
  margin-bottom: 0px;
}

.twm-DT-candidates-list .twm-candidate-address i {
  margin-right: 5px;
}

.twm-table-controls {
  display: flex;
}

.twm-table-controls .twm-DT-controls-icon {
  display: flex;
  margin-bottom: 0px;
  margin-left: auto;
}

.twm-table-controls .twm-DT-controls-icon li {
  margin-right: 3px;
}

.twm-table-controls .twm-DT-controls-icon li:last-child {
  margin-right: 0px;
}

.twm-table-controls .twm-DT-controls-icon li button {
  background-color: #f0f6fe;
  color: #1967d2;
  border: none;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.twm-table-controls .twm-DT-controls-icon li button:hover {
  background-color: #1967d2;
  color: #fff;
}

.add_field_youtube,
.add_field_vimeo,
.add_field_custom {
  background: transparent;
  border: none;
  font-size: 13px;
  color: #1967d2;
}

.twm-D_table {
  padding: 20px;
}

.twm-D_table div.dataTables_wrapper div.dataTables_length select {
  width: 100px;
  border-radius: 6px;
  margin: 0px 5px;
  border: none;
  height: 40px;
  background-color: #f0f6fe;
}

.twm-D_table div.dataTables_wrapper div.dataTables_filter input {
  height: 40px;
  border-radius: 6px;
}

.twm-D_table .page-link {
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0px 20px;
}

@media (max-width: 400px) {
  .twm-D_table .page-link {
    height: 40px;
    line-height: 40px;
    padding: 0px 10px;
  }
}

.twm-D_table .page-link:focus {
  box-shadow: none;
}

.twm-D_table .page-item.active .page-link {
  background-color: #1967d2;
}

/*default table*/
.twm-table {
  position: relative;
  background: #fff;
  border: 0;
  border-radius: 5px;
  margin-bottom: 0px;
  overflow: hidden;
  width: 100%;
}

.twm-table thead {
  border-radius: 8px;
  color: #fff;
}

.twm-table thead th {
  position: relative;
  padding: 15px 20px;
  font-size: 14px;
  color: #17171d;
  font-weight: 500;
  line-height: 30px;
  white-space: nowrap;
}

.twm-table tbody tr {
  position: relative;
  border-bottom: 1px solid #ecedf2;
}

.twm-table tbody tr td {
  position: relative;
  padding: 20px 20px;
  font-size: 14px;
  color: #17171d;
  font-weight: 400;
}

@media (max-width: 767px) {
  .twm-table tbody tr td {
    padding: 20px 10px;
  }
}

.twm-table tbody tr td button {
  border: none;
  background: transparent;
  display: inline-flex;
  margin-bottom: 5px;
}

.twm-table tbody tr td button .fa {
  background-color: #f0f6fe;
  color: #1967d2;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}

.twm-table tbody tr td button:hover .fa {
  background-color: #1967d2;
  color: #fff;
}

.twm-table tr td {
  position: relative;
  padding: 21px 20px;
  font-size: 14px;
  color: #696969;
  font-weight: 400;
}

@media (max-width: 767px) {
  .twm-table tr td {
    padding: 21px 10px;
  }
}

/* Data Table Candidets */
.twm-bookmark-list-wrap .twm-bookmark-list {
  position: relative;
  display: flex;
  width: 100%;
}

.twm-bookmark-list-wrap .twm-bookmark-list .twm-media {
  width: 100%;
  max-width: 90px;
  display: inline-block;
  margin-right: 20px;
  border-radius: 10px;
  height: 90px;
}

.twm-bookmark-list-wrap .twm-bookmark-list .twm-media .twm-media-pic {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border: 1px solid #ddd;
}

.twm-bookmark-list-wrap .twm-bookmark-list .twm-media .twm-media-pic img {
  display: inline-block;
}

.twm-bookmark-list-wrap .twm-bookmark-list .twm-job-title h4 {
  color: #1967d2;
  font-size: 16px;
  font-weight: 500;
}

.twm-bookmark-list-wrap .twm-bookmark-list .twm-bookmark-address {
  font-size: 14px;
  margin-bottom: 10px;
}

.twm-bookmark-list-wrap .twm-bookmark-list .twm-bookmark-address i {
  margin-right: 5px;
}

.twm-bookmark-list-wrap .twm-job-post-duration {
  color: #2db346;
  font-size: 14px;
}

/* Data Table*/
.twm-D_table .table> :not(caption)>*>* {
  padding: 14px;
}

.twm-D_table .dataTables_length {
  margin-bottom: 10px;
}

/* Model Popup */
.twm-model-popup .modal-content {
  border-top: 6px solid #1967d2;
  border-radius: 10px;
  overflow: hidden;
}

.twm-model-popup .modal-content .modal-header {
  border: none;
}

.twm-model-popup .modal-content .modal-title {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.twm-model-popup .modal-content .modal-footer {
  background-color: #f0f6fe;
  justify-content: center;
}

.twm-model-popup .modal-content .modal-footer .site-button {
  padding: 4px 20px;
}

.twm-msg-noti {
  background-color: #1967d2;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 100%;
}

@media (max-width: 575px) {
  h3.twm-title {
    font-size: 16px;
  }
}

.main-bar {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.color-fill.main-bar {
  animation: 0.95s ease 0s normal forwards 1 running mainBar_on_scrollAnimatio;
}

@keyframes mainBar_on_scrollAnimatio {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

/*Featured cities*/
.twm-featured-city {
  margin-bottom: 30px;
}

.twm-featured-city.twm-large-block img {
  object-fit: cover;
  min-height: 520px;
}

@media (max-width: 1199px) {
  .twm-featured-city.twm-large-block img {
    min-height: 426px;
  }
}

@media (max-width: 991px) {
  .twm-featured-city.twm-large-block img {
    min-height: auto;
  }
}

.twm-featured-city .twm-media {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
}

.twm-featured-city .twm-media .twm-city-info {
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: #fff;
  padding: 10px 50px 10px 20px;
  border-radius: 0px 10px 0px 0px;
}

.twm-featured-city .twm-media .twm-city-info .twm-city-jobs {
  color: #1967d2;
}

.twm-featured-city .twm-media .twm-city-info .twm-title {
  margin-bottom: 0px;
}

/*-------------------------------------------------------------------
HOME PAGE 15 START
--------------------------------------------------------------------*/
/*Mega Menu Start*/
.has-mega-menu {
  position: inherit !important;
}

.has-mega-menu .mega-menu {
  background-color: #fff;
  display: flex;
  flex-wrap: nowrap;
  left: 0px;
  list-style: none;
  opacity: 0;
  position: absolute;
  right: 0px;
  visibility: hidden;
  width: 100%;
  margin-top: 20px;
  z-index: 10;
  max-width: 620px;
  left: 50%;
  transform: translateX(-50%);
}

.has-mega-menu .mega-menu>li {
  width: 25%;
  padding: 0px;
  position: relative;
  vertical-align: top;
  text-transform: none;
}

.has-mega-menu .mega-menu>li ul {
  list-style: none;
  margin: 10px 0px 0px 0px;
  padding: 0px;
}

@media (max-width: 991px) {
  .has-mega-menu .mega-menu>li ul {
    margin: 0px 0px 0px 0px;
  }
}

.has-mega-menu .mega-menu>li ul li {
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  text-transform: none;
}

.has-mega-menu .mega-menu>li ul li a {
  color: #000;
  display: block;
  font-size: 13px;
  padding: 11px 20px;
  padding-left: 20px;
  font-weight: 500;
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 1;
}

.has-mega-menu .mega-menu>li ul li a:hover {
  color: #000;
  text-decoration: none;
  padding-left: 40px;
}

.has-mega-menu .mega-menu>li ul li:hover>a {
  color: #1967d2;
}

.has-mega-menu .mega-menu>li ul li:last-child {
  border-bottom: 0px;
}

.has-mega-menu .mega-menu>li ul li .fa {
  color: #000;
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: -1;
}

.has-mega-menu:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
  transition: all 0.3s ease;
}

@media (max-width: 991px) {
  .has-mega-menu .mega-menu {
    transition: none !important;
    transform: none;
  }

  .has-mega-menu .mega-menu>li {
    float: none;
    display: block;
    width: auto;
  }
}

@media (max-width: 991px) {
  .header-nav .nav>li ul.mega-menu ul {
    display: block;
  }

  .header-nav .nav>li .mega-menu>li {
    padding: 0px;
  }

  .header-nav .nav>li ul.mega-menu li:hover ul {
    display: block;
  }

  .header-nav .nav li .mega-menu {
    opacity: 1;
    margin-top: 0px;
  }

  .has-mega-menu.has-child.nav-active .mega-menu {
    margin-top: 0px;
  }
}

/*Mega Menu End*/
/* Home Page 15 Banner Section Css Start */
.twm-home1-banner-section.twm-bnr-hpage-15 {
  /* padding: 50px 0px; */
  overflow: hidden;
  position: relative;
  z-index: -1;
  padding-top: 175px;
  padding-bottom: 130px;
}

@media (max-width: 1199px) {
  .twm-home1-banner-section.twm-bnr-hpage-15 {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section.twm-bnr-hpage-15 {
    padding-bottom: 40px;
  }
}

.twm-home1-banner-section.twm-bnr-hpage-15>.row .twm-bnr-left-section {
  margin-right: auto;
  margin-left: inherit;
  padding-right: 55px;
}

@media (max-width: 1360px) {
  .twm-home1-banner-section.twm-bnr-hpage-15>.row .twm-bnr-left-section {
    padding-left: 0px;
  }
}

@media (max-width: 1199px) {
  .twm-home1-banner-section.twm-bnr-hpage-15>.row .twm-bnr-left-section {
    margin-left: auto;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section.twm-bnr-hpage-15>.row .twm-bnr-left-section {
    max-width: 768px;
    margin: 0px auto;
    padding: 0px 30px 0px 30px;
  }
}

.twm-home1-banner-section.twm-bnr-hpage-15>.row .twm-bnr-left-section .twm-bnr-title-large {
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  color: #000;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .twm-home1-banner-section.twm-bnr-hpage-15>.row .twm-bnr-left-section .twm-bnr-title-large {
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .twm-home1-banner-section.twm-bnr-hpage-15>.row .twm-bnr-left-section .twm-bnr-title-large {
    font-size: 28px;
    line-height: 36px;
  }
}

.twm-home1-banner-section.twm-bnr-hpage-15>.row .twm-bnr-left-section .twm-bnr-discription {
  font-size: 18px;
  /* margin-bottom: 30px; */
  text-align: justify;
  line-height: 33px;
  font-weight: 300;
  color: black;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks {
  display: flex;
  background-color: #fff;
  border: 10px;
  box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
  position: relative;
  padding: 10px 20px;
  border-radius: 10px;
  width: 270px;
  margin: 0px 4px;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks .twm-icon {
  width: 70px;
  margin-right: 20px;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks .twm-content .tw-count-number {
  font-size: 38px;
  line-height: 38px;
  font-weight: 700;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks .twm-content .tw-count-number .counter {
  font-size: 38px;
  line-height: 40px;
  font-weight: 600;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks .twm-content .icon-content-info {
  color: #17171d;
  margin-bottom: 0px;
  font-size: 16px;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-3 {
  display: flex;
  background-color: #fff;
  border: 10px;
  box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
  position: relative;
  padding: 10px 20px;
  border-radius: 10px;
  width: 290px;
  margin: 0px 4px;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-3 .twm-pics {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-3 .twm-pics span {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #fff;
  overflow: hidden;
  margin-left: -15px;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-3 .twm-content .tw-count-number {
  font-size: 38px;
  line-height: 38px;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-3 .twm-content .tw-count-number .counter {
  font-size: 38px;
  line-height: 40px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-3 .twm-content .icon-content-info {
  color: #17171d;
  margin-bottom: 0px;
  font-size: 16px;
}

@media (max-width: 991px) {
  .twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-position-wrap {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-position-wrap {
    display: none;
  }
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-position-1 {
  position: absolute;
  z-index: 4;
  top: 200px;
  left: 45px;
}

@media (max-width: 991px) {
  .twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-position-1 {
    position: inherit;
    top: inherit;
    left: inherit;
  }
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-position-2 {
  position: absolute;
  z-index: 4;
  top: 90px;
  right: 0px;
}

@media (max-width: 1366px) {
  .twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-position-2 {
    top: -30px;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-position-2 {
    position: inherit;
    top: inherit;
    right: inherit;
  }
}

.twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-position-3 {
  position: absolute;
  z-index: 4;
  bottom: 50px;
  right: 20px;
}

@media (max-width: 991px) {
  .twm-home1-banner-section.twm-bnr-hpage-15 .twm-bnr-blocks-position-3 {
    position: inherit;
    right: inherit;
    bottom: inherit;
  }
}

.twm-bnr-hpage-15 {
  position: relative;
  z-index: 1;
}

.twm-bnr-hpage-15:before {
  width: 100%;
  bottom: 60px;
  content: "";
  right: calc(50% + 250px);
  top: 0px;
  background-color: #4886db1a;
  position: absolute;
  z-index: -1;
  transform: skewX(-10deg);
}

@media (max-width: 1024px) {
  .twm-bnr-hpage-15:before {
    transform: none;
    right: auto;
    bottom: 0px;
    height: 400px;
    left: 0px;
    width: 47%;
  }
}

.twm-bnr-hpage-15:after {
  width: 100%;
  content: "";
  left: calc(50% - 350px);
  top: 0px;
  background-color: #e4edfa;
  position: absolute;
  z-index: -2;
  transform: skewX(-10deg);
  bottom: 60px;
}

@media (max-width: 1024px) {
  .twm-bnr-hpage-15:after {
    transform: none;
    left: 0px;
  }
}

.twm-bnr-15-carousal {
  max-width: 780px;
  margin-left: auto;
  margin-right: 40px;
  padding-left: 40px;
  position: relative;
  z-index: 1;
}

@media (max-width: 1360px) {
  .twm-bnr-15-carousal {
    margin-right: 0px;
  }
}

@media (max-width: 1199px) {
  .twm-bnr-15-carousal {
    margin-right: auto;
    margin-bottom: 100px;
    padding-right: 20px;
  }
}

.twm-bnr-15-carousal::before {
  background-color: #f9f9f9;
  left: 10px;
  bottom: -30px;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  border-radius: 20px;
}

.twm-bnr-15-carousal .twm-bnr-15-carousal-section img {
  border-radius: 20px;
  width: 100%;
}

.twm-bnr-15-carousal .bnr-15-left-pic1 {
  position: absolute;
  left: -40px;
  top: -40px;
  z-index: -1;
}

.twm-bnr-15-carousal .bnr-15-left-pic2 {
  position: absolute;
  right: 39%;
  bottom: -70px;
  z-index: -1;
}

.twm-bnr-15-carousal .bnr-15-left-pic3 {
  position: absolute;
  bottom: -75px;
  left: -40px;
  width: 100px;
  height: 100px;
  border: 14px solid #f9f9f9;
  border-radius: 50%;
  z-index: 1;
}

/*H page 15 Counter*/
.twm-bnr-hpage-15-counter {
  margin-top: 80px;
}

@media (max-width: 1199px) {
  .twm-bnr-hpage-15-counter {
    margin-top: 20px;
  }
}

.twm-bnr-hpage-15-counter .counter-outer-two {
  text-align: center;
}

.twm-bnr-hpage-15-counter .counter-outer-two .icon-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 20px 20px 20px;
}

.twm-bnr-hpage-15-counter .counter-outer-two .counter {
  font-size: 38px;
}

.twm-bnr-hpage-15-counter .counter-outer-two .twm-media {
  width: 50px;
  height: 50px;
  line-height: 0;
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
  margin: 0px auto -25px;
  border-radius: 50%;
  transition: 0.5s all ease;
}

.twm-bnr-hpage-15-counter .counter-outer-two .twm-media img {
  width: auto;
  transition: 0.5s all ease;
}

.twm-bnr-hpage-15-counter .counter-outer-two .twm-media [class*="flaticon-"] {
  color: #fff;
  font-size: 24px;
  line-height: 0px;
  transition: 0.5s all ease;
}

.twm-bnr-hpage-15-counter .counter-outer-two .twm-media [class*="flaticon-"]:before {
  transform: translateX(-50%);
  left: 50%;
  position: inherit;
}

.twm-bnr-hpage-15-counter .counter-outer-two:hover .twm-media {
  border-radius: 20px;
  transform: rotate(45deg);
}

.twm-bnr-hpage-15-counter .counter-outer-two:hover .twm-media img {
  transform: scale(0.8) rotate(-45deg);
  z-index: 9;
}

.twm-bnr-hpage-15-counter .counter-outer-two:hover .twm-media [class*="flaticon-"] {
  transform: scale(0.8) rotate(-45deg);
  z-index: 9;
}

/* =================mobile-app-section-start======================= */
.app-downloading-area {
  position: relative;
}

.app-downloading-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #f7f7fb;
}

.app-img-1 {
  position: relative;
}

.app-img-1 .app-img-2 {
  position: absolute;
  top: 45px;
  left: 0;
}

.app-img-2 img {
  width: 100%;
  max-width: 394px;
}

/* .app-downloading-content {
  margin-top: -60px;
} */

.app-downloading-content h2 {
  font-size: 50px;
}

.app-downloading-content .app-btn {
  margin-top: 45px;
}

.app-downloading-content .app-btn .default-btn {
  position: relative;
  text-align: left;
  padding-left: 90px;
  line-height: 1.4;
  padding: 10px 15px;
  padding-left: 60px;
}

.default-btn {
  font-size: 16px;
  color: #ffffff;
  line-height: 1;
  transition: all ease 0.5s;
  text-align: center;
  background-color: #2042e3;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-grid;
  padding: 15px 40px;
  border-radius: 4px;
}

.app-downloading-content .app-btn .default-btn.active {
  margin-left: 30px;
  background-color: #1a1a1a;
  color: #ffffff;
}

.app-downloading-content .app-btn .default-btn i {
  font-size: 42px;
  position: absolute;
  left: 10px;
  top: 12px;
}

.app-downloading-content .app-btn .default-btn:last-child i {
  font-size: 36px;
  top: 15px;
}

@media (max-width: 767px) {
  .app-downloading-content {
    margin-top: 25px;
  }

  .app-downloading-content h2 {
    font-size: 20px;
  }

  .app-downloading-content .app-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app-downloading-content .app-btn .default-btn {
    /* display: block; */
    width: 135px;
    height: auto;
  }

  .app-downloading-content .app-btn .default-btn i,
  .app-downloading-content .app-btn .default-btn.active i {
    font-size: 30px;
  }

  .default-btn {
    font-size: 10px;
  }
}

/* registration form */
.left-content {
  width: 300px;
  float: left;
}

.right-content {
  width: calc(100% - 360px);
  float: right;
}

.static-list {
  width: 300px;
  margin: 40px 0 0;
  background: #fff;
  border: 1px solid var(--N400);
  border-radius: 10px;
  padding: 30px;
  /* position: sticky; */
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.registerWrapper {
  padding: 0 73px 40px 50px;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.rtitle {
  margin: 40px 0 0;
  color: var(--N800);
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
}

.signupformcontent {
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
  margin-bottom: 20px;
}

label.frmlabel {
  font-weight: 500;
  padding-inline: 12px;
}

.left-content ul li::marker {
  content: "\f0a4";
  font-family: "Font Awesome 5 Free";
  margin-right: 5px;
}

ul li {
  padding-inline: 7px;
}

.row.searchbar {
  background-color: lightgray;
  border-radius: 4px;
  padding: 3px;
  text-align: justify;
  align-items: center;
}

.twm-bnr-discription {
  text-align: center;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
}

form.srchfrm {
  margin-top: 10px;
}

a.loginhere {
  color: blue;
  margin-left: 8px;
}

/* .login.col-xl-10 {
    margin: 30px 0px 20px 0px;
} */

.twm-tabs-style-2 {
  box-shadow: 0px 1px 1px 3px rgb(0 0 255 / 10%);
  border-radius: 10px;
  padding: 12px;
  margin: 30px 0px;
}

/* privacy page css  */
.privacy_content {
  background-color: whitesmoke;
}

.privacy_content h3 {
  margin: 4px 0px;
}

.privacy_content h3::marker {
  content: "\f0a4";
  font-family: "Font Awesome 5 Free";
}

/* terems andd condition page css  */
.terms_content {
  background-color: whitesmoke;
}

.terms_content h1 {
  font-size: 24px;
}

.terms_content p span {
  margin: 10px 6px 0px 0px;
  /* font-size: 17px; */
  font-weight: 500;
}

/* about page css  */

.about_heading {
  font-size: 4px;
}

.aboutus .section-content .twm-explore-media-wrap {
  float: left;
}

.aboutus .section-content .twm-explore-media-wrap img {
  max-width: 560px;
  width: 100%;
  height: 100%;
}

.aboutus .section-content .who-we-are-content p {
  margin-bottom: 0;
}

#iconfingure {
  color: #17171d;
  font-weight: 500;
  text-decoration: underline;
}

.srcharea {
  background-color: transparent;
  /* background-size: cover; */
  border-radius: 5px;
  padding: 20px 25px;
}

.serchselect {
  display: flex;
  padding: 9px;
  border-radius: 2px;
  justify-content: center;
  border: none;
  outline: none;
  height: 39px;
  width: 100%;
}

.twm-bnr-search-bar .site-button {
  display: block;
  width: 100%;
  /* padding: 7px 65px; */
  /* margin-top: 25px; */
  font-size: 15px;
}

.twm-inputicon-box .form-control {
  background: #f8f9fa;
  padding: 10px;
}

/* jOb List */
select.wt-select-bar-large {
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 10px;
}

@media (max-width: 767px) {
  .registerWrapper {
    padding: 8px;
  }

  .twm-bnr-search-bar {
    width: 100%;
  }
}

/* Slider  arrow none  */

.react-slideshow-container .default-nav:first-of-type,
.react-slideshow-container .default-nav:last-of-type {
  display: none;
}

/* .sticky {
  position: fixed !important;
  top: 0 !important;
} */

/* slide section css */
.slide-section {

  margin-top: 30px;
}

.slide-section .slide-section-data {
  padding: 0px 40px;
  text-align: justify;
  line-height: 1.9;
  letter-spacing: 1px;
}

.lowercase {
  text-transform: capitalize;
}

/* respopnsive media query  */



@media (max-width:1192px) {
  #hero .main-s {
    top: 0;
    left: 10px;
  }
}


@media (max-width:1146px) {
  #hero .main-s {
    top: 0;
    left: 10px;
  }
}

@media (max-width:992px) {
  #hero .main-s {
    left: 30px;
    top: 130px;
  }


  #hero h1 {
    font-size: 40px;
    padding: 0;
  }

  #hero p {
    padding: 0 !important;
    margin-bottom: 10px;
    font-size: 17px;
  }

  .twm-bnr-search-bar {
    padding: 5px 20px;
    margin: 0 !important;
  }

  .slide-section {
    margin-top: 40px;

  }
}

@media (max-width:768px) {

  .side-bar {
    height: auto !important;
  }

  .why-choose-us .card .card-content p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    overflow: hidden;
    -webkit-box-orient: vertical;
    letter-spacing: -1px;
    text-align: justify;
  }

  .apply-process .card .card-content h3 {
    font-size: 15px;
  }

  .apply-process .card .card-content p {
    padding: 10px !important;
    letter-spacing: -1px;
    word-spacing: 10px;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  #hero .main-s {
    /* top: 0;
     */
    top: 153px;
    left: 0;
    padding: 10px 20px;
    margin-bottom: 30px;
  }

  #hero h1 {
    font-size: 25px;
    font-weight: 500;
  }

  .slide-section {
    margin-top: 40px;
  }

  .slide-section h2 {
    margin-top: 40px;
    font-size: 30px;
    margin-bottom: 0;

  }

  .section-full.p-t120,
  .section-full.p-b90,
  .section-full.p-tb120 {
    padding: 10px;
  }

  .section-head.center.wt-small-separator-outer {
    margin: 10px;
  }

  .join-us-section .join-us-content h3 {
    font-size: 1.3rem !important;
    padding: 0px 10px;
    justify-content: start !important;
  }

  .join-us-section .join-us-content {
    padding: 10px 0px !important;

  }

  .join-us-section .join-us-content .join-us-para {
    padding: 0px 10px !important;
    text-align: justify !important;
  }

  .join-us-section .join-us-content .join-buttons button {
    font-size: 10px;
  }



}

@media (max-width:667px) {
  .slide-section {
    margin-top: 45px;
  }



}

@media (max-width:537px) {
  .slide-section {
    margin-top: 100px;
  }

  #hero p {
    font-size: 14px;
  }

}

@media (max-width:470px) {
  .slide-section {
    margin-top: 120px;
  }

  .slide-section h2 {
    font-size: 22px;

  }

}

@media (max-width:425px) {
  .slide-section {
    margin-top: 140px;
  }
}

@media (max-width:416px) {
  .slide-section {
    margin-top: 190px;
  }

  #hero h1 {
    font-size: 20px;

  }
}

@media (max-width:400px) {
  .slide-section {
    margin-top: 210px;
  }
}

@media (max-width:367px) {


  .slide-section {
    margin-top: 180px;
  }

  #hero p {
    font-size: 11px;
  }

  .section-full.p-t120,
  .section-full.p-b90,
  .section-full.p-tb120 {
    margin-bottom: 0;
    padding-top: 20px;
  }

  .slide-section .slide-section-data {
    padding: 0px 10px;
    font-size: 12px;
  }

  .section-head.center.wt-small-separator-outer {
    margin: 0px auto;
    margin-bottom: 20px;
  }

  .twm-job-categories-area2 {
    padding: 0;

  }

  .section-head.center.wt-small-separator-outer h2 {
    font-size: 15px;
  }

  .job-categories-block-2 {
    padding: 15px 5px;
  }

  .twm-job-categories-section-2 {
    margin: 0;
  }

  .m-b30 {
    margin-bottom: 15px;
  }

  .twm-jobs-list-style1 {
    margin-bottom: 15px !important;
    padding: 20px 5px;
  }

  .twm-bnr-search-bar label {
    font-size: 12px;
  }

  .serchselect {
    font-size: 12px;
  }

  .job-categories-btn.accordion-collapse {
    padding-bottom: 0 !important;
  }

  .why-choose-us p {
    margin-bottom: 0;
    font-size: 12px;
  }

  .why-choose-us h4 {
    font-size: 13px;
  }

  .join-us-section .join-us-content h3 {
    font-size: 1.3rem !important;
    padding: 0px 10px;
  }

  .join-us-section .join-us-content {
    padding: 10px 0px !important;

  }

  .join-us-section .join-us-content .join-us-para {
    padding: 0px 10px !important;
    text-align: justify !important;
  }

  .join-us-section .join-us-content .join-buttons button {
    font-size: 10px;
  }

  .apply-process .apply-process-heading {
    font-size: 1.3rem !important;
    margin: 0px !important;
    margin-bottom: 10px !important;
  }

  h3 {
    font-size: 18px;
  }

  .apply-process .card {
    margin: 10px 0px;
  }

  .testimonials .section-title h2 {
    font-size: 20px;
  }

  .footer-dark {
    padding: 20px 0;
  }

  .ftr-nw-content .ftr-nw-form {
    display: flex;
    justify-content: center;
    gap: 0px !important;
  }

  .ftr-nw-content .ftr-nw-form .form-control {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px 7px;
  }

  .ftr-nw-content .ftr-nw-form button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ftr-nw-content .ftr-nw-title {
    font-size: 15px;
  }

  .ftr-nw-form .ftr-nw-subscribe-btn {
    padding: 0 8px;
  }

  .top_the_sticky .header-2-nav .header-search {
    display: none;
  }

  .header-style-3 .logo-header .logo-header-inner img {
    width: 100%;
  }

  .header-style-3 .header-nav-btn-section .twm-nav-btn-right .dropdown .dropdown-toggle {

    background: transparent;
    border: none;
    outline: none;
    color: #000;
  }

  .who-we-are-content {
    margin-top: 20px;

  }

}

@media (max-width:340px) {
  .slide-section {
    margin-top: 225px;
  }
}

@media (max-width:315px) {
  .slide-section {
    margin-top: 235px;
  }

  .footer-bottom .social-icons {
    display: block;
  }
}

@media (max-width:280px) {
  .slide-section {
    margin-top: 255px;
  }

}

/* css forget modal and forget login page css  */
.forget-content .modal-dialog {
  max-width: 60%;
  justify-content: center;
  display: flex;
}

.forget-content .modal-content {
  display: flex;
  justify-content: center;
  width: 60%;
}