:root {
  --c: #ffa500;
}

.heading_nofound {
  color: var(--c);
}

.bodyloading {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(circle at 50% 50%, #252525, #010101);
}

.loader {
  width: 15vmin;
  height: 15vmin;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: conic-gradient(from 150deg at 50% 14%,
      var(--c) 0 60deg,
      #fff0 0 100%);
  filter: drop-shadow(0.2vmin 0.2vmin 0vmin #bf7200) drop-shadow(0.2vmin 0.2vmin 0vmin #ab6701) drop-shadow(0.2vmin 0.2vmin 0vmin #9c5e01) drop-shadow(0.2vmin 0.2vmin 0vmin #8d5502) drop-shadow(0.4vmin 0.2vmin 0vmin #744602) drop-shadow(0.4vmin 0.2vmin 0vmin #5f3900) drop-shadow(0.4vmin 0.2vmin 0vmin #4d2e00) drop-shadow(0.4vmin 0.2vmin 0vmin #382200) drop-shadow(4vmin 3vmin 1vmin #0008);
}

.loader span {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-origin: 100% 100%;
  transform: rotate(60deg);
  animation: spin 1.5s ease-in-out -1.245s infinite;
  background: conic-gradient(from 150deg at 50% 14%,
      var(--c) 0 60deg,
      #fff0 0 100%);
}

.loader span+span {
  transform-origin: 0% 100%;
}

.loader span+span+span {
  transform-origin: 50% 14%;
}

@keyframes spin {
  100% {
    transform: rotate(300deg);
  }
}

.mainpass {
  position: relative;
}

.password-eye {
  position: absolute;
  top: 50%;
  right: 6%;
  cursor: pointer;
}

/* ....................................... */

.app-downloading-area .app-img-1 img {
  border-style: none;
  width: 100%;
  height: auto;
  vertical-align: middle;
  margin-top: 25px;
}

.job-categories-style1 img {
  /* background-color: red; */
  width: 100%;
  max-width: 100px;
  height: 87px;
  vertical-align: middle;
}

/* Testimonial  first section css */

.owl-carousel .owl-item img {
  height: 240px !important;
  object-fit: contain;
}

/* Testimonial second section css */
.testimonial-wrap .testimonial-item img {
  height: auto !important;
}

/* //////  paayment css ////////// */

.payment {
  position: relative;
  /* margin: 80px 0px; */
  z-index: 10;
}

.payment ul li {
  display: flex;
  align-items: center;
}

.payment-process-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.payment-option-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.payment-option-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment-option-card.selected {
  border-color: #007bff;
}

.payment-option-card img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

/* yogi css pyment */
.payment .container-fluid {
  background-color: #c5cae9;
  height: auto;
}

.payment .heading {
  font-size: 40px;
  margin-top: 35px;
  margin-bottom: 30px;
  padding-left: 20px;
}

.payment .card {
  border-radius: 10px !important;
  margin: 60px 0;

}

.payment .form-card {
  margin-left: 20px;
  margin-right: 20px;
}

.payment .form-card input,
.payment .form-card textarea {
  padding: 10px 15px 5px 15px;
  border: none;
  border: 1px solid lightgrey;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: arial;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

.payment .form-card input:focus,
.payment .form-card textarea:focus {
  -moz-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  -webkit-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  box-shadow: 0px 0px 0px 1.5px skyblue !important;
  font-weight: bold;
  border: 1px solid #304ffe;
  outline-width: 0;
}

.payment .input-group {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.payment .input-group input {
  position: relative;
  height: 80px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 6px;
  padding-top: 30px;
  padding-left: 25px;
}

.payment .input-group label {
  position: absolute;
  height: 24px;
  background: none;
  border-radius: 6px;
  line-height: 48px;
  font-size: 15px;
  color: gray;
  width: 100%;
  font-weight: 100;
  padding-left: 25px;
}

.payment input:focus+label {
  color: #304ffe;
}

.payment .btn-pay {
  background-color: #304ffe;
  height: 60px;
  color: #ffffff !important;
  font-weight: bold;
}

.payment .btn-pay:hover {
  background-color: #3f51b5;
}

/* .payment .fit-image {
  width: 100%;
  object-fit: cover;
} */

/* .payment img {
  border-radius: 5px;
} */

.payment .radio-group {
  position: relative;
  margin-bottom: 25px;
}

.payment .radio {
  width: 200px;
  border-radius: 6px;
  border: 2px solid lightgrey;
  cursor: pointer;
  margin: 12px 25px 12px 0px;
}

.payment .radio:hover {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
}

.payment .radio.selected {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 155, 0.4);
  border: 2px solid blue;
}

.payment .label-radio {
  font-weight: bold;
  color: #000000;
}

/* ................................................... */
.phonepe-click-here-text {
  animation: colorfull 1s infinite ease-in;
}

@keyframes colorfull {
  0% {
    transform: scale(0.8);
    transition: 0.5s linear;
    color: #0ce929;
  }

  50% {
    transform: scale(0.9);
    transition: 0.5s linear;
    color: #6ee649;
  }

  100% {
    transform: scale(0.8);
    transition: 0.5s linear;
    color: #80f071;
  }

}

/* ...................................................... */

/* star_rating css  */
.rating_star {
  width: 120px;
}

.rating_star ul {
  display: flex;
  /* flex-direction: ; */
  width: 100%;
}

.rating_star ul li {
  list-style: none;
  width: 100%;
  padding: 0 !important;
}

.rating_star_testi ul {
  display: flex;
  /* flex-direction: ; */
  width: 100%;
}

.rating_star_testi ul li {
  list-style: none;
  width: 100%;
  padding: 0 !important;
}

/* ....................................... */
/* Header Model Search css */
/* Header Model Search css */

.header-search-model {
  height: auto;
  overflow-y: scroll;
  border-top: 2px solid gray;
}

.header-search-model button {
  border-radius: 1rem;
  box-shadow: #010101;
}

.header-search-model .card img {
  width: 100px;
  height: 70px;
  object-fit: contain;
}

.header-search-model .card .card-title {
  width: auto;
  max-width: 100%;
  padding-left: 10px;
  text-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
}

.header-search-model p {
  width: 100%;
  height: 50px;
  padding: 10px;
  text-align: center;
}

/* ....................................... */

/* otp css   */

.otp-form {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

h2 {
  margin-bottom: 20px;
  color: #333;
}

/* OTP input styles */
.otp-container,
.email-otp-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.otp-container .otp-input,
.email-otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.otp-input:focus,
.email-otp-input:focus {
  border-color: #007bff;
}

#verificationCode,
#emailverificationCode {
  width: 100%;
  margin-top: 15px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

#verificationCode:focus,
#emailverificationCode:focus {
  border-color: #007bff;
}

.email-otp {
  margin-top: 25px;
}

/* Button styles */
/* button { 

  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
} */

/* ---------------------------------------- */
.otp-section {
  width: 500px;
  height: 250px;
  background-color: #c5c0c4;
  border-radius: 10px;
  padding: 30px;
  margin: 50px 0px;
  color: #0fb9ab;
  font-weight: 700;
  font-size: 2rem;
}

.otp-section .otp-input {
  gap: 10px;
  margin: 20px 0px;

}

.otp-section .otp-input input {
  width: 40px !important;
  height: 40px !important;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 25px;
  font-weight: 700;
  color: #fad727;
}


.otp-section .resent-otp {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: center !important;
  align-items: center;
  gap: 10px;

}

.otp-section .resent-otp span {
  font-size: 20px;
}

.otp-section button {
  padding: 5px 7px;
  border: none;
  outline: none;
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
}

.otp-section button:nth-child(1) {
  background-color: #33b633;
}

.otp-section button:nth-child(1):hover {
  background-color: #157615;

}

.otp-section button:nth-child(2):hover {
  background-color: #2fb488;
}

/* ---------------------------------------- */

/* login tabpills */
.login .card {
  border: none;
}

.emp-tab-pills {
  justify-content: center;
}

.emp-tab-pills-btn {
  display: flex;
  align-items: center;
  gap: 12px;
}

footer .widget_services ul li p {
  margin-bottom: 0;
}

/* page not found css 404 rohit  */
.error-page {
  margin: 70px 0px;
  display: grid;
  justify-content: center;
}

.error-page .error-box {
  text-align: center;
}

.error-box h1 {
  animation: colorTransition 6s infinite alternate;
}

.error-box h1,
.error-box h3,
.error-box p {
  line-height: 1.7;
}

.error-box h3 i {
  animation: colorTransition 4s infinite alternate;
}

@keyframes colorTransition {
  0% {
    color: #f69619;
  }

  50% {
    color: rgba(180, 123, 15, 0.658);
  }

  100% {
    color: black;
  }
}

/* payment gateway */

input {
  box-shadow: rgba(168, 168, 172, 0.25) 0px 6px 12px -2px,
    rgba(189, 185, 185, 0.3) 0px 3px 7px -3px !important;
}

/* gallery page css start gurjar rohit patel css */

/* gallery css */

.gallery-block.grid-gallery .heading {
  margin-bottom: 50px;
  text-align: center;
}

.gallery-block.grid-gallery .banner-title-outer .heading h3 {
  font-weight: bold;
  font-size: 28px;
  color: var(--btn-color);
  text-transform: capitalize;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

.gallery-block.grid-gallery a:hover {
  opacity: 0.8;
}

.gallery-block .bttn {
  background-color: var(--btn-color);
  color: #fff;
  padding: 2px 10px 6px 10px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
}

.gallery-block.grid-gallery .item img {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  transition: 0.4s;
}

.gallery-block.grid-gallery .item {
  margin-bottom: 20px;
}

.gallery-block img {
  border: 2px solid var(--btn-color);
  padding: 5px;
  border-top-left-radius: 15%;
  border-end-end-radius: 15%;
  width: 300px;
  height: 250px;
}

.gallery-block img:hover {
  /* scale: 1.03; */
  transition: 1s ease-in-out all;
  padding: 0;
  border: rgb(2, 2, 207) 2px solid;
}

/* New Css  */
.css-zjq1i3 {
  width: 80vw;
  max-width: 90%;
  object-fit: cover;
}

/* ............................................................................. */
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 100px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 80px !important;
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
  margin-left: 15px !important;
}

.react-photo-gallery--gallery div:nth-child(1) {
  justify-content: space-between;
  display: flex;
  row-gap: 16px;
}

/* ............................................................................. */

/* Why Choose Us */
.why-choose-us {
  width: 100%;
}

.why-choose-us .card {
  border: none !important;
  background-color: #f9fcff;
}

.why-choose-us .card:hover {
  background-image: linear-gradient(0deg, #bfacff 0%, #795fff 100%);
  color: #fff !important;
  box-shadow: -14.142px 14.142px 20px 0px rgba(157, 157, 157, 0.5);
}

.why-choose-us .card:hover h4 {
  color: white;
}

.why-choose-us .card .card-content p {
  font-weight: 300;
  line-height: 25px;
}

/* ..................................................................... */

.join-us-section {
  width: 100%;
  margin: 20px 0px;
}

.join-us-section .join-us-content {
  padding: 50px 0px;
  /* background-image: linear-gradient(0deg, #bfacff 0%, #795fff 100%); */
  background-color: #f0f6fe;
}

.join-us-section .join-us-content h3 {
  /* color: #fff; */
  font-size: 2rem;
}

.join-us-section .join-us-content .join-us-para {
  /* color: #fff; */
  font-size: 0.8rem;
  text-align: center;
  padding: 10px 150px;
  margin: 20px 0px;
}

.join-us-section .join-us-content .join-buttons button {
  color: #fff;
  font-weight: 500;
  border: none;
  outline: none;
  /* background-color: #2bcaba; */
  background-color: var(--c);
}

.join-us-section .join-us-content .join-buttons button:hover {
  /* background-color: lightsalmon; */
  background-color: #2bcaba;
}

/* ...................................................................... */
.apply-process {
  width: 100%;
  margin: 40px 0px;
  padding: 30px 10px;
  background-color: #f0f6fe;
}

.apply-process .apply-process-heading {
  text-align: center;
  font-size: 2rem;
  margin: 40px 0px;
}

.apply-process .card {
  border: none !important;
  outline: none !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.apply-process .card:hover img {
  transform: rotateY(180deg);
  transition: 0.5s linear;
}

.apply-process .card .card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 20px 0px;
}

.apply-process .card .card-image img {
  width: 50px;
  height: 50px !important;
  transition: 0.5s linear;
}

.apply-process .card .card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* color: white; */
}

.apply-process .card .card-content p {
  padding: 10px 50px;
  line-height: 30px;
}
.applied-btn{
  display: flex;
  justify-content: end;
}
/* ...................................................................... */

/*Header DropDown Css */

.header-nav .nav .dropdown:hover .dropdown-menu {
  display: block;
}

.header-nav .nav .dropdown:hover .dropdown-menu {
  position: absolute;
  top: 40px;
  left: -22.5rem;
  width: 60vw !important;
  height: auto !important;
  border: none;
  padding: 8px 14px;
  /* background-color: red; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.header-nav .nav .dropdown {
  position: relative;
}

.header-nav .nav .dropdown .dropdown-menu::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 45%;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  z-index: 0;
  background-color: white;
  transform: rotate(45deg);
}

.header-nav .nav .dropdown .dropdown-menu .job-banner-image {
  width: 100%;
  min-width: 250px;
  height: 100%;
}

.header-nav .nav .dropdown .dropdown-menu .job-banner-image img {
  width: 100%;
  height: 140px;
  object-fit: fill;
  margin: 10px 0px;
}

/* .header-nav .nav .dropdown .dropdown-menu .jobs-cards {
  height: 50vh;
  overflow-y: scroll;
  padding-right: 10px;
}

.header-nav .nav .dropdown .dropdown-menu .jobs-cards .job-card:hover {
  background-color: #8b8888;
  color: #fff;
}

.header-nav .nav .dropdown .dropdown-menu .jobs-cards .job-card:hover h4 {
  color: #fff;
}

.header-nav .nav .dropdown .dropdown-menu .jobs-cards::-webkit-scrollbar-thumb {
  background-color: var(--c);
}

.header-nav .nav .dropdown .dropdown-menu .jobs-cards::-webkit-scrollbar {
  width: 5px;
}

.header-nav .nav .dropdown .dropdown-menu .jobs-cards::-webkit-scrollbar-track {
  background-color: rgb(226, 182, 164);
} */
.textlogin{
  text-transform: capitalize;
  margin-left: 5px;
}

.header-nav .nav .dropdown .dropdown-menu .job-card {
  border: 1px solid gray;
  border-radius: 10px;
}

.header-nav .nav .dropdown .dropdown-menu .job-card .job-card-image {
  width: 100px;
  height: 80px;
}

.header-nav .nav .dropdown .dropdown-menu .job-card .job-card-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 5px;
  background-color: #b6c2ce;
}

.header-nav .nav .dropdown .dropdown-menu .job-card .job-card-image img:hover {
  padding: 0;
  transition: 0.4s linear;
}

.header-nav .nav .dropdown .dropdown-menu .job-card .job-card-content {
  width: 100%;
  padding: 0 15px;
}

/* ...................................................................... */

/* Subscription pages css start */


.subscription-section .subscription-clock {
  animation: blinkRot 2s infinite ease-in-out;
  font-weight: 800;
  font-size: 22px;
}

@keyframes blinkRot {
  0% {
    color: #eea8a8;
    transition: all .5s linear;

  }

  50% {
    color: #ed6666;
    transition: all 1s linear;

  }

  100% {
    color: #ec4141;
    transition: all 1.5s linear;

  }

}

.subscription-section .subscription-cross-btn:hover {
  color: red;
  border-radius: 10px;
}

.subscription-section .weekend-offer .main-heading {
  color: #fff;
}

.subscription-section .card:hover {
  transform: scale(1.01);
  transition: all 0.5Fs ease-in;
  background-color: rgba(245, 245, 245, 0.37) !important;
  cursor: pointer;

}


.subscription-section .subs-plan-heading h3 .down-arrow {
  animation: subdownarrow 2s infinite linear;
  color: #f07f7f;
  font-size: 30px;
}

@keyframes subdownarrow {
  0% {
    color: #007bff;
    transition: all 1.5s ease-in-out;
    transform: scale(0.3);
  }

  100% {
    color: #382200;
    transition: all 1s ease-in-out;
    transform: scale(0.5);

  }

  100% {
    color: #f07f7f;
    transition: all 0.5s ease-in-out;
    transform: scale(1);
  }

}


.subscription-section .subscription-main-card {
  height: 340px;
}

.subscription-section .subscription-main-card .card-description {
  height: 100px;
  overflow-y: scroll;
}

.subscription-section .subscription-main-card .card-description::-webkit-scrollbar {
  width: 8px;
}

.subscription-section .subscription-main-card .card-description::-webkit-scrollbar-thumb {
  background: #ffcc3e;
  border-radius: 10px;
}

.subscription-section .subscription-main-card .card-description::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;

}


/* ............................................................... */
/* Material Ui Datatable css start */
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  align-items: baseline !important;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.tss-1dccma1-MUIDataTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar p {
  border: none;
}

/* ............................................................... */

/* Subscription pages css end */

/* ...................................................................... */

/* responsive css */

/* @media (max-width: 992px) {
  .twm-bnr-search-bar {
    width: 100%;
  }

  .app-downloading-content h2 {
    font-size: 40px;
  }

  .app-downloading-content .app-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app-img-1 .app-img-2 {
    top: 20px;
    width: 250px;
  }

  .app-downloading-content .app-btn .default-btn {
    padding: 5px 10px;
    padding-left: 53px;
  }
}

@media (max-width: 991px) {

  .payment .form-card input,
  .payment .form-card textarea {
    margin-bottom: 25px;
  }
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 100%;
  }

  .h-search-form button {
    position: relative;
    padding: 3px 3px;
    top: -51px;
    right: -28%;
    border-radius: 50px;
    border: none;
    width: 80px;
  }

  .side-bar {
    height: auto;
  }

  .app-img-1,
  .app-img-2 {
    top: 10px;
  }

  .app-downloading-area .app-img-2 img {
    width: 245px;
    max-width: 100%;
    height: 275px;
    margin-top: 10px;
  }

  .app-downloading-content .app-btn {
    margin-top: 15px;
  }

  .app-downloading-content h2 {
    font-size: 24px !important;
  }

  .app-downloading-content .app-btn .default-btn {
    height: 50px;
    padding: 9px 6px;
  }

  .apply-process .card .card-content p {
    padding: 5px 24px;
  }


  .slide-section .slide-section-data {
    padding: 20px 40px;
  }

  .header-nav .nav .dropdown:hover .dropdown-menu {
    left: 0;
    width: 31vw !important;

  }

  .header-nav .nav .dropdown .dropdown-menu .job-banner-image {
    min-width: 210px;
  }

  .header-nav .nav .dropdown .dropdown-menu::before {
    left: 0;
    margin: 0;
  }

  .twm-explore-media-wrap .twm-media img {
    width: 300px;
  }

  h2 {
    font-size: 30px;
  }
}

@media (max-width: 367px) {
  #hero {
    padding-bottom: 0;
  }

  #hero .main-s {
    top: 0 !important;
    left: 0 !important;
  }

  #hero h1 {
    font-size: 20px;
    padding-top: 0 !important;
  }

  #hero p {
    font-size: 14px;
  }

  .why-choose-us {
    margin-top: 30px;
  }

  .join-us-section .join-us-content h3 {
    font-size: 1.5rem;
    padding: 0px 20px;
  }

  .join-us-section .join-us-content .join-us-para {
    padding: 10px;
    text-align: justify;
  }

  .apply-process .card {
    margin: 10px 0px;
  }

  .twm-explore-media-wrap .twm-media img {
    width: 150px;
    margin-bottom: 20px;
  }

  .section-full.p-t120 {
    padding: 0;
  }

  .header-nav .nav .dropdown:hover .dropdown-menu {
    display: none;

  }

  .twm-bnr-search-bar {
    margin-top: -36px;
  }

} */