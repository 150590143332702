/* Breadcrumb css admin */
.common_dash .top h3 {
  font-weight: 600;
  font-size: 25px;
  display: flex;
}

.common_dash .anim {
  animation: myAnim 3s ease-in 1s 3 normal both;
}

@keyframes myAnim {

  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  10% {
    transform: rotate(8deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-10deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-8deg);
  }

  90% {
    transform: rotate(8deg);
  }
}

.common_dash .bread {
  display: flex;
  justify-content: end;
}

/* siteconfig css admin  */
.upshowing {
  background-color: #fff;
  padding: 0 20px;
  position: relative;
}

.upshowing .pic {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

.upshowing .pic img {
  height: 120px;
  border-radius: 50%;
  width: 120px;
  object-fit: contain;
  border: 2px solid black;
  padding: 5px;
}


.upshowing .upbtn button {
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 2px 16px;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 17px;
  background-color: #f5651d;
  color: #fff;
  position: absolute;
  top: 17px;
  right: 35px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgb(45, 51, 89);
  }
}


/* .upshowing h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
  border-bottom: 1px solid gray;
  padding: 5px;
  height: 50px;
} */

.upshowing p {
  font-size: 15px;
  font-weight: 400;
  /* margin-bottom: 1.5px; */
  /* border-bottom: 1px solid gray; */
  /* padding: 5px; */
  /* height: 52px;  */

  /* &::before {
      content: " :  ";
    } */
}

.upshowing .card_design {
  /* box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11); */
  /* box-shadow: 20px 0 10px -20px rgba(0,0,0,0.45) inset; */
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  padding: 20px;
}

/* .upshowing .content p {
  height: 100px;
  overflow-y: auto;
} */

.upshowing h5 {
  text-transform: capitalize;
}


/* .upshowing {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    background-color: #000;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
  }
} */

.slider_page .table tr img {
  height: 90px;
  width: 100px;
  object-fit: contain;
  padding: 2px;
}

.comapnay_form .registerWrapper {
  padding: 0 73px 40px 50px;
  display: flex;
  flex-direction: row;
}

.comapnay_form .twm-tabs-style-2 {
  box-shadow: 0px 1px 1px 3px rgb(0 0 255 / 10%);
  border-radius: 10px;
  padding: 12px;
}

.comapnay_form .site-button {
  background-color: #f5651d;
  color: whitesmoke;
  border: none;
  border-radius: 5px;
  padding: 4px;
  margin-top: 15px;
}

.ab-image img {
  width: 100%;
  height: auto;
  max-width: 450px;
}

.slider-trash-btn {
  background: red;
  color: #fff;
  padding: 3px 8px;
  border: none;
  font-size: 17px;
  border-radius: 5px;
}

.swal2-actions {
  gap: 30px;
}

.slider-image-img input {
  padding-top: 13px;
  background-color: whitesmoke;
}

.slider-image-alt input {
  background-color: whitesmoke;
}

@media (max-width: 767px) {
  .main-container .sidebar {
    position: absolute;
    z-index: 2;
  }

  .centerbar {
    z-index: 1;
  }

  .upshowing .upbtn {
    & button {
      font-size: 14px;
      top: 64px;
      right: 35px;
    }
  }

  .upshowing ol {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.discription_content {
  height: 80px;
  overflow-y: scroll;
}

/* footer css admin  */
.dashboard_footer {
  background-color: whitesmoke;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  margin: auto;
  justify-content: center;
  font-size: medium;
  color: rgba(0, 0, 0, 0.555);
}

.card-1 .student_image img {
  max-width: 120px;
  border-radius: 8px;
}

.card-1 .student_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-1 .location {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.card-3 {
  padding: 22px;
}

.m-body {
  height: 164px;
}



/* testimonial css */
.content-related .textimage {
  width: 95px;
  height: 70px;
  object-fit: contain;
}

/*  admin user Profile css */
.breadcrumb-item+.breadcrumb-item::before {
  display: none;
}

.admin_profile .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  align-items: center;
}

/* Admin Company deshbord */
.admin_profile .personal-details {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.admin_profile .personal-details .comapny-image img {
  max-width: 100%;
  width: 500px;
  height: 100%;
}

.admin_profile .personal-details .comapny-details {
  margin-left: 50px;
}

.admin_profile .personal-details-heading {
  color: grey;
  animation: blink 1.5s infinite linear;
  position: relative;
  font-size: 26px;
  padding: 30px 20px;
}


.admin_profile .personal-details-heading::before {
  content: " ";
  position: absolute;
  border-bottom: 2px solid #0e5188;
  top: 70%;
  left: 22px;
  width: 250px;
}

.admin_profile .personal-details-heading::after {
  content: " ";
  position: absolute;
  top: 75%;
  left: 22px;
  border-bottom: 2px solid #72ccc8;
  width: 220px;
}


@keyframes blink {
  0% {
    color: #106688;

  }

  0% {
    color: #3a73a8;
  }

  0% {
    color: #858feb;
  }

}

.admin_profile .personal-details .comapny-details h5 {
  line-height: 2.5;
}

.admin_profile .personal-details .comapny-details h5 span {
  color: #98c7ec;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
  animation: blink 0.5s infinite ease-in;

}

/* ,.,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */
.subscription-edit-form .form-subscriprion-data {
  margin: 15px 0px;

}

/* Admin sidebar uiseb png imageb css start  */

.admin-sidebar-png-image {
  width: 20px;
  height: 20px;
}


/* Admin sidebar uiseb png imageb  css end  */

@media (max-width: 367px) {

  .admin_profile {
    margin-left: 32px;
  }

  .admin_profile .personal-details-heading {
    font-size: 19px;
  }

  .admin_profile .personal-details .comapny-image img {
    width: 250px;
  }

}


/* ,.,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */

/* Admin About Section  */
.admin-about-data {
  height: 315px;
  overflow-y: scroll;
}

/* ,.,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */

/* AdminSlider Section  */
.table-row--align {
  vertical-align: middle;
}

.table-row--baseline{
vertical-align: baseline;
}

/* ,.,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */